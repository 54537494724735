<template>
  <div class="language lang-fix">
    <span v-bind:class="['localSwi', $i18n.locale === code ? 'select' : '']"
          @click="setLocale(code)">{{ name }}</span>
  </div>
</template>
<script>
export default {
  name: 'LocaleSwitcherInscene',
  methods: {
    setLocale(lang) {
      this.$log.debug(`setLocale("${lang}")`)
      if (this.$i18n.locale !== lang) {
        localStorage.setItem('lang',lang);
        this.$i18n.locale = localStorage.getItem('lang');
        location.reload();
      }
    }
  },
  props: [
      "code",
      "name",
  ]
}
</script>
