<template>
     <div>
          <!-- <ExpoHeader></ExpoHeader> -->
          <div class="Scene">
               <audio class="sound-scene sound" loop="true" src="sound/sound_2_64_.mp3"></audio>
               <audio class="sound-move sound" loop="true" src="sound/interface/step.wav"></audio>
               <audio class="sound-window sound" src="sound/interface/window.wav"></audio>
               <audio class="sound-click sound" src="sound/interface/click.mp3"></audio>
               <audio class="sound-hover sound" src="sound/interface/hover.mp3"></audio>
               <audio class="sound-kick sound" src="sound/game/kick.wav"></audio>
               <audio class="sound-star sound" src="sound/interface/star.mp3"></audio>
               <audio class="sound-cast sound" src="sound/game/cast.mp3"></audio>
               <audio class="sound-win sound" src="sound/game/win.mp3"></audio>
               <audio class="sound-fail sound" src="sound/game/fail.mp3"></audio>
               <audio class="sound-alert sound" src="sound/game/alert.mp3"></audio>
               <select-hero></select-hero>
               <Gorodki-interface></Gorodki-interface>
               <Football-interface></Football-interface>
               <samokatInterface v-if="inSamokat"></samokatInterface>
               <div class="camera-factor">
                 <input type="range" id="js-camera-factor" name="volume" min="1" max="10000" value="5000" step="1">
               </div>
               <div class="fps">
               <span class="js-fps"></span>
               <span class="stat"></span>
               <span class="walk"></span>
               </div>
               <!--new interfaces-->
               <!-- map -->
               <div class="map js-button-map">
                    <img src="images/map_img.png" alt="" class="mapOk">
                    <img src="images/map_img-ton.png" alt="" class="mapTon">
               </div>
               <!-- /map -->
               <!-- upper-icon -->
               <div class="upper-icon js-upper-icon upper-icon--mobile">
                    <div class="icon-up upper-icon--language js-upper-icon--language">
                         <div class="icon_slider"></div>
                         <div class="language_scene" @click="upperLanguageActive(1)">
                              <LocaleSwitcherInscene code="ru" name="Ru"/>
                         </div>
                         <div class="language_scene" @click="upperLanguageActive(0)">
                              <LocaleSwitcherInscene code="en" name="Eng"/>
                         </div>
                    </div>
                    <div class="icon-up upper-icon--sound js-icon--sound">
                         <div class="sound_back" @click="upperSoundActive()">
                              <img class="icon_sound" src="/images/upper--sound.svg" alt="">
                              <img class="icon_sound-2" src="/images/upper--sound-active.svg" alt="">
                         </div>
                    </div>
               </div>
               <!-- /upper-icon -->

               <!-- point-detected -->
               <div  class="point-detected point-detected--mobile js-point-detected" style="display:none"
                    @click="likeShopActive()">
                    <div class="detected detected--star js-point-detected--star">
                         <img class="star_img star-anim js-star_img anim-out" src="/images/detected--star.svg"
                         alt="">
                         <img class="star_img star-anim_blue js-star_img star_img-blue anim-out"
                         src="/images/detected--star_blue.svg" alt="">
                    </div>
                    <div class="detected detected--counter js-point-detected--counter">
                         <span class="counter_number js-counter_number" data-count="0">0</span>
                    </div>
               </div>
               <!-- /point-detected -->

               <!-- right-bar -->
               <div class="right-bar js-right-bar">
                    <ExpoTraining></ExpoTraining>
                    <router-link to="catalog" class="right-item right-item--catalog">
                         <div class="item_back item_back--catalog">
                              <img class="right-image js-right-image right-image_catalog"
                                   src="/images/right-bar--catalog.svg" alt="">
                              <img class="right-image-2 js-right-image-2 right-image-2_catalog"
                                   src="/images/right-bar--catalog-active.svg" alt="">
                         </div>
                         <div class="right-text text_catalog">
                              <span class="js-text_catalog">{{$t('sceneInterface.catalog')}}</span>
                         </div>
                    </router-link>
                    <div class="right-item right-item--help js-right-item--help"
                         @click="rightBarActive('help')">
                         <div class="item_back item_back--help">
                              <img class="right-image js-right-image right-image_help" src="/images/right-bar--help.svg"
                                   alt="">
                              <img class="right-image-2 js-right-image-2 right-image-2_help"
                                   src="/images/right-bar--help-active.svg" alt="">
                         </div>
                         <div class="right-text text_help">
                              <span class="js-text_help">{{$t('sceneInterface.help')}}</span>
                         </div>
                    </div>
                    <div class="right-item right-item--stream js-right-item--stream" style="display:none">
                         <a href="https://mrexpo.ru/broadcast/" target="_blank" class="item_back  item_back--stream">
                              <img class="right-image js-right-image right-image_stream"
                                   src="/images/right-bar--stream.svg" alt="">
                              <img class="right-image-2 js-right-image-2 right-image-2_stream"
                                   src="/images/right-bar--stream-active.svg" alt="">
                         </a>
                         <div class="right-text text_stream">
                              <span class="js-text_stream">{{$t('sceneInterface.stream')}}</span>
                         </div>
                    </div>
                    <div  class="right-item right-item--chat js-right-item--chat" style="display:none"
                         @click="rightBarActive('chat')">
                         <div class="item_back item_back--chat">
                              <div class="new-messsages-count">!</div>
                              <img class="right-image js-right-image right-image_chat" src="/images/right-bar--chat.svg"
                                   alt="">
                              <img class="right-image-2 js-right-image-2 right-image-2_chat"
                                   src="/images/right-bar--chat-active.svg" alt="">
                         </div>
                         <div class="right-text text_chat">
                              <span class="js-text_chat">{{$t('sceneInterface.chat')}}</span>
                         </div>
                    </div>
                    <div class="right-item right-item--train js-right-item--info">
                         <div class="icon-train">
                              <img src="images/interface/head.png" alt="">
                         </div>
                    </div>
               </div>
               <!-- /right-bar -->

               <!-- block - stream -->
               <!-- <ExpoStreamsEvents></ExpoStreamsEvents> -->
               <!-- /block - stream -->
               <!-- block - help -->
               <ExpoHelp></ExpoHelp>
               <!-- /block - help -->

               <!-- block - info -->
               <!-- /block - info -->

               <!-- block - like--shop -->
               <ExpoShopNew></ExpoShopNew>
               <!--/ block - like--shop -->

               <!-- block - objectInfo -->
               <ExpoObjectInfo v-bind:object-for-card="objectForCard" v-if="loadObject"></ExpoObjectInfo>
               <!-- / block - objectInfo -->

               <div class="map--close js-map--close">
                    <div class="map--close_back js-map--close_back">
                         <img class="map--close_img js-map--close_img" src="/images/b-close-blue.png" alt="">
                    </div>
                    <div class="map--close_text">
                         <span>Выход</span>
                    </div>
               </div>

               <!--/ Danya v/2 -->
               <!--/new interfaces-->
               <ExpoMasterClass v-if="msKlassON"></ExpoMasterClass>
               <Rubbish></Rubbish>
               <!-- Danya v/3 -->
               <!-- training -->
               <div class="glass-plane"></div>
               <!-- /training -->
               <div @click="mobileInt()" id="nav-icon3" class="nav-icon3">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
               </div>
               <interfaceMobile></interfaceMobile>
               <!-- /Danya v/3 -->
          <div id="customLoadingScreenDiv">
               <div id="load-animation"></div>
               <div class="progressLoad"><span class="js-progressLoad"></span> %</div>
          </div>
               <canvas
                    id="renderCanvas"
                    ref="canvas"
                    class="Scene-canvas"
                    oncontextmenu="return false"
               ></canvas>
               <PlayerChat></PlayerChat>
          </div>
     </div>
</template>

<script>

import PlayerChat from "@/components/PlayerChat";
import store from "@/store";
import ExpoHelp from "@/components/ExpoHelp";
import ExpoStreamsEvents from "@/components/ExpoStreamsEvents";
import ExpoInfo from "@/components/ExpoInfo";
import ExpoShopNew from "@/components/ExpoShopNew";
import ExpoObjectInfo from "@/components/ExpoObjectInfo";
import ExpoTraining from "@/components/ExpoTraining";
import selectHero from "@/components/selectHero";
import GorodkiInterface from "@/components/GorodkiInterface";
import samokatInterface from "@/components/samokatInterface";
import FootballInterface from "@/components/FootballInterface";
import interfaceMobile from "@/components/interfaceMobile";
import ExpoMasterClass from "@/components/ExpoMasterClass";
import LocaleSwitcherInscene from "@/LocaleSwitcherInscene";
import Rubbish from "@/components/Rubbish";
import { directive } from 'vue-awesome-swiper';
import  {init3d} from '@/3d';
import {tr} from "@/utils";
export default {
     name: 'BabylonHome',
     directives: {
          swiper: directive
     },
     components: {
          ExpoObjectInfo,
          ExpoShopNew,
          ExpoInfo,
          ExpoStreamsEvents,
          ExpoHelp,
          PlayerChat,
          ExpoTraining,
          selectHero,
          GorodkiInterface,
          samokatInterface,
          FootballInterface,
          interfaceMobile,
          LocaleSwitcherInscene,
          Rubbish,
          ExpoMasterClass,
     },
     mounted() {
          window.vexpo = this;
          init3d();
          window.localInt = {};
          window.localModel = {};
          localInt.soundWindow = document.querySelector('.sound-window')
          localInt.soundClick = document.querySelector('.sound-click')
          localInt.soundHover = document.querySelector('.sound-hover')
          localInt.blurH = document.querySelector(".newShop-head").offsetHeight;
          document.querySelector(".newShop-blur--top").style.top = localInt.blurH + "px";
          localInt.btnLike = document.querySelector('.like-all');
          localInt.divLike = document.querySelector('.like-text');
          localInt.divNumber = document.querySelector('.like-number');
          localInt.click = new Event("click", {bubbles : true, cancelable : true})
          localInt.vh = window.innerHeight * 0.01;
          document.querySelector(".main-cont.inscene").style.setProperty('--vh', `${localInt.vh}px`);
          window.addEventListener('resize', () => {
               let vh = window.innerHeight * 0.01;
               document.querySelector(".main-cont.inscene").style.setProperty('--vh', `${vh}px`);
          });
          this.soundHover()

     },
     methods: {
          soundHover(){
          localInt.hoverBlock = document.querySelectorAll(".item_back");
          localInt.hoverBlock.forEach((item)=>{
               item.addEventListener('mouseenter', ()=>{
                    localInt.soundHover.currentTime = 0;
                    localInt.soundHover.play();
               }
          )})
          document.querySelector(".map").addEventListener('mouseenter', ()=>{
               localInt.soundHover.currentTime = 0;
               localInt.soundHover.play()
          });
          document.querySelector(".sound_back").addEventListener('mouseenter',()=>{
               localInt.soundHover.currentTime = 0;
               localInt.soundHover.play()
          });
          },
          rubbishStartGame: function (event){
               document.querySelector('.rubbishGame').classList.toggle('active');
          },

          mobileInt: function (event) {
               this.clickSound();
               document.querySelector('.nav-icon3').classList.toggle('open');
               document.querySelector('.interface-mobile').classList.toggle('active');
          },
          setObjectForCard(objectId) {
               this.$log.debug(`setObjectForCard: ${objectId}`);
               const filtered = store.state.expoObjects.filter(value => value.objectId === objectId);
               this.objectForCard = filtered.length !== 0 ? filtered[0] : null;
               this.objectForCard = tr(this.objectForCard,this.$i18n.locale) || {};
               this.loadObject = true;
               localStorage.setItem('obj',objectId);
               setTimeout(()=>{
                    document.querySelector('.render-info.disable').classList.remove('disable');
               }, 10)
               setTimeout(()=>{
                 this.InSceneStartCatalogDetails();
               },200)
          },
          ping() {
               this.$log.debug('Ping method of the BabylonHome!');
          },
          addClass (){
               localInt.divLike.classList.add('active')
               localInt.btnLike.classList.add('active')
               localInt.divNumber.classList.add('active')
          },
          likeShopActive: function(event) {
               localInt.soundWindow.currentTime = 0
               localInt.soundWindow.play()
               this.checkOpens();
               document.querySelector(".js-b-like--shop").classList.add("active");
               document.querySelector(".star-anim").classList.add("anim-to");
               document.querySelector(".star-anim_blue").classList.add("anim-to");

          },
          checkOpens() {
               if(document.querySelector(".right-item.active") != null){
                    document.querySelector(".right-item.active").classList.remove("active");
               }
               if(document.querySelector(".b-block.active") != null){
                    document.querySelector(".b-block.active").classList.remove("active");
               }
               if(document.querySelector(".nav-icon3.open") != null){
                    document.querySelector(".nav-icon3.open").classList.remove("open");
                    document.querySelector(".interface-mobile.active").classList.remove("active");
               }
               if(document.querySelector(".sc-launcher.opened") !=null){
                    document.querySelector(".sc-header--close-button").dispatchEvent(localInt.click);
               }
          },
          rightBarActive(name){
               localInt.soundWindow.currentTime = 0
               localInt.soundWindow.play()
               if(document.querySelector(".star-anim.anim-to") != null){
                    document.querySelector(".star-anim.anim-to").classList.remove("anim-to");
                    document.querySelector(".star-anim_blue.anim-to").classList.remove("anim-to");
               }
               this.checkOpens();
               if(name == "chat"){
                    document.querySelector(".sc-launcher").dispatchEvent(localInt.click);
                    setTimeout(()=>{
                         document.querySelector(".sc-header--close-button").addEventListener("click",()=>{this.closeBlock()})
                    },50)
                    if(document.querySelector('.new-messsages-count.new-alert')!=null){
                         document.querySelector('.new-messsages-count.new-alert').classList.remove("new-alert")
                    }
               }else{
                    document.querySelector(".js-b-"+name).classList.add("active");
               }
               document.querySelector(".js-right-item--"+name).classList.add("active");
          },
          closeBlock(){
               this.clickSound()
               if(document.querySelector(".star-anim.anim-to") != null){
                    document.querySelector(".star-anim.anim-to").classList.add("anim-out");
                    document.querySelector(".star-anim_blue.anim-to").classList.add("anim-out");
               }
               if(document.querySelector(".right-item.active") != null){
                    document.querySelector(".right-item.active").classList.remove("active");
               }
               if(document.querySelector(".b-block.active") != null){
                    document.querySelector(".b-block.active").classList.remove("active");
               }
               document.querySelector(".star-anim").classList.remove("anim-to");
               document.querySelector(".star-anim_blue").classList.remove("anim-to");
          },
          upperLanguageActive(lang){
               if(lang === 0){
                    document.querySelector(".icon_slider").classList.add("active");
                    localInt.soundClick.currentTime = 0
                    localInt.soundClick.valume = 0;
                    localInt.soundClick.play()
               }
               if(lang === 1){
                    localInt.soundClick.currentTime = 0
                    localInt.soundClick.valume = 0;
                    localInt.soundClick.play()
                    document.querySelector(".icon_slider").classList.remove("active");
               }
          },
          upperSoundActive(){
               document.querySelector(".js-icon--sound").classList.toggle("active");
          },
          clickSound(){
               let soundClick = document.querySelector('.sound-click')
               soundClick.valume = 0.04;
               soundClick.currentTime = 0
               soundClick.play()
          },
          InSceneStartCatalogDetails(){
               local.iSayStay=true;
               var model =  localStorage.getItem('obj');
               var canvas = document.querySelector(".detail-canvas");
               var engineObj = null;
               var sceneObj = null;
               this.$log.info(`Loading model in details: ${model} canvas: ${canvas}`);
               const regexWithCharRange = /[a-z]/;
               this.$log.debug("model="+model);
               if(regexWithCharRange.test(model)){
               document.querySelectorAll(".js-canvas-slide").forEach((item, i) => {
                    item.remove();
               });
               return;
               }
               const createDefaultEngine = function () {
               return new BABYLON.Engine(canvas, true, {
                    preserveDrawingBuffer: true,
                    stencil: true,
                    disableWebGL2Support: true
               });
               }.bind(this);
               const createScene = function () {
               // This creates a basic Babylon Scene object (non-mesh)
               sceneObj = new BABYLON.Scene(engineObj);
               const camera = new BABYLON.ArcRotateCamera("camera", BABYLON.Tools.ToRadians(90), BABYLON.Tools.ToRadians(65), 1.5, BABYLON.Vector3.Zero(), sceneObj);
               camera.lowerRadiusLimit = camera.radius;
               camera.upperRadiusLimit = camera.radius;
               camera.inputs.attached.pointers.panningSensibility=0;
               camera.attachControl(canvas, true);
               BABYLON.SceneLoader.AppendAsync("/js/model_list/", "SM_"+model + ".glb", sceneObj).then(function () {
                    sceneObj.createDefaultCamera(true, true, true);
                    sceneObj.activeCamera.alpha += Math.PI;
                    sceneObj.clearColor = new BABYLON.Color3(1,1,1);
                    let lighting = BABYLON.CubeTexture.CreateFromPrefilteredData("https://assets.babylonjs.com/environments/environmentSpecular.env", sceneObj);
                    lighting.name = "runyonCanyon";
                    lighting.gammaSpace = false;
                    sceneObj.environmentTexture = lighting;
                    sceneObj.createDefaultSkybox(sceneObj.environmentTexture, true, (sceneObj.activeCamera.maxZ - sceneObj.activeCamera.minZ)/2, 0.3, false);
                    sceneObj.cameras[0].upperBetaLimit = 1.5;
                    sceneObj.cameras[0].lowerBetaLimit = .5;
                    sceneObj.cameras[0].lowerRadiusLimit = sceneObj.cameras[0].radius - (sceneObj.cameras[0].radius / 2);
                    sceneObj.cameras[0].upperRadiusLimit = sceneObj.cameras[0].radius + (sceneObj.cameras[0].radius / 2);
                    sceneObj.meshes.forEach(item => {
                    if(item.material!=null){
                    item.material.backFaceCulling = true;
                    }
                    });

               }).catch((reason) => {
                    this.$log.error("Babych AppendAsync failed", reason);
               });
               return sceneObj;
               }.bind(this);
               const initFunction = async function () {
               const asyncEngineCreation = async function () {
                    try {
                    return createDefaultEngine();
                    } catch (e) {
                    this.$log.debug("the available createEngine function failed. Creating the default engine instead");
                    return createDefaultEngine();
                    }
               }.bind(this);
               this.engineObj = await asyncEngineCreation();
               window.engineObj = this.engineObj;
               if (!this.engineObj) throw 'engine should not be null.';
               this.sceneObj = createScene();
               window.sceneObj = this.sceneObj
               }.bind(this);
               initFunction().then(() => {
               this.engineObj.runRenderLoop(this.renderLoop);
               }).catch((reason) => {
               this.$log.error("Babych initFunction failed", reason);
               });
               // Resize
               window.addEventListener("resize", this.onResize);
          },
          onResize() {
               if (this.engineObj)
               this.engineObj.resize();
          },
          renderLoop() {
               if (this.sceneObj && this.sceneObj.activeCamera) {
               this.sceneObj.render();
               }
          }
     },
     computed: {
          expoObjects() {
               return store.state.expoObjects;
          },
          expoEvents() {
               return store.state.expoEvents;
          },
          expoRewards() {
               return store.state.expoRewards;
          },
     },
     created() {
     },
     destroyed() {
          //this.expo?.destroy();
          window.scene.dispose();
          window.engine.dispose();
          window.scene = "";
          window.local="";
          clearInterval(setDataHeroPos);
     },
     data: {
        a: true,
        b: false
     },
     data() {
          return {
              inSamokat:false,
               loadObject: false,
               msKlassON: false,
               objectForCard: "326",
               swiperOption: {
                    effect: 'fade',
                    loop: true,
                    loopFillGroupWithBlank: true,
                    navigation: {
                         nextEl: '.swiper-button-next--selectHero',
                         prevEl: '.swiper-button-prev--selectHero',
                    },
                    pagination: {
                         el: '.swiper-pagination',
                         clickable: true,
                    },
                    breakpoints: {
                    // when window width is >= 300px
                         300: {
                              slidesPerView: 1,
                              spaceBetween: 60,
                              slidesPerGroup: 1,
                         },
                    }
               }
          };
     },
};
</script>
<style>
     .sound{
          position: absolute;
          z-index: -10;
     }

     .inscene{
          overflow: hidden;
          height: 100vh;
          width: 100vw;
          touch-action: none;
          position: relative;
     }
     @media(max-width: 576px){
          .inscene{
               height: calc(var(--vh, 1vh) * 100);
          }
     }
     .inscene .header-background{
          background: transparent;
          z-index: 1000;
     }
     .inscene .container-fluid.header-background-2{
          background: transparent;
     }
     .inscene .disable.header{
          transform: translateY(-120px);
     }
     .header.inscene{
          transform: translateY(-120px);
     }
     @media(max-width: 1024px){
          .inscene .header-background-2{
               transform:translateY(-120px);
          }
          .inscene .header-background-2 .header{
               transform: translateY(0px);
          }
          .inscene .header-background-2 .header{
               top: 162px;
          }
          .inscene .header-background-2 .header.griz.disable{
               top: -144px;
          }
     }
     .selectHero {
          width: 100vw;
          height: 100vh;
          position: fixed;
     }
     .js-language-text.inscene{
          font-family: "Gerbera-Bold";
          font-size: 20px;
          box-sizing: border-box;
          color: #fff;
          padding-right: 2px;
          cursor: pointer;
          text-decoration-line: none;
     }
     @media(max-height: 650px) and (min-width: 550px){
          .inscene .header-background-2:hover .header {
               transform: translateY(0px);
          }
          .inscene .header-background-2 .header {
               top: 65px;
          }
          .selectHero {
               top:0;
               width: 100vw;
               height: 100vh;
               position: fixed;
               background-image: url(/images/background/selectHero/mobile-back.png);
               background-size: contain;
               background-position: left;
               background-position-x: 10%;
               background-position-y: 24px;
          }
          .icon_slider.active {
               transform: translateX(70px);
          }
          .Scene .sc-chat-window{
               width: 100%;
               height: 100%;
               max-height: 100%;
               right: 0;
               bottom: 0;
               border-radius: 0;
               transition: .1s ease-in-out;
          }
          .sc-user-input div{
               font-size: 18px;
          }
          .sc-chat-window.opened .sc-header{
               border-top-left-radius: 0px;
               border-top-right-radius: 0px;
          }
     }
     @media(max-height: 575px) and (min-width: 500px){
          .selectHero {
               top: 0;
               width: 100vw;
               height: 100vh;
               position: fixed;
               background-image: url(/images/background/selectHero/mobile-back.png);
               background-size: contain;
               background-position: left;
               background-position-x: 10%;
          }
          .inscene .header-background-2 .header {
               top: 0px;
          }
          .inscene .header-background-2 .header.griz {
               top: 185px;
          }
     }
     @media(max-height: 575px) and (min-width: 1300px){
          .inscene .header-background-2 .header {
               top: 65px;
          }
          .inscene .header-background-2 .header.griz {
               top: 0px;
          }
          .inscene .header-background-2:hover .header {
               transform: translateY(65px);
          }
     }
     @media(max-height: 575px) and (min-width: 1024px){
          .inscene .header-background-2 .header {
               top: 65px;
          }
          .inscene .header-background-2 .header.griz {
               top: 0px;
          }
          .inscene .header-background-2:hover .header {
               transform: translateY(65px);
          }
     }
     .map.disable{
       display: none;
     }

     .point-detected.disable{
          pointer-events: none;
     }
     .nav-icon3.disable{
          pointer-events: none;
     }
     /* zoom-bar */
     #js-camera-factor::-ms-thumb {
          width: 40px;
          height: 40px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          margin-top: 0px;
          /*Needed to keep the Edge thumb centred*/
     }
     #js-camera-factor:focus::-ms-fill-lower {
          background: rgba(240,240,240, 0.75);
     }
     #js-camera-factor:focus::-ms-fill-upper {
          background: rgb(250,250,250);
     }
     /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
     how to remove the virtical space around the range input in IE*/
     @supports (-ms-ime-align:auto) {
     /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
          #js-camera-factor {
               margin: 0;
               /*Edge starts the margin from the thumb, not the track as other browsers do*/
          }
     }
     #js-camera-factor {
          width: 100%;
          margin: 0px 0;
          background-color: transparent;
          -webkit-appearance: none;
          border-radius: 4px;
          touch-action: auto;
     }
     #js-camera-factor:focus {
          outline: none;
     }
     #js-camera-factor::-webkit-slider-runnable-track {
          background: rgba(240,240,240, 0.75);
          border: 0;
          width: 100%;
          height: 40px;
          cursor: pointer;
          border-radius: 31.8px;
     }
     #js-camera-factor::-webkit-slider-thumb {
          margin-top: 0px;
          width: 40px;
          height: 40px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          -webkit-appearance: none;
     }
     #js-camera-factor:focus::-webkit-slider-runnable-track {
          background: rgb(250,250,250);
          border-radius: 31.8px;
     }
     #js-camera-factor::-moz-range-track {
          background: rgba(240,240,240, 0.75);
          border: 0;
          width: 100%;
          height: 40px;
          cursor: pointer;
          border-radius: 31.8px;
     }
     #js-camera-factor::-moz-range-thumb {
          width: 40px;
          height: 40px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
     }
     #js-camera-factor::-ms-track {
          background: transparent;
          border-color: transparent;
          border-width: 6.9px 0;
          color: transparent;
          width: 100%;
          height: 40px;
          cursor: pointer;
     }
     #js-camera-factor::-ms-fill-lower {
          background: rgb(240,240,240);
          border: 0;
          border-radius: 31.8px;
     }
     #js-camera-factor::-ms-fill-upper {
          background: rgba(240,240,240, 0.75);
          border: 0;
          border-radius: 31.8px;
     }
     #js-camera-factor::-ms-thumb {
          width: 40px;
          height: 40px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          margin-top: 0px;
          /*Needed to keep the Edge thumb centred*/
     }
     #js-camera-factor:focus::-ms-fill-lower {
          background: rgba(240,240,240, 0.75);
          border-radius: 31.8px;
     }
     #js-camera-factor:focus::-ms-fill-upper {
          background: rgb(250,250,250);
          border-radius: 31.8px;
     }
     /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
     how to remove the virtical space around the range input in IE*/
     @supports (-ms-ime-align:auto) {
     /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
          #js-camera-factor {
          margin: 0;
          /*Edge starts the margin from the thumb, not the track as other browsers do*/
          }
     }

     /* /zoom-bar */


@media (max-width: 570px) {
     #js-camera-factor::-ms-thumb {
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          margin-top: 0px;
          /*Needed to keep the Edge thumb centred*/
     }
     #js-camera-factor:focus::-ms-fill-lower {
          background: rgba(240,240,240, 0.75);
     }
     #js-camera-factor:focus::-ms-fill-upper {
          background: rgb(250,250,250);
     }
     #js-camera-factor {
          width: 70%;
          margin: 0px 0;
          background-color: transparent;
          -webkit-appearance: none;
          border-radius: 4px;
          opacity: 30%;
          transition: all 150ms ease;
     }
     #js-camera-factor:active{
          opacity: 100%;
          transition: all 150ms ease;
     }
     #js-camera-factor:focus {
          outline: none;
     }
     #js-camera-factor::-webkit-slider-runnable-track {
          background: rgba(240,240,240, 0.75);
          border: 0;
          width: 70%;
          height: 20px;
          cursor: pointer;
          border-radius: 31.8px;
     }
     #js-camera-factor::-webkit-slider-thumb {
          margin-top: 0px;
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          -webkit-appearance: none;
     }
     #js-camera-factor:focus::-webkit-slider-runnable-track {
          background: rgb(250,250,250);
          border-radius: 31.8px;
     }
     #js-camera-factor::-moz-range-track {
          background: rgba(240,240,240, 0.75);
          border: 0;
          width: 70%;
          height: 20px;
          cursor: pointer;
          border-radius: 31.8px;
     }
     #js-camera-factor::-moz-range-thumb {
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
     }
     #js-camera-factor::-ms-track {
          background: transparent;
          border-color: transparent;
          border-width: 6.9px 0;
          color: transparent;
          width: 70%;
          height: 20px;
          cursor: pointer;
     }
     #js-camera-factor::-ms-fill-lower {
          background: rgb(240,240,240);
          border: 0;
          border-radius: 31.8px;
     }
     #js-camera-factor::-ms-fill-upper {
          background: rgba(240,240,240, 0.75);
          border: 0;
          border-radius: 31.8px;
     }
     #js-camera-factor::-ms-thumb {
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          margin-top: 0px;
          /*Needed to keep the Edge thumb centred*/
     }
     #js-camera-factor:focus::-ms-fill-lower {
          background: rgba(240,240,240, 0.75);
          border-radius: 31.8px;
     }
     #js-camera-factor:focus::-ms-fill-upper {
          background: rgb(250,250,250);
          border-radius: 31.8px;
     }
}
@media (max-height: 575px) and (min-width: 500px){
          #js-camera-factor::-ms-thumb {
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          margin-top: 0px;
          /*Needed to keep the Edge thumb centred*/
     }
     #js-camera-factor:focus::-ms-fill-lower {
          background: rgba(240,240,240, 0.75);
     }
     #js-camera-factor:focus::-ms-fill-upper {
          background: rgb(250,250,250);
     }
     #js-camera-factor {
          width: 70%;
          margin: 0px 0;
          background-color: transparent;
          -webkit-appearance: none;
          border-radius: 4px;
     }
     #js-camera-factor:focus {
          outline: none;
     }
     #js-camera-factor::-webkit-slider-runnable-track {
          background: rgba(240,240,240, 0.75);
          border: 0;
          width: 70%;
          height: 20px;
          cursor: pointer;
          border-radius: 31.8px;
     }
     #js-camera-factor::-webkit-slider-thumb {
          margin-top: 0px;
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          -webkit-appearance: none;
     }
     #js-camera-factor:focus::-webkit-slider-runnable-track {
          background: rgb(250,250,250);
          border-radius: 31.8px;
     }
     #js-camera-factor::-moz-range-track {
          background: rgba(240,240,240, 0.75);
          border: 0;
          width: 70%;
          height: 20px;
          cursor: pointer;
          border-radius: 31.8px;
     }
     #js-camera-factor::-moz-range-thumb {
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
     }
     #js-camera-factor::-ms-track {
          background: transparent;
          border-color: transparent;
          border-width: 6.9px 0;
          color: transparent;
          width: 70%;
          height: 20px;
          cursor: pointer;
     }
     #js-camera-factor::-ms-fill-lower {
          background: rgb(240,240,240);
          border: 0;
          border-radius: 31.8px;
     }
     #js-camera-factor::-ms-fill-upper {
          background: rgba(240,240,240, 0.75);
          border: 0;
          border-radius: 31.8px;
     }
     #js-camera-factor::-ms-thumb {
          width: 20px;
          height: 20px;
          background: #af63e5;
          border: 0;
          border-radius: 26px;
          cursor: pointer;
          margin-top: 0px;
          /*Needed to keep the Edge thumb centred*/
     }
     #js-camera-factor:focus::-ms-fill-lower {
          background: rgba(240,240,240, 0.75);
          border-radius: 31.8px;
     }
     #js-camera-factor:focus::-ms-fill-upper {
          background: rgb(250,250,250);
          border-radius: 31.8px;
     }
}
.right-item.train{
     pointer-events: none;
     z-index: 10000;
}
.right-item{
     pointer-events: auto;
}
.right-bar.train{
     z-index: 10010;
}

.point-detected.give-scale {
     animation: anim-give--star 150ms ease-in;
}

@keyframes anim-give--star {
	50%{
          transform: scale(1.3);
	}
     100%{
          transform: scale(1);
     }
}
.new-messsages-count{
     position: absolute;
     top: -5px;
     left: calc(50% + 10px);
     display: flex;
     justify-content: center;
     flex-direction: column;
     border-radius: 50%;
     width: 22px;
     height: 22px;
     background: #ff4646;
     color: #fff;
     text-align: center;
     margin: auto;
     font-size: 12px;
     font-weight: bold;
     z-index: -10;
     opacity: 0;
     transition: all 150ms ease;
}
.new-messsages-count.new-alert{
     z-index: 10;
     opacity: 1;
     transition: all 150ms ease;
}
</style>
