<template>
  <div>

    <!-- block - help -->
    <div class="b-block js-b-block b-help js-b-help test_span">
      <div class="b-block-close js-b-block-close b-help--close js-b-help--close"
        @click="closeBlock()">
        <img class="b-block-close--btn js-b-block-close--btn help-close--btn js-help-close--btn"
          src="/images/b-close.png" alt="">
      </div>
      <div class="top-help--blur"></div>
      <span class="scroll-Help" data-mcs-theme="inset-3-dark">
        <span class="cd-tech__title">{{$t('interfaceHelp.h1')}}</span>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                {{$t('interfaceHelp.text-1')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-1-2')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                {{$t('interfaceHelp.text-2')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-2-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSixteen">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSixteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseSixteen">
                {{$t('interfaceHelp.text-16-1')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseSixteen" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSixteen">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-16-2')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                {{$t('interfaceHelp.text-3')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-3-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFour">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                {{$t('interfaceHelp.text-4')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-4-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                {{$t('interfaceHelp.text-5')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-5-1')}}
              </div>
            </div>
          </div>
        </div>
        <span class="cd-tech__title">{{$t('interfaceHelp.h2')}}</span>
        <div class="accordion" id="accordionPanelsStayOpenTwo">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSix">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                {{$t('interfaceHelp.text-6')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-6-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                {{$t('interfaceHelp.text-7')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-7-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingEight">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                {{$t('interfaceHelp.text-8')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingEight">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-8-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingNine">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseNine">
                {{$t('interfaceHelp.text-9')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingNine">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-9-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTen">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false" aria-controls="panelsStayOpen-collapseTen">
                {{$t('interfaceHelp.text-10')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTen" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTen">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-10-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingEleven">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEleven" aria-expanded="false" aria-controls="panelsStayOpen-collapseEleven">
                {{$t('interfaceHelp.text-11')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseEleven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingEleven">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-11-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwelve">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwelve" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwelve">
                {{$t('interfaceHelp.text-12')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwelve">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-12-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingThirteen">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThirteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseThirteen">
                {{$t('interfaceHelp.text-13')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThirteen">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-13-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFourteen">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFourteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseFourteen">
                {{$t('interfaceHelp.text-14')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFourteen" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFourteen">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-14-1')}}
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFifteen">
              <button class="accordion-button cd-tech__item-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFifteen" aria-expanded="false" aria-controls="panelsStayOpen-collapseFifteen">
                {{$t('interfaceHelp.text-15')}}
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFifteen" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFifteen">
              <div class="accordion-body">
                {{$t('interfaceHelp.text-15-1')}}
              </div>
            </div>
          </div>
        </div>
      </span>
      <div class="bottom-help--blur"></div>
    </div>
    <!-- /block - help -->
  </div>
</template>
<script>
import '@/3d/bootstrap.bundle.min';
export default {
  name: "ExpoHelp",
  methods: {
    closeBlock(){
      let soundClick = document.querySelector('.sound-click')
      soundClick.currentTime = 0
      soundClick.play()
      if(document.querySelector(".star-anim.anim-to") != null){
        document.querySelector(".star-anim.anim-to").classList.add("anim-out");
        document.querySelector(".star-anim_blue.anim-to").classList.add("anim-out");
      }
      if(document.querySelector(".right-item.active") != null){
        document.querySelector(".right-item.active").classList.remove("active");
      }
      document.querySelector(".b-block.active").classList.remove("active");
      document.querySelector(".star-anim").classList.remove("anim-to");
      document.querySelector(".star-anim_blue").classList.remove("anim-to");
    }
  }
}
</script>
<style scoped>
  .b-help{
    height: 100%;
  }
  @media (max-height: 790px) {
    .b-help{
      height: 60%;
    }
  }
  @media (max-height: 675px) and (min-width: 500px){
      .b-help{
      height: 100%;
    }
  }
  @media (max-width: 767px){
    .b-block {
      width: 100vw;
      max-width: inherit;
      height: 100vh;
      max-height: 100%;
      z-index: 100;
      border-radius: 0px;
    }

  }
  .cd-tech__title{
    font-family: "Gerbera-Bold";
    font-size: 32px;
    line-height: 95%;
    color: #232176;
  }
  .accordion-button:not(.collapsed) {
    color: #232176;
    background-color: #e7f1ff00;
  }
  .accordion{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .accordion-body{
    color: #7C7C7C;
  }
  .accordion-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-bottom: 24px;
    border-radius: 24px;
    -webkit-box-shadow: -2.5px -2.5px 5px #fafbff, 2.5px 2.5px 5px #a6abbd;
    box-shadow: -2.5px -2.5px 5px #fafbff, 2.5px 2.5px 5px #a6abbd;
  }
  .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-header{
    width: 90%;
  }
  .accordion-button{
    border: 0px;
    font-family: "Gerbera-Bold";
    font-size: 24px;
    line-height: 120%;
    color: #232176;
    position: relative;
  }
  .accordion-collapse {
    border-width: 0px;
  }
  .accordion-button::after {
    width: 40px;
    height: 40px;
    background-size: 40px;
  }
  @media (max-width: 575px) {
    .accordion-item {
      width: 95%;
    }
    .accordion-button {
      font-size: 18px;
      line-height: 110%;
    }
    .cd-tech__title {
      font-size: 24px;
    }
    .accordion-body{
      font-size: 15px;
    }
  }
</style>
