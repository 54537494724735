let statBotPos = [
{bot:``,pos:[-10.848,0,46.518],rot:[-0.436]},
{bot:``,pos:[-16.074,0,52.531],rot:[2.356]},
{bot:``,pos:[-35.513,-5.581,110.855],rot:[-1.92]},
{bot:``,pos:[-37.022,-5.581,112.655],rot:[3.054]},
{bot:``,pos:[-30.398,-5.581,115.757],rot:[2.182]},
{bot:``,pos:[2.556,-5.581,149.932],rot:[-1.134]},
{bot:``,pos:[-3.187,-5.581,155.526],rot:[2.443]},
{bot:``,pos:[28.673,-5.581,157.456],rot:[-2.356]},
{bot:``,pos:[70.276,-5.581,138.283],rot:[2.88]},
{bot:``,pos:[68.959,-5.581,137.671],rot:[2.182]},
{bot:``,pos:[78.145,-5.581,113.908],rot:[2.182]},
{bot:``,pos:[82.459,-5.581,105.754],rot:[0.087]},
{bot:``,pos:[114.296,-2.412,133.489],rot:[-0.698]},
{bot:``,pos:[112.35,-2.412,140.564],rot:[-2.88]},
{bot:``,pos:[108.998,-2.412,138.654],rot:[2.356]},
{bot:``,pos:[131.643,-2.412,122.255],rot:[-2.705]},
{bot:``,pos:[123.97,-2.13,109.319],rot:[-2.705]},
{bot:``,pos:[118.079,-2.13,115.622],rot:[-2.705]},
{bot:``,pos:[132.088,0.233,51.26],rot:[-2.705]},
{bot:``,pos:[130.743,0.233,51.732],rot:[2.967]},
{bot:``,pos:[101.7,0.256,32.416],rot:[2.967]},
{bot:``,pos:[101.141,0.256,27.689],rot:[0.349]},
{bot:``,pos:[68.955,-0.08,28.805],rot:[0.349]},
{bot:``,pos:[84.946,2.458,56.108],rot:[0.349]},
{bot:``,pos:[86.079,2.458,63.875],rot:[-2.967]},
{bot:``,pos:[100.344,0.877,-10.234],rot:[1.658]},
{bot:``,pos:[100.394,0.877,-12.451],rot:[1.396]},
{bot:``,pos:[97.781,0.877,-34.12],rot:[2.618]},
{bot:``,pos:[94.854,0.877,-37.652],rot:[1.658]},
{bot:``,pos:[59.365,-0.14,-52.023],rot:[-3.054]},
{bot:``,pos:[44.586,-0.14,-59.801],rot:[-0.873]},
{bot:``,pos:[31.458,-0.14,-63.129],rot:[-0.873]},
{bot:``,pos:[32.31,-0.14,-76.656],rot:[-0.873]},
{bot:``,pos:[15.216,-0.14,-90.392],rot:[2.269]},
{bot:``,pos:[17.117,-0.14,-96.158],rot:[0]},
{bot:``,pos:[-7.39,-0.14,-94.84],rot:[-0.785]},
{bot:``,pos:[-25.76,-0.14,-86.533],rot:[2.618]},
{bot:``,pos:[-43.287,-0.14,-83.011],rot:[-1.658]},
{bot:``,pos:[-63.919,-0.14,-78.198],rot:[2.356]},
{bot:``,pos:[-64.285,-0.14,-84.959],rot:[2.356]},
{bot:``,pos:[25.694,-0.001,-27.115],rot:[2.356]},
{bot:``,pos:[24.096,-0.001,-34.385],rot:[1.047]},
{bot:``,pos:[15.722,-0.001,-22.792],rot:[1.309]},
{bot:``,pos:[13.017,-0.001,-6.749],rot:[-1.571]},
{bot:``,pos:[-17.689,-0.001,32.486],rot:[1.833]},
{bot:``,pos:[-22.361,-0.001,17.835],rot:[-2.356]},
{bot:``,pos:[-40.305,-0.001,22.466],rot:[-1.134]},
{bot:``,pos:[-84.841,-0.001,8.824],rot:[-1.134]},
{bot:``,pos:[-103.271,-0.001,22.391],rot:[-1.134]},
{bot:``,pos:[-121.05,-0.001,6.747],rot:[-1.134]},
{bot:``,pos:[-114.56,-0.001,11.782],rot:[-2.443]},
{bot:``,pos:[-116.355,-0.001,-15.375],rot:[-1.309]},
{bot:``,pos:[-111.16,-0.001,-29.845],rot:[-1.658]},
{bot:``,pos:[-85.368,-0.001,-43.214],rot:[-1.658]},
{bot:``,pos:[-118.969,-0.001,39.07],rot:[-0.087]},
{bot:``,pos:[-98.173,-0.001,25.474],rot:[-0.785]},
{bot:``,pos:[-80.505,2.4,27.225],rot:[-0.785]},
{bot:``,pos:[-76.788,2.4,37.222],rot:[-2.094]},
{bot:``,pos:[3.965,-0.001,13.421],rot:[-2.094]},
{bot:``,pos:[-19.226,-0.001,-37.887],rot:[-2.88]},
{bot:``,pos:[-77.636,0.777,-79.835],rot:[-2.88]},
{bot:``,pos:[-48.378,0.163,69.756],rot:[-0.785]},
{bot:``,pos:[-51.465,0.163,68.057],rot:[-0.087]},
{bot:``,pos:[-55.64,0.163,77.429],rot:[2.705]},
{bot:``,pos:[-80.88,4.148,62.097],rot:[-0.175]},
{bot:``,pos:[-103.655,9.217,56.159],rot:[2.618]},
{bot:``,pos:[-26.994,2.4,30.707],rot:[-1.047]},
{bot:``,pos:[50.473,-4.061,62.177],rot:[-0.175]},
{bot:``,pos:[55.834,-5.581,122.996],rot:[2.182]},
{bot:``,pos:[54.865,-5.581,121.676],rot:[1.309]},
{bot:``,pos:[-100.301,-0.001,-48.204],rot:[0.785]},
{bot:``,pos:[-100.933,-0.001,-46.154],rot:[1.484]},
{bot:``,pos:[13.043,-0.001,-5.468],rot:[-1.833]},
{bot:``,pos:[-15.566,-0.001,-58.594],rot:[-1.571]},
{bot:``,pos:[-15.539,-0.001,-57.313],rot:[-1.833]},
];
export {statBotPos};
