<template>
  <div>
    <beautiful-chat
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :showEdition="false"
      :showDeletion="false"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="true"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :disableUserListToggle="false"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @scrollToTop="handleScrollToTop"
      @edit="editMessage"
      ref="chat"
    />
  </div>
</template>

<script>
import store from '@/store'


export default {
  name: "PlayerChat",
  data() {
    return {
      context: null,
      participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      newMessagesCount: 0,
      isChatOpen: false, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      currentUserId: "42",
      messagesListeners: [],
    }
  },
  mounted() {
    if(this.$i18n.locale === "ru"){
      document.querySelector(".sc-header--title").innerHTML = "Вы"
    }else{
      document.querySelector(".sc-header--title").innerHTML = "You"
    }
  },
  methods: {
    onMessageWasSent (message) {
      this.$log.debug('onMessageWasSent: ', message)
      let to = '';
      for (const m of this.messageList) {
        if (m.author === 'support') {
          to = m.author_id
        }
      }
      store.dispatch('sendSupportMessage', {
        "to": to,
        "text": message.data.text,
        "username": store.state.userProfile.nick || "unknown"
      });
    },
    openChat (context) {
      this.$log.debug("openChat: ", context)
      this.reset()
      this.currentUserId =store.state.userProfile.uid;
      this.setContext(context)
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    setContext(context) {
      if (context !== this.context) {
        this.context = context
      }
    },
    reset() {
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.$log.debug("closeChat")
      this.isChatOpen = false
    },
    handleScrollToTop () {
      this.$log.debug('handleScrollToTop')
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      // this.$log.debug('handleOnType')
    },
    // eslint-disable-next-line no-unused-vars
    editMessage(message){
      // const m = this.messageList.find(m=>m.id === message.id);
      // m.isEdited = true;
      // m.data.text = message.data.text;
    }
  },
  destroyed() {
    this.reset()
  },
  computed: {
    messageList() {
      const messages = []
      store.state.supportMessages.forEach(data => {
        messages.push({
          type: 'text',
          author: store.state.userProfile.uid === data.from ? 'me' : 'support',
          author_id: data.from,
          data: {
            text: data.text,
          },
        })
      })

      return messages
    }
  }
}

</script>

<style scoped>

</style>
