<template>
<!-- v-if="name='rubbish'" -->
    <div class="rubbishGame">
        <div class="cub-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 -40 250.79 250.79" class="timer-svg">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1"><circle class="cls-1 js-preload-circle" cx="85.89" cy="85.89" r="84.89" style="stroke-dashoffset: 600;"/> <circle class="cls-1" cx="85.89" cy="85.89" r="84.89" stroke-opacity="0.55"/>
                    </g>
                </g>
            </svg>
        </div>
        <div class="game js-game">
            <div class="game-background--top">
                <div @click="closeGame();clickSound('click')" class="close-rubbish">
                    <span></span>
                </div>
                <div id="timer"></div>
                <div class="right-counter counter">
                    <div class="right-counter--back">
                        <img src="/images/RubbishGame/ok.png" alt="">
                        <span class="right-counter-text js-right-counter">0</span>
                    </div>
                </div>
                <div class="mistake-counter counter">
                    <div class="mistake-counter--back">
                        <img src="/images/RubbishGame/no.png" alt="">
                        <span class="right-counter-text js-mistake-counter">0</span>
                    </div>
                </div>
                <div class="rubbish-text">
                    <span class="rubbish-text--train">{{$t('game.text-train')}}</span>
                    <span class="rubbish-text--win">
                        {{$t('game.text-win-1')}}<span class="rubbish-text--winScore"></span>{{$t('game.text-win-2')}}
                    </span>
                </div>
                <button class="rubbish-start" type="button" name="game" @click="initGameRub();runTime();clickSound('click')">{{$t('game.buttonRub-1')}}</button>
                <div class="bottom-trash">
                    <div class="container-bucks yellow">
                        <div class="bucks-name bucks-name--yellow">
                            {{$t('game.recyclable')}}
                        </div>
                        <img class="top-bucks top-bucks--yellow" src="/images/RubbishGame/bucks/buck_top/wastetop_yellow.png" alt="">
                        <img src="/images/RubbishGame/bucks/wastebox_yellow.png" alt="">
                        <div @click="clickRubbish(`recyclable`,`yellow`,`plastic`)" class="js-bucks bucks plastic" data-type="recyclable">
                        </div>
                    </div>
                    <div class="container-bucks green">
                        <div class="bucks-name bucks-name--green">
                            {{$t('game.mixed-waste')}}
                        </div>
                        <img class="top-bucks top-bucks--green" src="/images/RubbishGame/bucks/buck_top/wastetop_green.png" alt="">
                        <img src="/images/RubbishGame/bucks/wastebox_green.png" alt="">
                        <div @click="clickRubbish(`mixed-waste`,`green`,`glass`)" class="js-bucks bucks glass" data-type="mixed-waste">
                        </div>
                    </div>
                    <div class="container-bucks blue">
                        <div class="bucks-name bucks-name--blue">
                            {{$t('game.household')}}
                        </div>
                        <img class="top-bucks top-bucks--blue" src="/images/RubbishGame/bucks/buck_top/wastetop_blue.png" alt="">
                        <img src="/images/RubbishGame/bucks/wastebox_blue.png" alt="">
                        <div @click="clickRubbish(`household`,`blue`,`paper`)" class="js-bucks bucks paper" data-type="household">
                        </div>
                    </div>
                    <div class="container-bucks red">
                        <div class="bucks-name bucks-name--red">
                            {{$t('game.hazardous')}}
                        </div>
                        <img class="top-bucks top-bucks--red" src="/images/RubbishGame/bucks/buck_top/wastetop_gray.png" alt="">
                        <img src="/images/RubbishGame/bucks/wastebox_gray.png" alt="">
                        <div @click="clickRubbish(`hazardous`,`red`,`mixed-waste`)" class="js-bucks bucks mixed-waste" data-type="hazardous">
                        </div>
                    </div>
                </div>
            </div>
            <div class="alert-fix">
                <div id="right-alert" class="right-alert alert"></div>
            </div>
            <div class="alert-fix">
                <div id="mistake-alert" class="mistake-alert alert"></div>
            </div>
            <div class="menu">
                <div class="menu-score">
                    <div class="right-counter counter right-menu">
                        <div class="right-counter--back">
                            <img src="/images/RubbishGame/ok.png" alt="">
                            <span class="right-counter-text js-right-counter js-right-counter--menu">0</span>
                        </div>
                    </div>
                    <div class="mistake-counter counter mistake-menu">
                        <div class="mistake-counter--back">
                            <img src="/images/RubbishGame/no.png" alt="">
                            <span class="right-counter-text js-mistake-counter js-mistake-counter--menu">0</span>
                        </div>
                    </div>
                </div>
                <div @click="newGame();runTime();clickSound('click')" class="play-again">
                    <img src="/images/RubbishGame/reload.png" alt="">
                    <span>{{$t('game.play-again')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as lottie from '@/3d/lottie';
export default {
    name: "Rubbish",
    // data() {
    //     return { name: 'rubbish' }
    // },
    mounted() {
        window.localGame ={};
        localGame.lottie = lottie;
        localGame.score = document.querySelector(".js-right-counter")
        localGame.youScore = document.querySelector(".js-right-counter--menu")
        localGame.youScoreWin = document.querySelector(".rubbish-text--winScore")
        localGame.timerShow = document.getElementById("timer");
        localGame.inGame = true;
        localGame.flag = true;
        localGame.soundHover = document.querySelector(".sound-hover")
        document.querySelector('.close-rubbish').addEventListener('mouseenter',()=>{
            localGame.soundHover.currentTime = 0;
            localGame.soundHover.play()
        });
        document.querySelector('.rubbish-start').addEventListener('mouseenter',()=>{
            localGame.soundHover.currentTime = 0;
            localGame.soundHover.play()
        });
        document.querySelector('.play-again').addEventListener('mouseenter',()=>{
            localGame.soundHover.currentTime = 0;
            localGame.soundHover.play()
        }) 
    },
    methods: {
        runTime() {
            localGame.seconds = 60;
            localGame.timer = setInterval(function () {
            localGame.seconds -= 0.01;
            if (localGame.seconds < 0) {
                clearInterval(localGame.timer);
                localGame.youScore.innerHTML = localGame.score.textContent;
                document.querySelector(".right-counter.active").classList.remove('active');
                document.querySelector(".mistake-counter.active").classList.remove("active");
                document.querySelector(".rubbish-text--train").classList.add("active");
                document.querySelector(".rubbish-text--win").classList.add("active");
                document.querySelector(".cub-svg").classList.remove("active");
                document.querySelector(".menu").classList.add("active");
                // document.querySelector(".you-are-win").classList.add("active");
                document.querySelectorAll('.bucks').forEach((item)=>{
                    item.classList.remove('active')
                })
                localGame.drag.remove();
                localGame.circle.remove();
                localGame.kostB.remove()
                localGame.kostT.remove()
                localGame.lottie.destroy()
                setTimeout(()=>{
                    localGame.lottie.destroy()
                }, 400)
                localGame.timerShow.innerHTML = 0;
                clearInterval(localGame.timer)
            } else {
                document.querySelector(".timer-svg").style.strokeDashoffset = 0 - 8.8*(60-localGame.seconds);
            }
        }, 10)
        },
        initGameRub() {
            this.clickSound("click")
            if(localGame.kostT != null || localGame.kostB != null){
                localGame.kostB.remove();
                localGame.kostT.remove();
            }
            if(document.querySelector('.rubbish-start') != null){
                setTimeout(()=>{
                    document.querySelector('.rubbish-start').classList.add('active');
                },10)
            }
            if(document.querySelector(".rubbish-text--train.active") != null){
                document.querySelector(".rubbish-text--train.active").classList.remove("active");
                document.querySelector(".rubbish-text--win.active").classList.remove("active");
            }
            document.querySelector(".cub-svg").classList.add("active");
            document.querySelector('.right-counter').classList.add('active')
            document.querySelector('.mistake-counter').classList.add('active')
            document.querySelectorAll('.bucks').forEach((item)=>{
                item.classList.add('active')
            })
            localGame.svgYeah = localGame.lottie.loadAnimation({
            container: document.getElementById('right-alert'),
            renderer: 'svg',
            name:"svgYeah",
            loop: false,
            path: '/json/gameRubbish/yeah.json'
            });

            localGame.svgError = localGame.lottie.loadAnimation({
            container: document.getElementById('mistake-alert'),
            renderer: 'svg',
            loop: false,
            path: '/json/gameRubbish/error.json'
            });
            // localGame.svgWin = localGame.lottie.loadAnimation({
            // container: document.getElementById('svgWin'),
            // renderer: 'svg',
            // loop: true,
            // autoplay: false,
            // path: '/json/gameRubbish/win.json'
            // });
            localGame.rubArr = [
                {type:"recyclable",url:"RubbishGame/trash/1.svg"},
                {type:"recyclable",url:"RubbishGame/trash/2.svg"},
                {type:"recyclable",url:"RubbishGame/trash/3.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/4.svg"},
                {type:"recyclable",url:"RubbishGame/trash/5.svg"},
                {type:"recyclable",url:"RubbishGame/trash/6.svg"},
                {type:"recyclable",url:"RubbishGame/trash/7.svg"},
                {type:"recyclable",url:"RubbishGame/trash/9.svg"},
                {type:"hazardous",url:"RubbishGame/trash/10.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/11.svg"},
                {type:"household",url:"RubbishGame/trash/12.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/13.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/14.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/15.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/16.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/17.svg"},
                {type:"household",url:"RubbishGame/trash/18.svg"},
                {type:"household",url:"RubbishGame/trash/19.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/20.svg"},
                {type:"hazardous",url:"RubbishGame/trash/21.svg"},
                {type:"household",url:"RubbishGame/trash/22.svg"},
                {type:"household",url:"RubbishGame/trash/23.svg"},
                {type:"hazardous",url:"RubbishGame/trash/24.svg"},
                {type:"household",url:"RubbishGame/trash/25.svg"},
                {type:"household",url:"RubbishGame/trash/26.svg"},
                {type:"recyclable",url:"RubbishGame/trash/27.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/28.svg"},
                {type:"recyclable",url:"RubbishGame/trash/29.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/30.svg"},
                {type:"household",url:"RubbishGame/trash/31.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/32.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/33.svg"},
                {type:"recyclable",url:"RubbishGame/trash/34.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/35.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/36.svg"},
                {type:"recyclable",url:"RubbishGame/trash/38.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/39.svg"},
                {type:"recyclable",url:"RubbishGame/trash/40.svg"},
                {type:"household",url:"RubbishGame/trash/41.svg"},
                {type:"recyclable",url:"RubbishGame/trash/42.svg"},
                {type:"recyclable",url:"RubbishGame/trash/43.svg"},
                {type:"recyclable",url:"RubbishGame/trash/44.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/45.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/46.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/47.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/48.svg"},
                {type:"mixed-waste",url:"RubbishGame/trash/49.svg"},
                {type:"hazardous",url:"RubbishGame/trash/50.svg"},
                {type:"household",url:"RubbishGame/trash/51.svg"},
                {type:"recyclable",url:"RubbishGame/trash/52.svg"},
            ];
            localGame.tick = localGame.rubArr.length;
            localGame.counter = 0;
            localGame.mistakes = 0;
            localGame.rights = 0;
            localGame.totMist = 4;
            localGame.overlapThreshold = "50%";
            localGame.right = document.querySelector(".js-right-counter");
            localGame.droppables = document.querySelectorAll('.js-bucks');
            localGame.field = document.querySelector('.js-game');
            localGame.rightAlert = document.querySelector('.right-alert');
            localGame.mistakeAlert = document.querySelector('.mistake-alert');
            this.initRubbish2(localGame.rubArr,localGame.counter,localGame.tick,localGame.mistakes,localGame.rights,localGame.totMist,localGame.right,localGame.droppables,localGame.field,localGame.rightAlert,localGame.mistakeAlert,this);
            document.querySelector(".menu").classList.remove("active");
        },
        initRubbish2(rubArr,counter,tick,mistakes,rights,totMist,right,droppables,field,rightAlert,mistakeAlert,rub){
            localGame.rand = Math.floor(Math.random() * localGame.rubArr.length);
            localGame.kostT = document.createElement("div")
            localGame.kostT.setAttribute("class","kost-trash");
            localGame.kostB = document.createElement("div")
            localGame.kostB.setAttribute("class","kost-back");
            document.querySelector(".rubbishGame").appendChild(localGame.kostB);
            document.querySelector(".rubbishGame").appendChild(localGame.kostT);
            localGame.circle = document.createElement("div")
            localGame.circle.setAttribute("class","back-img anim-delay");
            document.querySelector(".kost-back").appendChild(localGame.circle);
            setTimeout(()=>{
                localGame.circle.classList.add('active')
            },10)
            setTimeout(()=>{
                localGame.drag = document.createElement("img");
                localGame.drag.setAttribute("src","/images/"+rubArr[localGame.rand].url);
                localGame.drag.setAttribute("type",rubArr[localGame.rand].type);
                localGame.drag.setAttribute("class","curRubCont");
                document.querySelector(".kost-trash").appendChild(localGame.drag);
                localGame.rubArr.splice(localGame.rand, 1)
            },250)
            setTimeout(()=>{
                localGame.drag.classList.add('active')
            },270)
            },

        clickRubbish(type, color, oldType) {
            if(localGame.flag){
                localGame.flag = false;
                setTimeout(()=>{
                    localGame.flag = true
                }, 1600)
            }else{
                return
            }
            if(document.querySelector(".kost-trash") != null ){
                localGame.box = document.querySelector("."+oldType).getBoundingClientRect();
                localGame.cup = document.querySelector(".top-bucks--"+color);
                localGame.kostB.classList.add('active');
                localGame.cup.classList.add("active");
                localGame.top = localGame.box.top + 12;
                localGame.left =  localGame.box.left + (localGame.box.width/2);
                localGame.topC = localGame.box.top + 12;
                localGame.leftC =  localGame.box.left + (localGame.box.width/2);
                localGame.circle.classList.add('anim-go--'+color);
                localGame.kostB.style.top = localGame.topC + "px";
                localGame.kostB.style.left = localGame.leftC + "px";
                localGame.drag.classList.add('anim-go')
                localGame.kostT.style.top = localGame.top + "px";
                localGame.kostT.style.left = localGame.left + "px";
                localGame.i = localGame.droppables.length;
                if(localGame.drag.getAttribute("type") === type){
                    this.clickSound("star")
                    setTimeout(()=>{
                        this.toBackClass(localGame.rightAlert,"active")
                    },300)
                    localGame.svgYeah.goToAndPlay(6, 31);
                    // this.playAnimTo(localGame.svgWin,0,15);
                    document.querySelector(".right-counter--back").classList.add('active')
                    localGame.rights++;
                    document.querySelector(".js-right-counter").innerHTML = localGame.rights;
                    document.querySelector(".js-right-counter--menu").innerHTML = localGame.rights;
                    localGame.youScoreWin.innerHTML = localGame.rights;
                }else{
                    if(localGame.mistakes==52){
                        document.querySelector(".right-counter.active").classList.remove("active");
                        document.querySelector(".mistake-counter.active").classList.remove("active");
                        document.querySelector(".rubbish-text--train").classList.add("active");
                        document.querySelector(".rubbish-text--win").classList.add("active");
                        document.querySelector(".menu").classList.add("active");
                        document.querySelector(".cub-svg").classList.remove("active");
                        localGame.drag.remove();
                        localGame.circle.remove();
                        localGame.lottie.destroy();
                        localGame.kostB.remove()
                        localGame.kostT.remove()
                        clearInterval(localGame.timer)
                        return;
                    }
                    this.clickSound("fail")
                    document.querySelector(".mistake-counter--back").classList.add('active')
                    localGame.mistakes++;
                    document.querySelector(".js-mistake-counter").innerHTML = localGame.mistakes;
                    document.querySelector(".js-mistake-counter--menu").innerHTML = localGame.mistakes;
                    setTimeout(()=>{
                        this.toBackClass(localGame.mistakeAlert,"active");
                    },300)
                    localGame.svgError.goToAndPlay(0, 51)
                }
                localGame.counter++;
                localGame.tick--;
                setTimeout(()=>{
                    localGame.cup.classList.remove('active')
                    document.querySelector(".right-counter--back").classList.remove('active')
                    document.querySelector(".mistake-counter--back").classList.remove('active')
                    localGame.drag.remove();
                    localGame.circle.remove();
                    localGame.kostB.remove();
                    localGame.kostT.remove();
                    if(localGame.counter < 31 && document.querySelector(".curRubCont") == null){
                        this.initRubbish2(localGame.rubArr,localGame.counter,localGame.tick,localGame.mistakes,localGame.rights,localGame.totMist,localGame.right,localGame.droppables,localGame.field,localGame.rightAlert,localGame.mistakeAlert,this);
                    }else{
                        document.querySelector(".rubbish-text--train").classList.add("active");
                        document.querySelector(".rubbish-text--win").classList.add("active");
                        document.querySelector(".menu").classList.add("active");
                        document.querySelector(".right-counter.active").classList.remove("active");
                        document.querySelector(".mistake-counter.active").classList.remove("active");
                        document.querySelector(".cub-svg").classList.remove("active");
                        document.querySelectorAll('.bucks').forEach((item)=>{
                            item.classList.remove('active')
                        })
                        // document.querySelector(".you-are-win").classList.add("active");
                    }
                },1000);
            }
        },
        toBackClass(element,elClass){
            element.classList.add(elClass);
            setTimeout(()=>{
                element.classList.remove(elClass);
            },1400);
        },
        newGame(){
            this.clickSound("click")
            localGame.drag.remove();
            localGame.circle.remove();
            localGame.kostB.remove();
            localGame.kostT.remove();
            localGame.lottie.destroy()
            document.querySelector(".js-mistake-counter").innerHTML = 0;
            document.querySelector(".js-right-counter").innerHTML = 0;
            document.querySelector(".js-right-counter--menu").innerHTML = 0;
            document.querySelector(".js-mistake-counter--menu").innerHTML = 0;
            localGame.youScoreWin.innerHTML = 0;
            localGame.drag.remove();
            localGame.circle.remove();
            localGame.kostB.remove();
            localGame.kostT.remove();
            localGame.lottie.destroy()
            if(document.querySelectorAll('.bucks').length > 0){
                document.querySelectorAll('.bucks').forEach((item)=>{
                    item.classList.remove('active')
                })
            }
            this.initGameRub();
            this.removeCup()
        },
        closeGame(){
            this.clickSound("click")
            document.querySelector('.rubbishGame').classList.remove('active');
            local.iSayStay=false;
            local.icons.Game_waste.renderingGroupId = 1;
            setTimeout(()=>{
                localGame.lottie.destroy()
                document.querySelector(".js-mistake-counter").innerHTML = 0;
                document.querySelector(".js-right-counter").innerHTML = 0;
                document.querySelector(".js-right-counter--menu").innerHTML = 0;
                document.querySelector(".js-mistake-counter--menu").innerHTML = 0;
                document.querySelector(".rubbish-text--win").classList.remove("active");
                document.querySelector(".rubbish-text--train").classList.remove("active");
                localGame.youScoreWin.innerHTML = 0;
                if(document.querySelector(".menu.active") != null){
                    document.querySelector(".menu").classList.remove("active");
                }
                if(localGame.drag != null || localGame.kostT != null || localGame.kostB != null){
                    localGame.drag.remove();
                    localGame.circle.remove();
                    localGame.kostB.remove();
                    localGame.kostT.remove();
                    localGame.lottie.destroy()
                    document.querySelector('.rubbish-start').classList.remove('active');
                    document.querySelector('.cub-svg').classList.remove('active');
                }
                clearInterval(localGame.timer);
            },100)
            if(document.querySelectorAll('.bucks').length > 0){
                document.querySelectorAll('.bucks').forEach((item)=>{
                    item.classList.remove('active')
                })
            }
            this.removeCup()
        }
        ,
        removeCup(){
            if(document.querySelector(".right-counter--back.actie") != null){
                document.querySelector(".right-counter--back").classList.remove('active')
            }
            localGame.allCup = document.querySelectorAll(".top-bucks")
            if(localGame.allCup.length >= 1){
                localGame.allCup.forEach(item => {
                    item.classList.remove('active')
                });
            }
        },
        playAnimTo(anim,first,end){
            anim.goToAndPlay(first, end)
            let stop = setInterval(()=>{
                if (anim.currentFrame > end-1){
                    anim.pause();
                    clearInterval(stop);
                }
            },10)
        },
        clickSound(name){
            let soundClick = document.querySelector('.sound-' + name)
            soundClick.currentTime = 0
            soundClick.play()
        }
    }
}
</script>

<style>
    .close-rubbish{
        position: absolute;
        top: 3%;
        right: 3%;
        width: 40px;
        height: 40px;
        transition: all 250ms ease;
        transform: scale(1);
        cursor: pointer;
        z-index: 10;
    }
    .close-rubbish span{
        top: 18px;
        position: absolute;
        transform: rotate(45deg);
        width: 40px;
        height: 3px;
        background-color: #C4C4C4;
    }
    .close-rubbish span::after{
        content: "";
        position: absolute;
        transform: rotate(90deg);
        width: 40px;
        height: 3px;
        background-color: #C4C4C4;
    }
    .cub-svg{
        width: 0px;
        height: 0px;
        position: fixed;
        top: 45%;
        left: 50%;
        z-index: -1;
        opacity: 0;
        transition: all 350ms ease;
    }
    .cub-svg.active{
        opacity: 1;
        z-index: 1;
        transition: all 250ms ease;
    }
    .timer-svg{
        stroke-dashoffset: 0;
        stroke-dasharray: 600;
        transform: rotate(-90deg);
        position: absolute;
        width: 377px;
        height: 377px;
        top: -188.5px;
        left: -188.5px;
    }
    .cls-1{
        fill:none;
        stroke:#AF63E6;
        stroke-miterlimit:0;
        stroke-width:16px;
        stroke-linecap: round;
    }
    .alert-fix{
        position: fixed;
        width: 0px;
        height: 0px;
        top: 45%;
        left: 50%;
    }
    .rubbishGame{
        position: fixed;
        z-index: 1;
        height: 100vh;
        width: 100vw;
        transform: scale(0);
        z-index: -10;
        transition: all 450ms ease;
    }
    .rubbishGame.active{
        transform: scale(1);
        z-index: 10;
        transition: all 450ms ease;
    }
    .bucks-name{
        position: absolute;
        height: 35px;
        width: 85%;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -28%;
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;
        border-radius: 39px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bucks-name--yellow{
        background-color: #FFCE00;
    }
    .bucks-name--green{
        background-color: #8BBF0C;
    }
    .bucks-name--blue{
        background-color: #0060EE;
    }
    .bucks-name--red{
        background-color: #DE2525;
    }
    .play-again{
        width: 316px;
        height: 76px;
        background: #AF63E6;
        border-radius: 44px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
    }
    .play-again span{
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 45px;
        line-height: 58px;
        color: #FFFFFF;
    }
    .menu-score{
        display: flex;
        margin-bottom: 28px;
    }
    .counter{
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 45px;
        line-height: 58px;
        color: #FFFFFF;
    }
	.game {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
	}
    .curRubCont{
        width: 140px;
        height: 140px;
        -o-object-fit: contain;
        object-fit: contain;
        transform: scale(0);
        transition: all 350ms ease;
        z-index: 3;
    }
	.active.curRubCont{
		transform: scale(1);
	}
	.bucks {
	    width: 100px;
	    height: 100px;
	    border-radius: 50%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    color: #fff;
        position: absolute;
        top: 0%;
        right: 0;
        left: 0;
        margin: 0 auto;
        z-index: 6;
	}
    .bucks.active{
        cursor: pointer;
    }
    .container-bucks .top-bucks{
        position: absolute;
        top: -6px;
        right: 22px;
        margin-bottom: 0;
        width: 110px;
        z-index: 1;
    }
    .top-bucks.active{
        animation-name: cup-anim;
        animation: cup-anim 750ms ease;
    }
    @keyframes cup-anim {
        0% { top: -6px; }
        50% { top: -70px; }
        100% { top: -6px; }
    }
    #right-alert{
        width: 500px;
        height: 500px;
        top: -250px;
        left: -250px;
        opacity: 0;
        transition: all 150ms linear;
    }
    #mistake-alert{
        width: 160px;
        height: 160px;
        top: -80px;
        left: -80px;
        opacity: 0;
        transition: all 150ms linear;
    }
	.alert{
		font-size: 36px;
		position: absolute;
		color: #fff;
	}
    #right-alert.active{
        opacity: 1;
    }
	#mistake-alert.active{
		opacity: 1;
	}
	.menu{
		display: flex;
        flex-direction: column;
		justify-content: center;
		align-items: center;
	    margin-bottom: 5%;
		box-sizing: border-box;
		position: fixed;
		transform: scale(0);
        transition: all 150ms ease;
		z-index: -1;
	}
	.menu.active{
		transform: scale(1);
        transition: all 250ms ease;
        z-index: 40;
	}
	.menu-end{
		font-size: 24px;
	}
    #timer{
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        display: none;
        margin: 0 auto;
        width: 100px;
        height: 100px;
        font-size: 43px;
        text-align: center;
        color: #6b6b6b;
    }
    .curRubCont.anim-go{
        transition: all 450ms ease;
        animation-name: trash-anim;
        animation-duration: 1.5s;
        animation-timing-function: cubic-bezier(0, 1.05, .79, 1);
        animation-fill-mode: forwards;
    }
    .back-img.anim-go--yellow{
        transition: all 450ms ease;
        animation-name: anim-yellow;
        animation-duration: 750ms;
        animation-timing-function: cubic-bezier(0, 1.05, .79, 1);
        animation-fill-mode: forwards;
    }
    .back-img.anim-go--green{
        transition: all 450ms ease;
        animation-name: anim-green;
        animation-duration: 750ms;
        animation-timing-function: cubic-bezier(0, 1.05, .79, 1);
        animation-fill-mode: forwards;
    }
    .back-img.anim-go--blue{
        transition: all 450ms ease;
        animation-name: anim-blue;
        animation-duration: 750ms;
        animation-timing-function: cubic-bezier(0, 1.05, .79, 1);
        animation-fill-mode: forwards;
    }
    .back-img.anim-go--red{
        transition: all 450ms ease;
        animation-name: anim-red;
        animation-duration: 750ms;
        animation-timing-function: cubic-bezier(0, 1.05, .79, 1);
        animation-fill-mode: forwards;

    }
    .back-img.anim-go--orange{
        transition: all 450ms ease;
        animation-name: anim-orange;
        animation-duration: 750ms;
        animation-timing-function: cubic-bezier(0, 1.05, .79, 1);
        animation-fill-mode: forwards;

    }
    .back-img.anim-delay{
        transition-delay: 150ms;
    }
    @keyframes anim-orange {
        from {
            transform: scale(1);
        }
        to {
            background-color: orange;
            transform: scale(0);
        }
    }
    @keyframes anim-red {
        from {
            transform: scale(1);
        }
        to {
            background-color: #FF5050;;
            transform: scale(0);
        }
    }
    @keyframes anim-blue {
        from {
            transform: scale(1);
        }
        to {
            background-color: #008fdf;
            transform: scale(0);
        }
    }
    @keyframes anim-yellow {
        from {
            transform: scale(1);
        }
        to {
            background-color: #dea900;
            transform: scale(0);
        }
    }
    @keyframes anim-green {
        from {
            transform: scale(1);
        }
        to {
            background-color: #00dc24;
            transform: scale(0);
        }
    }
    @keyframes trash-anim {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(0.1);
        }
    }
    .game-background--top{
        width: 808px;
        height: 648px;
        background-color: #fff;
        border-radius: 44px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .bottom-trash{
        display: flex;
        position: absolute;
        bottom: -15%;
    }
    .back-img{
        position: absolute;
        top: -114.5px;
        left: -114.5px;
        width: 229px;
        height: 229px;
        background-color: #f9f8f8;
        border-radius: 50%;
        transform: scale(0);
        transition: all 350ms ease;
        z-index: 2;
    }
    .back-img.active{
        transform: scale(1);
    }
    .container-bucks {
        position: relative;
        top: -70%;
    }
    .container-bucks img{
        width: 150px;
        margin-left: 5px;
        margin-right: 5px;
        position: relative;
        z-index: 5;
    }
    .rubbish-start{
        margin: 0 auto;
        top: 26%;
        position: absolute;
        left: 0;
        right: 0;
        width: 200.65px;
        height: 200.65px;
        background-color: #AF63E6;
        border-radius: 50%;
        transition: all 150ms ease;
        z-index: 10;
        border: 0px;
        color: #fff;
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 45px;
        line-height: 58px;
        display: flex;
        justify-content: center;
        align-items: center ;
    }
    .rubbish-start.active{
        transition: all 150ms ease;
        transform: scale(0);
    }
    @media (hover: hover) {
        .close-rubbish:hover{
            transition: all 250ms ease;
            transform: scale(1.2);
        }
     }
    .right-counter{
        width: 151.5px;
        height: 76px;
        background: #A6D160;
        border-radius: 44px;
        position: absolute;
        left: 7%;
        top: 37%;
        opacity: 0;
        transition: all 250ms ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .right-counter--back{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 50%;
        z-index: 3;
        transform: scale(1);
    }
    .mistake-counter{
        width: 151.5px;
        height: 76px;
        background: #E5017D;;
        border-radius: 44px;
        position: absolute;
        right: 7%;
        top: 37%;
        opacity: 0;
        transition: all 250ms ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mistake-counter--back{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 50%;
        z-index: 3;
        transform: scale(1);
    }
    .right-counter--back.active{
        animation: animCircle 200ms ease;
    }
    .mistake-counter--back.active{
        animation: animCircle 200ms ease;
    }
    .right-counter.active{
        opacity: 1;
    }
    .mistake-counter.active{
        opacity: 1;
    }
    .right-menu{
        margin-right: 8px;
        position: static;
        top: inherit;
        left: inherit;
        width: 151.5px;
        height: 76px;
        background: #A6D160;
        border-radius: 44px;
        opacity: 1;
    }
    .mistake-menu{
        margin-left: 8px;
        top: inherit;
        right: inherit;
        position: static;
        width: 152px;
        height: 76px;
        background: #E5017D;
        border-radius: 44px;
        opacity: 1;
    }
    @keyframes animCircle {
        0% { transform:scale(1); }
        50% { transform:scale(1.3); }
        100% { transform:scale(1); }
    }
    .kost-back{
        width: 0px;
        height: 0px;
        top: 45%;
        left: 50%;
        position: fixed;
        z-index: 2;
        transition-delay: 150ms;
        transition: all 450ms ease;
    }
    .kost-back.active{
        transition-delay: 75ms;
    }
    .kost-trash{
        width: 0px;
        height: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 45%;
        left: 50%;
        position: fixed;
        z-index: 4;
        transition: all 450ms ease;
    }
    .rubbish-text{
        width: 60%;
        font-size: 32px;
        line-height: 41px;
        font-family: "Gerbera-Bold";
        color: #162B75;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        position: absolute;
        top: 2%;
        left: 0px;
        right: 0px;
        margin: 0 auto;
    }
    .rubbish-text--train{
        opacity: 1;
        transition: all 150ms ease;
    }
    .rubbish-text--train.active{
        opacity: 0;
        transition: all 150ms ease;
    }
    .rubbish-text--win{
        position: absolute;
        opacity: 0;
        transition: all 150ms ease;
    }
    .rubbish-text--win.active{
        opacity: 1;
        transition: all 150ms ease;
    }
    @media (max-height: 900px) {
        .menu{
            margin-bottom: 5%;
        }
        .timer-svg{
           width: 320px;
           height: 320px;
           top: -160px;
           left: -160px;
        }
        .play-again {
            width: 270px;
            height: 65px;
        }
        .game-background--top {
            height: 600px;
        }
        .container-bucks img {
            width: 135px;
        }
        .game-background--bottom {
            width: 600px;
        }
        .container-bucks .top-bucks {
            top: -6px;
            right: 18px;
            width: 100px;
        }
        .curRubCont {
            width: 140px;
            height: 140px;
            left: calc(50% - 70px);
        }
        .back-img {
            width: 195px;
            height: 195px;
            left: -97.5px;
            top: -97.5px;
        }
        .rubbish-text {
            width: 50%;
            font-size: 24px;
            line-height: 30px;
        }
        .right-counter {
            width: 130px;
            height: 60px;
        }
        .play-again span {
            font-style: normal;
            font-family: "Gerbera-Bold";
            font-size: 30px;
            line-height: 40px;

        }
        .mistake-counter{
            width: 130px;
            height: 60px;
        }
        .right-counter--back img{
            width: 28px;
        }
        .mistake-counter--back img{
            width: 23px;
        }
        .play-again img{
            width: 30px;
        }
        .counter {
            font-size: 24px;
        }
        #timer {
            font-size: 36px;
        }
        @keyframes cup-anim {
            0% { top: -6px; }
            50% { top: -65px; }
            100% { top: -6px; }
        }
    }
    @media (max-height: 785px) {
        .timer-svg{
           width: 260px;
           height: 260px;
           top: -130px;
           left: -130px;
        }
        .bottom-trash {
            display: flex;
            position: absolute;
            bottom: -20%;
        }
        .game-background--top {
            width: 808px;
            height: 500px;
        }
        .container-bucks img {
            width: 130px;
        }
        .game-background--bottom {
            width: 565px;
        }
        .container-bucks .top-bucks {
            right: 13px;
            width: 100px;
        }
        .curRubCont {
            width: 100px;
            height: 100px;
        }
        .back-img {
            width: 158px;
            height: 158px;
            left: -79px;
            top: -79px;
        }
        .rubbish-start{
            width: 170.65px;
            height: 170.65px;
            font-size: 34px;
            line-height: 43px;
            top: 28%;
        }
        .right-counter {
            top: 35%;
        }
        .mistake-counter {
            top: 35%;
        }
        .kost-back{
            top: 39%;
        }
        .kost-trash{
            top: 39%;
        }
        .alert-fix {
            top: 39%;
        }
        .cub-svg {
            top: 39%;
        }
        .counter {
            font-size: 24px;
        }
        #timer {
            font-size: 36px;
        }
        #right-alert {
            top: -250px;
        }
        #mistake-alert {
            top: -80px;
        }
        @keyframes cup-anim {
            0% { top: -8px; }
            50% { top: -65px; }
            100% { top: -8px; }
        }
        .menu {
            top: 37%;
        }
    }
    @media (max-width: 1320px){
        .rubbish-text {
            width: 70%;
        }
    }
    @media (max-width: 822px){
        .rubbish-text {
            width: 70%;
        }
        .game-background--top {
            width: 680px;
        }
        .right-counter {
            left: 3%;
        }
        .mistake-counter {
            right: 3%;
        }
    }
    @media (max-width: 690px){
        .close-rubbish {
            top: 2%;
            width: 30px;
            height: 30px;
        }
        .close-rubbish span {
            top: 14px;
            width: 30px;
            height: 3px;
        }
        .close-rubbish span::after {
            width: 30px;
            height: 3px;
        }
        .container-bucks {
            position: relative;
            top: -70%;
        }
        .game-background--top {
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }
        .game-background--bottom {
            width: 100%;
            border-radius: 0px;
        }
        .container-bucks img {
            width: 130px;
            margin-left: 2px;
            margin-right: 2px;
        }
        .container-bucks .top-bucks {
            right: 15px;
            width: 100px;
            top: -4px;
        }
        .mixed-waste br{
            display: block;
        }
        .right-counter {
            width: 112px;
            height: 56.18px;
            left: calc(35% - 75px);
            right: inherit;
            top: 17%;
        }
        .mistake-counter {
            width: 112px;
            height: 56.18px;
            right: calc(35% - 75px);
            top: 17%;
        }
        .bucks-name{
            height: 26.99px;
            font-size: 16px;
            line-height: 16px;
        }
        .counter {
            font-size: 24px;
        }
        .right-menu{
            top:inherit;
        }
        .mistake-menu{
            top:inherit;
        }
        .play-again {
            width: 240px;
            height: 66.18px;
        }
        .rubbish-text {
            font-size: 24px;
            top: 7%;
            width: 90%;
        }
        .bottom-trash {
            display: flex;
            position: absolute;
            bottom: 7%;
        }
        @keyframes cup-anim {
            0% { top: -5px; }
            50% { top: -65px; }
            100% { top: -5px; }
        }
    }
    @media (max-width: 536px){
        .container-bucks img {
            width: 130px;
            margin-left: -18px;
            margin-right: -18px;
        }
        .yellow img{
            z-index: 9;
        }
        .green img{
            z-index: 8;
        }
        .blue img{
            z-index: 7;
        }
        .red img{
            z-index: 6;
        }
        .bucks {
            z-index: 10;
            left: -5px;
        }
        .bucks-name {
            top: -35%;
            width: 100%;

            transform: rotate(-45deg);
        }
        .menu {
            top: 30%;
        }
    }
    @media (max-width: 415px){
        .container-bucks img {
            width: 120px;
            margin-left: -16px;
            margin-right: -16px;
        }
        .container-bucks .top-bucks {
            right: 15px;
            width: 90px;
            top: -5px;
        }
        .cub-svg {
            top: 40%;
        }
        .alert-fix {
            top: 40%;
        }
        .kost-back {
            top: 40%;
        }
        .kost-trash {
            top: 40%;
        }
        .bucks-name {
            font-size: 14px;
        }
        @keyframes cup-anim {
            0% { top: -5px; }
            50% { top: -59px; }
            100% { top: -5px; }
        }
    }
    @media (max-width: 385px){
        .container-bucks img {
            width: 110px;
            margin-left: -15px;
            margin-right: -15px;
        }
        .bucks-name {
            top: -40%;
            width: 120%;
            transform: rotate(-45deg);
        }
        .container-bucks .top-bucks {
            right: 15px;
            width: 80px;
            top: -5px;
        }
        @keyframes cup-anim {
            0% { top: -5px; }
            50% { top: -50px; }
            100% { top: -5px; }
        }
    }
    @media (max-width: 349px){
        .container-bucks img {
            width: 100px;
            margin-left: -15px;
            margin-right: -15px;
        }
        .container-bucks .top-bucks {
            right: 16px;
            width: 70px;
            top: -4px;
        }
        .bucks-name {
            font-size: 12px;
        }
        @keyframes cup-anim {
            0% { top: -4px; }
            50% { top: -45px; }
            100% { top: -4px; }
        }
        .rubbish-text {
            font-size: 18px;
        }
    }
    @media (max-width: 330px) {
        .right-counter {
            width: 90px;
            height: 45.18px;
            top: 18%;
        }
        .mistake-counter {
            width: 90px;
            height: 45.18px;
            top: 18%;
        }
        .play-again {
            width: 200px;
            height: 50.18px;
        }
        .menu-score {
            margin-bottom: 15px;
        }
        .play-again span {
            font-size: 24px;
        }
        .play-again img {
            width: 24px;
        }
        .curRubCont {
            width: 70px;
            height: 70px;
        }
        .back-img {
            width: 130px;
            height: 130px;
            left: -65px;
            top: -65px;
        }
        .timer-svg{
            width: 216px;
            height: 216px;
            top: -107px;
            left: -108px;
        }
        .bottom-trash{
            bottom: 5%;
        }
        .rubbish-start {
            width: 160.65px;
            height: 160.65px;
            font-size: 32px;
            line-height: 43px;
            top: 27%;
        }
        #right-alert {
            width: 400px;
            height: 400px;
            top: -200px;
            left: -200px;
        }
        #mistake-alert {
            width: 138px;
            height: 138px;
            top: -69px;
            left: -69px;
        }
    }
    @media(max-height: 594px) and (min-width: 550px){
        .cub-svg{
            top: 40%;
        }
        .bottom-trash {
            bottom: 0%;
        }
        .alert-fix {
            top: 40%;
        }
        .right-counter {
            left: 20%;
            width: 100px;
            height: 50;
        }
        .mistake-counter {
            right: 20%;
            width: 100px;
            height: 50;
        }
        .right-menu{
            left: inherit;
        }
        .menu {
            top: 32%;
        }
        .mistake-menu{
            right: inherit;
        }
        .play-again {
            width: 210px;
            height: 55px;
        }
        .play-again span {
            font-style: normal;
            font-family: "Gerbera-Bold";
            font-size: 24px;
            line-height: 39px;
        }
        .play-again img {
            width: 23px;
        }
        .rubbish-start {
            width: 120.65px;
            height: 120.65px;
            font-size: 18px;
            line-height: 43px;
        }
        .rubbish-text {
            font-size: 16px;
        }
        .game-background--top {
            width: 100%;
            height: 100%;
            border-radius: 0px;
        }
        .bucks-name {
            height: 26px;
            font-size: 14px;
        }
        .game-background--bottom {
            width: 100%;
            border-radius: 0px;
        }
        .container-bucks img {
            width: 90px;
        }
        .container-bucks .top-bucks {
            top: -4px;
            right: 13px;
            width: 65px;
        }
        .kost-trash {
            top: 40%;
        }
        .kost-back {
            top: 40%;
        }
        #timer {
            right: -72%;
            top: 45%;
        }
        .rubbish-start {
            margin-bottom: 40px;
        }
        @keyframes cup-anim {
            0% { top: -4px; }
            50% { top: -44px; }
            100% { top: -4px; }
        }
    }
</style>
