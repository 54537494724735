let initWaste = function (local,scene,spriteGicon){
  let sprite = new BABYLON.Sprite("Game_waste", spriteGicon);
  sprite.position= new BABYLON.Vector3(-9.472,2.04,26.131);
  sprite.isPickable = true;
  sprite.height = .5;
  sprite.width = .5;
  sprite.playAnimation(0, 0, true, 40);
  local.icons.Game_waste = sprite;
  local.Games.Game_waste = ()=>{document.querySelector('.rubbishGame').classList.add('active')};
}

export{initWaste};
