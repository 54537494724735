<template>
  <div class="b-block js-b-block b-info js-b-info test_span">
    <div class="b-block-close js-b-block-close b-info--close js-b-info--close"
      @click="closeBlock()">
    <img class="b-block-close--btn js-b-block-close--btn info-close--btn js-info-close--btn"
      src="/images/b-close.png" alt="">
    </div>
    <div class="scroll-Info" data-mcs-theme="inset-3-dark">
      <div class="top-help--blur"></div>
      <span>
        {{$t('interfaceInfo.text-1')}}
        <br>
        <br>
        {{$t('interfaceInfo.text-2')}}
        <br>
        <br>
        {{$t('interfaceInfo.text-3')}}
      </span>
      <div class="bottom-help--blur"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpoInfo",
  methods: {
    closeBlock(){
      if(document.querySelector(".star-anim.anim-to") != null){
        document.querySelector(".star-anim.anim-to").classList.add("anim-out");
        document.querySelector(".star-anim_blue.anim-to").classList.add("anim-out");
      }
      if(document.querySelector(".right-item.active") != null){
        document.querySelector(".right-item.active").classList.remove("active");
      }
      document.querySelector(".b-block.active").classList.remove("active");
      document.querySelector(".star-anim").classList.remove("anim-to");
      document.querySelector(".star-anim_blue").classList.remove("anim-to");
    }
  },
}
</script>

<style scoped>

</style>
