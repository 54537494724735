<template>
     <div class="selectHero">
          <div class="selectHero-container">
               <div class="selectHero-text">
                    <span>{{$t('selectHero.h1')}}</span>
               </div>
               <div class="swiper-button swiper-button--selectHero swiper-button-prev--selectHero disable" @click="swipeHero(1)">
                    <img src="/images/interface/slider/selectHero_swiper-prev.png" alt="" class="slider-button">
               </div>
               <div class="swiper-button swiper-button--selectHero swiper-button-next--selectHero disable" @click="swipeHero(-1)">
                    <img src="/images/interface/slider/selectHero_swiper-next.png" alt="" class="slider-button">
               </div>
               <div id="heroPreloader"></div>
               <div class="left-section--hero">
                    <div class="swiper-button swiper-button--selectHero swiper-button-prev--selectHero disable" @click="swipeHero(1)">
                         <img src="/images/interface/slider/selectHero_swiper-prev.png" alt="" class="slider-button">
                    </div>
                    <div class="swiper-button swiper-button--selectHero swiper-button-next--selectHero disable" @click="swipeHero(-1)">
                         <img src="/images/interface/slider/selectHero_swiper-next.png" alt="" class="slider-button">
                    </div>
                    <span>{{$t('selectHero.h1')}}</span>
                    <canvas
                         id="selectHero"
                         ref="selectHero"
                         oncontextmenu="return false"
                         class="selectHero-swiper">
                    </canvas>
               </div>
               <div class="right-section--hero">
                    <div class="selectHero-input">
                         <input v-model="nickname" type="text" :placeholder="$t('selectHero.name')">
                    </div>
                    <div v-bind:class="canGo">
                         <button class="disable" @click="selectHero()">{{$t('selectHero.button')}}</button>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>


import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as lottie from '@/3d/lottie';
import store from "@/store";
export default {
     name: 'selectHero',
     mounted() {
          window.localH = {};
          localH.model = ["BOT_1","BOT_3","BOT_4","BOT_5","BOT_6","BOT_7","BOT_8","BOT_9","BOT_10","BOT_11","BOT_13","BOT_14","BOT_15","BOT_16","BOT_17","BOT_18"];
          localH.thisBot = 0;
          localH.appendMeshesHero = 0;
            this.check();
            setTimeout(()=>{
              console.log(store.state.userProfile)
            },2000)
     },data(){
       return{
         nickname:"",
       }
     },
     computed: {
       canGo(){
         if(this.nickname.length < 1)
           return "selectHero-button disable";
         else
           return "selectHero-button";
         }
       },
     methods: {
          check(){
            if(store.state.userProfile!=null){
              console.log(store.state.userProfile)
              if(store.state.userProfile.avatar != undefined ){
                window.heroesIsLoaded = true;
                window.isfirstTime = false;
                console.log("nтут")
              }else{
                store.dispatch("updateUserProfile", {Game_samokat: 0});
                store.dispatch("updateUserProfile", {Game_gorodki: 0});
                store.dispatch("updateUserProfile", {Game_Football: 0});
                store.dispatch("updateUserProfile", {Game_waste: 0});
                store.dispatch("updateUserProfile", {balance: 0});
                this.initSelect3d();
                window.isfirstTime = true;
              }
            }else{
              if(store.state.user.isAnonymous){
                if(localStorage.getItem("avatar") == null){
                  this.initSelect3d();
                  window.isfirstTime = true;
                }else{
                  window.heroesIsLoaded = true;
                  window.isfirstTime = false;
                }
              }else{
                setTimeout(()=>{this.check()},100);
              }
            }
          },
          ping() {
               this.$log.debug('Ping method of the BabylonHome!');
          },
          initSelect3d() {
               const canvas = this.$refs.selectHero;
               const createDefaultEngine = function () {
               return new BABYLON.Engine(canvas, true, {
                    preserveDrawingBuffer: true,
                    stencil: true,
                    disableWebGL2Support: false
               });
               }.bind(this);
               var preloader = lottie.loadAnimation({
                    container: document.getElementById('heroPreloader'),
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: '/json/heroloader.json'
               });
               const createScene = function () {
                    window.heroesIsLoaded = false;
                    // This creates a basic Babylon Scene object (non-mesh)
                    window.sceneHero = new BABYLON.Scene(this.engine);
                    window.sceneHero.clearColor = new BABYLON.Color4(0, 0, 0, 0);
                    window.lighting = BABYLON.CubeTexture.CreateFromPrefilteredData("https://assets.babylonjs.com/environments/environmentSpecular.env", sceneHero);
                    lighting.name = "runyonCanyon";
                    lighting.gammaSpace = false;
                    lighting.level = 2;
                    sceneHero.environmentTexture = lighting;

                    let num = 0 ;
                    let camera = new BABYLON.FreeCamera("camera1", new BABYLON.Vector3(0, 5, -10), sceneHero);
                    camera.position.set(0.25543378849352244, -0.9649087033693271, -10.170065932722126);
                    camera.rotation.set(-0.2829265981442895,-0.017392585750404935, 0);
                    this.$log.debug(`Loading model ${"BOT_9.glb"} scene: ${window.sceneHero} engine: ${this.engine}`)
                    function asyncAppendMeshesHero(){
                         BABYLON.SceneLoader.AppendAsync("/scene/choise/",localH.model[localH.appendMeshesHero]+".glb",sceneHero).then(()=>{
                              window.sceneHero.activeCamera = camera;
                              if(num==0){
                                   //document.getElementById('heroPreloader').remove();
                              }
                              sceneHero.meshes.forEach((item,i)=>{
                                   if(item.name!="Plane001"){
                                        item.scaling.set(.4,.4,.4);
                                        item.position.set(0,0,-4);
                                   }
                                        item.visibility=0;
                                   if(item.name.search("Skin_")>-1 || item.name.search("Slim_")>-1){
                                        item.name = localH.model[localH.appendMeshesHero]
                                   }
                                   item.position = new BABYLON.Vector3(0,-2,0);
                              })
                              num++;
                              if(localH.appendMeshesHero < localH.model.length - 1){
                                   localH.appendMeshesHero ++;
                                   asyncAppendMeshesHero();
                              }else{
                                   sceneHero.meshes.forEach(item=>{
                                        if(item.material != null){
                                             item.material.albedoTexture.level = 2.3;
                                        }
                                   })
                                   sceneHero.getMeshByName("BOT_1").visibility=1;
                                   document.getElementById('heroPreloader').remove();
                                   let buttonSwiper = document.querySelectorAll('.swiper-button--selectHero');
                                   buttonSwiper.forEach((item)=>{
                                        item.classList.remove('disable');
                                   })
                                   camera.position.set(0.25543378849352244, -0.9649087033693271, -10.170065932722126);
                                   camera.rotation.set(-0.2829265981442895,-0.017392585750404935, 0);
                                   sceneHero.createDefaultSkybox(sceneHero.environmentTexture, true, (camera.maxZ - camera.minZ)/2, 0.3, false);
                                   sceneHero.getMeshByName("hdrSkyBox").visibility = 0;

                                   if(!window.isMobileOrTablet()){
                                        localH.R = 200;
                                        localH.ground = BABYLON.Mesh.CreateGround("ground", 100, 100, 100, sceneHero, false);
                                        localH.groundMaterial = new BABYLON.StandardMaterial("ground", sceneHero);
                                        localH.ground.position.y=-2;
                                        localH.groundMaterial.emissiveColor = new BABYLON.Color3(localH.R/255, localH.R/255, localH.R/255);
                                        localH.ground.material = localH.groundMaterial;
                                        var light = new BABYLON.DirectionalLight("dir01", new BABYLON.Vector3(-0.2, -1, 1), sceneHero);
                                        light.position = new BABYLON.Vector3(0, 10, -12);
                                        light.intensity = 0.5
                                        // Shadows
                                        localH.shadowGenerator = new BABYLON.ShadowGenerator(4096, light);
                                        localH.shadowGenerator.bias = 0.0001;
                                        light.shadowMaxZ = 25;
                                        light.shadowMinZ = 12;
                                        localH.shadowGenerator.useContactHardeningShadow = true;
                                        localH.shadowGenerator.setDarkness(0.1);
                                        // localH.shadowGenerator.addShadowCaster(sceneHero.getMeshByName("BOT_10"));
                                        sceneHero.meshes.forEach((item, i) => {
                                             if(item.name.search("BOT") > -1){
                                                  localH.shadowGenerator.addShadowCaster(item)
                                             }
                                        });
                                        localH.ground.receiveShadows = true;
                                   }
                              }
                         })
                    }
                    asyncAppendMeshesHero();
                    window.clearBots = function (botName) {
                         sceneHero.meshes.forEach(item=>{
                              if(item.name == botName){
                                   item.visibility = 1;
                              }
                              else{
                                   item.visibility = 0;
                                   if(sceneHero.getMeshByName("ground")!=null){
                                        sceneHero.getMeshByName("ground").visibility = 1
                                   }
                              }
                         })
                    }
                    return window.sceneHero;
               }.bind(this);
               const initFunction = async function () {
                    const asyncEngineCreation = async function () {
                         try {
                              return createDefaultEngine();
                         } catch (e) {
                              this.$log.debug("the available createEngine function failed. Creating the default engine instead");
                              return createDefaultEngine();
                         }
                    }.bind(this);
                    this.engine = await asyncEngineCreation();
                    if (!this.engine) throw 'engine should not be null.';
                    this.sceneHero = createScene();
               }.bind(this);
               initFunction().then(() => {
                    this.engine.runRenderLoop(this.renderLoop);
               }).catch((reason) => {
                    this.$log.error("Babych initFunction failed", reason);
               });
               // Resize
               window.addEventListener("resize", this.onResize);
          },
          onResize() {
               if (this.engine)
               this.engine.resize();
          },

          renderLoop() {
               if (this.sceneHero && this.sceneHero.activeCamera) {
               this.sceneHero.render();
               }
          },
          swipeHero(to) {
               if(localH.thisBot + to < 0){
                    localH.thisBot = localH.model.length-1;
               }else if(localH.thisBot + to > localH.model.length-1){
                    localH.thisBot = 0;
               }else{
                    localH.thisBot+=to;
               }
               clearBots(localH.model[localH.thisBot]);
          },
          selectHero(){
            if(store.state.user.isAnonymous)
            {
              localStorage.setItem("avatar",localH.model[localH.thisBot])
            }else{
              store.dispatch("updateUserProfile", {nick: this.nickname})
              store.dispatch("updateUserProfile", {avatar: localH.model[localH.thisBot]}).catch(this.$log.error);
            }
               window.heroesIsLoaded = true;
               document.querySelector('.selectHero').remove();
               document.querySelector('.header').classList.add("griz");
               window.sceneHero.dispose();
          }

     },

     created() {
     },
};
</script>
<style>

#heroPreloader{
    position: absolute;
    height: 70%;
    width: 50%;
}
@media(max-height: 650px) and (min-width: 500px){
     #heroPreloader {
          height: 50%;
          width: 25%;
          top: 25%;
          left: 12%;
     }
}

</style>
