<template>
<div class="samokat">
  <div class="s-watch s-watch--js"></div>
  <div class="s-timer--start">3</div>
  <div class="s-panel s-panel--js">
    <span class="s-circle">
      <span @click="console()" class="s-circle s-circle-color--js"></span>
      <span class="s-circle s-circle-move s-circle--js"></span>
    </span>
  </div>
  <div class="s-end-js">
      <span class="time">0</span>
  </div>
  <div class="s-aler--end">
    <span>
      {{$t('samokat.your-time')}}
      <br>
      {{$t('samokat.your-time-1')}}
    </span>
    <br>
    <span class="s-time-end"></span>
  </div>
</div>
</template>

<script>
export default {
  mounted() {
    console.log("iha");
    document.querySelector('.s-timer--start').classList.add('start-s');
  },
  methods:{
    console(){
      let boost = document.querySelector(".s-circle--js").offsetLeft;
      let marker = document.querySelector(".s-circle-color--js");
      boost  = 1.3 - (Math.abs(boost)*1.5/175);
      boost = boost < -.4 ? -1.8 : boost;
      if(GS.boost < local.WSpeed*-1 && boost < 0){
        boost=0;
      }
      console.log(boost);
      GS.boost += boost;
      marker.style.opacity = .2 + boost;
      setTimeout(()=>{
        marker.style.opacity = .2 + boost;
      },300)
    }
  }
}
</script>
<style>
.samokat{
  position: fixed;
  height: 100%;
  width: 100%;
}
.s-aler--end{
  height: fit-content;
  width: fit-content;
  padding: 40px;
  position: fixed;
  top: 35%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 30px;
  text-align: center;
  transform: scale(0);
  transition: all 250ms ease;
}
.s-aler--end.end-som{
  transform: scale(1);
  transition: all 250ms ease;
}
.s-aler--end span{
  color:#162B75;
  font-size: 32px;
  padding-bottom: 12px;
}
.s-aler--end .s-time-end{
  font-family: "Gerbera-Bold";
  font-size: 40px;
}
.s-timer--start{
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 30%;
  width: fit-content;
  font-size: 150px;
  color: #fff;
  font-family: "Gerbera-Bold";
  transform: scale(0);
  transition: all 250ms ease;
}
.s-timer--start.start-s{
  transform: scale(1);
  transition: all 250ms ease;
  animation-name: time-s;
  animation-duration: .7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
.s-end-js{
  position: fixed;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: scale(0);
  transition: all 150ms ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Gerbera-Bold";
  font-size: 70px;
}
.s-end-js.start-s{
  transform: scale(1);
  transition: all 150ms ease;
}
.s-panel{
  width: 300px;
  left: calc(50% - 150px);
  background: #fff7;
  height: 80px;
  position: absolute;
  bottom: 10%;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: scale(0);
  transition: all 250ms ease;
}
.s-panel.active{
  transform: scale(1);
  transition: all 250ms ease;
}
.s-circle{
  height: 100%;
  width: 80px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  transition: all .3s ease;
}
.s-circle-color--js{
  background: green;
}
.s-circle-move{
  opacity:1;
  opacity: .9;
  background: #AF63E6;
  animation-name: move;
  animation-duration: 2.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  z-index: -10;
}
@keyframes move {
  0%{
   left: -150px;
  }
  50%{
    left: 150px;
  }
  100%{
    left: -150px;
  }
}
@keyframes time-s {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.3);
  }
  100%{
    transform: scale(1);
  }
}
@media (max-height: 800px) {
  .s-panel {
    height: 60px;
  }
  .s-circle {
    width: 65px;
  }
  .s-end-js {
    top: 15%;
  }
  @keyframes move {
    0%{
    left: -140px;
    }
    50%{
      left: 140px;
    }
    100%{
      left: -140px;
    }
  }
  .s-aler--end span {
    font-size: 28px;
  }
  .s-aler--end .s-time-end {
    font-size: 36px;
  }
}
@media (max-width: 570px) {
  .s-end-js {
    font-size: 60px;
  }
    .s-panel {
    height: 60px;
  }
  .s-circle {
    width: 65px;
  }
  .s-end-js {
    top: 15%;
  }
  @keyframes move {
    0%{
    left: -140px;
    }
    50%{
      left: 140px;
    }
    100%{
      left: -140px;
    }
  }
}
@media (max-width: 450px) {
  .s-panel {
    bottom: 18%;
  }
  .s-aler--end span {
    font-size: 18px;
  }
  .s-aler--end .s-time-end {
    font-size: 28px;
  }
}
@media (max-height: 675px) and (min-width: 500px){
  .s-aler--end {
    top: 27%;
  }
}

</style>
