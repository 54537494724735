<template>
  <div class="render-info js-render-info disable">
    <div class="container details-main fix-p">
      <!-- Кнопка возврата -->
      <div class="details-button--exit">
        <span @click="clickSound();close();" class="button-exit--img js-button-exit"></span>
        <span class="js-button-exit">{{$t('objectInfo.btnExit')}}</span>
      </div>
      <!-- /Кнопка возврата -->
      <!-- Заголовок и поиск -->
      <div class="details-name row">
        <div class="col-8 name-object">
          <span>{{ objectForCard.name }}</span>
          <input type="hidden">
        </div>
      </div>
      <!-- /Заголовок и поиск -->
      <!-- Ссылка и откуда -->
      <div class="details-link--box">
        <div class="details-linkFrom details-from">
          <div class="details-img--linkFrom details-from--img"></div>
          <span class="text">{{ objectForCard.manufacturer }}</span>
        </div>
        <div class="details-linkFrom details-link">
          <div class="details-img--linkFrom details-link--img"></div>
          <a :href="objectForCard.website" class="text" target="_blank">{{ objectForCard.website }}</a>
        </div>
      </div>
      <!-- /Ссылка и откуда -->
      <!-- Описание и слайдер -->
      <div class="details-more row row-cols-2">
        <div class="details-more--info col-12 col-xxl-6">
          <span>
            {{ objectForCard.description }}
          </span>
          <div class="more-info--specifications">
            <div class="size row">
              <span class="text col">{{$t('objectInfo.size')}}</span>
              <span class="size-number col">{{ objectForCard.size }}</span>
            </div>
            <div class="heft row">
              <span class="text col">{{$t('catalogDetails.helf')}}</span>
              <span class="heft-number col">{{ objectForCard.materials }}</span>
            </div>
          </div>
          <div class="details-more--button col-12 col-xxl-10" v-if="isReguser">
            <div v-bind:class="likeBtnClass" @click="likeTap()">
              <img src="/images/interface/detalis/like-details.png" class="details-button--none" alt="">
              <span class="details-button--none">{{$t('catalogDetails.like')}}</span>
            </div>
            <div v-bind:class="likedBtnClass">
              <img src="/images/interface/detalis/like-ok.png" class="details-button--ok" alt="">
              <span class="details-button--ok">{{$t('catalogDetails.like-ok')}}</span>
            </div>
          </div>
        </div>
        <div class="details-more--slider col-12 col-xxl-6 col-fix">
          <div class="swiper-help">
            <div @click="clickSound()" class="swiper-button swiper-button-prev--details">
              <img src="/images/interface/slider/pre_button.png" alt="" class="slider-button">
            </div>
            <div @click="clickSound()" class="swiper-button swiper-button-next--details">
              <img src="/images/interface/slider/next_button.png" alt="" class="slider-button">
            </div>
            <div v-swiper:objImageSwiper="swiperOption"
                class="swiper-container swiper-container--details">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper swiper-wrapper--details">
                <!-- Slides -->
                <div class="swiper-slide swiper-slide-details js-canvas-slide">
                  <div class="over-canvas-scroll"></div>
                  <img src="/images/interface/main_catalog/360deg.png" alt="" class="deg360">
                  <canvas class="detail-canvas" style="width: 100%;height:100%"></canvas>
                </div>
                <div class="swiper-slide swiper-slide-details">
                  <img :src="thumb(objectForCard.img1)" alt="">
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {directive} from 'vue-awesome-swiper';
import Vue from 'vue';
import {imop} from "@/utils";
import store from "@/store";
export default {
  props: ['objectForCard'],
  name: "ExpoObjectInfo",
  directives: {
    swiper: directive
  },
  data() {
    return {
      isReguser: true,
      swiperOption: {
        loop: false,
        loopFillGroupWithBlank: false,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next--details',
          prevEl: '.swiper-button-prev--details',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          // when window width is >= 300px
          300: {
            slidesPerView: 1,
            spaceBetween: 60,
            slidesPerGroup: 1,
          },
        }
      }
    };
  },
  mounted() {
    if(store.state.userProfile == null){
      this.isReguser = false;
    }
  },
  activated() {
   // InSceneStartCatalogDetails();
  },
   computed: {
     obj() {
     },
     likedObject() {
       const likes = store.state.userProfile?.objectsLikes || [];
       return likes.contains(localStorage.getItem('obj'));
     },
     likeBtnClass() {
       if (this.likedObject) {
         return "details-button details-like--none active";
       }
       return "details-button details-like--none";
     },
     likedBtnClass() {
       if (this.likedObject) {
         return "details-button details-like--ok active";
       }
       return "details-button details-like--ok";
     },
     likeRemuve(){
       if(!(obj.id === this.obj.id)){
         document.querySelector('.details-like--none').classList.remove("active")
         document.querySelector('.details-like--ok').classList.remove("active")
       }
     }
   },
  methods: {
      counterStar(plus) {
       local.starsCount  =  parseInt(store.state.userProfile.balance)+plus;
       store.dispatch("updateUserProfile", {balance: local.starsCount});
       document.querySelector(".js-counter_number").innerHTML = local.starsCount;
       document.querySelector(".js-head-counter_number").innerHTML = local.starsCount;
     },
    close(){
      document.querySelector(".js-render-info").classList.add("disable");
      document.querySelector(".header").classList.add("disable");
      window.sceneObj.dispose();
      window.engineObj.dispose();
      window.sceneObj = "";
      local.mouseDown = false;
      local.iSayStay = false;
      setTimeout(()=>{
        window.vexpo.loadObject=false
      },400)
    },
    thumb(url) {
      return imop(url, 'c_thumb,h_1024')
    },
    likeTap(){
      this.clickSound();
      store.dispatch("likeObject", {objectId: localStorage.getItem('obj')}).catch(this.$log.error);
      this.counterStar(1);
      document.querySelector('.details-like--none').classList.add("active");
      document.querySelector('.details-like--ok').classList.add("active");
    },
    closeInfo() {
      document.querySelector(".js-render-info").classList.add("disable");
      document.querySelector(".header").classList.add("disable");
      window.sceneObj.dispose();
      window.sceneObj = "";
    },
    clickSound(){
      let soundClick = document.querySelector('.sound-click')
      soundClick.currentTime = 0
      soundClick.play()
    },
  }
}
</script>

<style scoped>

  .details-main.fix-p {
    padding-top: 40px;
    min-height: 100vh;
    position: relative;
  }
  .name-object{
    width: 40.666667% !important;
  }
  .details-more--slider {
    position: absolute;
    right: 0%;
    top: calc(15% + -40px);
  }
  @media (max-width: 1400px){
    .details-more--slider {
      position: relative;
      right: inherit;
      top: inherit;
    }
    .name-object{
      width: 70.666667% !important;
    }
    .details-more--info{
      width: 100%;
    }
  }
  @media (max-width: 400px){
    .name-object{
      width: 80.666667% !important;
    }

  }
</style>
