import {addArrow} from "@/3d/addArrow";
let startGFame = function (){
  let stopSound = document.querySelector(".sound-move")
  let soundAlert = document.querySelector(".sound-alert")
  soundAlert.volume = 0.1
  let textAlert = document.querySelector(".goal-alert")
  stopSound.pause();
  local.runAnim.stop();
  local.walkAnim.stop();
  window.GF = {};
  GF.pins=[]
  GF.joystickSizes = {
        height: "45%",
        width: "45%",
        left: "-37.5%",
        top: "0%",
        offsetL: 1.32,
        offsetT: .52,
  }
  if(local.adt!=null){
    local.leftThCont.left=100000;
  }
  local.canReset = true;
  let imbaUGOL = Math.floor(local.camTarget.rotation.y/(2*Math.PI));
  const ballPosition = new BABYLON.Vector3(0, .4, 0.1);
  const ballDiameter = 0.2;
  const BitPosition = new BABYLON.Vector3(115.55,.74,-15.15);
   let cameraTPos = "";
  if(local.adt==null){
    cameraTPos = new BABYLON.Vector3(115.55,.2,-15);
    local.camTarget.rotation = new BABYLON.Vector3( .05, .3 + (imbaUGOL*2*Math.PI),-.025);
  }else{
    cameraTPos = new BABYLON.Vector3( 116.7, 1.3, -13.7);
    local.camTarget.rotation = new BABYLON.Vector3( .05, .16 + (imbaUGOL*2*Math.PI),-.025);
  }

  local.camTarget.position = cameraTPos;
  local.canMove = false;
  local.hero.position.set( 115.6, 0.74,-14);
  local.hero.rotation.y = .01;
  //scene.getAnimationGroupByName("Ball").name = "heroRun";
  local.ballAnim = scene.animationGroups.find(a => a.name === "Ball");
  //local.ballAnim.play(true);
  //local.ballAnim.setWeightForAllAnimatables(0);
  GF.interface = document.querySelector(".football-js");
  document.querySelector(".right-bar").classList.add("ingame");
  document.querySelector(".camera-factor").classList.add("ingame-cam");
  document.querySelector(".map").classList.add("ingame");
  if(document.documentElement.clientWidth > 1024){
    document.querySelector(".point-detected").classList.add("ingame");
  }else{
    document.querySelector(".nav-icon3").classList.add("ingame-menu");
  }
  setTimeout(()=>{
    GF.interface.classList.add("active")
  }, 250);

  document.querySelector(".football-js .g-exit-js").addEventListener("click",()=>{
    leaveGame(local);
  });
 document.querySelector(".football-js .play-again--game").addEventListener("click",()=>{
   leaveGame(local);
   setTimeout(()=>{
     local.Games.Game_Football()
   },280);
 })
  //сделать fov .65
  // Set the camera to be the main active camera
  GF.num = 0;
  GF.bits = 20;
  GF.balls = 0;
  GF.ballsCounter = document.querySelector(".football-js .g-point-js");
  GF.bitsCounter = document.querySelector(".football-js .g-bits-js");
  GF.ballsCounter.innerHTML = GF.balls;
  GF.bitsCounter.innerHTML = GF.bits;
  createBowlingBall(scene,ballPosition,ballDiameter,BitPosition,GF);
  window.arrGate = [{name:"Box001",x: 115.5,y: 2.5,z: -24.5,w: 13,h:10,rx: 0, ry: 0, rz: 0},
                    {name:"Box002",x: 112.9,y: 2,z: -23.46,w: 3,h:4,rx: 0, ry: 1.57, rz: 1.57},
                    {name:"Box003",x: 117.5, y: 2, z: -23.3,w: 3,h:4,rx: 0, ry: 1.57, rz: 1.57},
                    {name:"Box004",x: 115.4, y: 3.4, z: -23.5,w: 3,h:4, rx: 1.57, ry: 0, rz: 0},
                  ];
  if(scene.getMeshByName("PF")==null){
    const lane = createPG(scene); //create physics ground
  }
  createGate(GF);
  GF.targetBox = createTargetBox();
  if(scene.getMeshByName("ArrowParent") == null){
  //  console.log("тута")
    addArrow(local,scene,new BABYLON.Vector3(115.55,0.74,-15.6));
  }else{
    scene.getMeshByName("ArrowParent").position.set(115.55, 0.74, -15.6);
  }
  if(GF.isCreate!=null){
    GF.leftThCont.left = GF.Jsizes.left;
  }else{
    addGFJoystic(scene, GF);
  }
  function createTargetBox(){
    GF.pointsTarget  = [[116.8,1.2,-23],[116.7,2.8,-23],[115.5,1.3,-23],[114.2,1.3,-23],[114.2,2.8,-23]];
    let tb = BABYLON.MeshBuilder.CreateGround("boxTarget", {width: 1, height: 1}, scene)
    tb.rotation.x = 1.57;
    let pos = GF.pointsTarget[randomIntFromInterval(0, GF.pointsTarget.length-1)]
    tb.position  = new BABYLON.Vector3(pos[0],pos[1],pos[2]);
    GF.intersect = scene.onBeforeRenderObservable.add(()=>{
      if(scene.getMeshByName("Fball").intersectsMesh(GF.targetBox, false)){
        soundAlert.currentTime = 0;
        soundAlert.play()
        toBackClassFB(textAlert,"alert-text")
        let newpos = GF.pointsTarget[randomIntFromInterval(0, GF.pointsTarget.length-1)]
        tb.position.set(-100,-100,-100);
        setTimeout(()=>{
          tb.position  = new BABYLON.Vector3(newpos[0],newpos[1],newpos[2]);
        },1000)
        GF.balls++;
        GF.ballsCounter.innerHTML=GF.balls;
        document.querySelector(".football-text--winScore").innerHTML = GF.balls;
        document.querySelector(".menu-score-fb").innerHTML = GF.balls;
        // сюда попал!
      }
    })
    addMShader(tb);
  function addMShader(mesh) {
    BABYLON.Effect.ShadersStore.MotyoVertexShader = `
    precision highp float;
    // Attributes
    attribute vec3 position;
    attribute vec2 uv;
    // Uniforms
    uniform mat4 worldViewProjection;
    uniform float T;
    // Varying
    varying vec2 vUv;
    void main(void) {
        gl_Position = worldViewProjection * vec4(position, 1.0);
        vUv = uv;
    }
`;
    BABYLON.Effect.ShadersStore.MotyoFragmentShader = `
    precision highp float;
    uniform float T;
    varying vec2 vUv;
    void main() {
        vec3 color = vec3(.686,.39,.9);
        float  dist = distance(vUv,vec2(0.5,0.5));
        gl_FragColor = vec4(color, 1.0 );
        if(dist > .27 + .1 * T){
          gl_FragColor = vec4(1.,1.,1.,1.);
        }
        if(dist > .3 + .2 * T){
          discard;
        }
    }
  `;
    var mShader = new BABYLON.ShaderMaterial("shaderGradient", scene, "Motyo", {
        attributes: ["uv2", "uv", "color", "position", "normal"],
        defines: ["#define INSTANCES"],
        uniforms: ["T", "world", "worldViewProjection"]
        });
      var time = 0;
      scene.registerBeforeRender(function() {
          time += 1 / engine.getFps();
            mShader.setFloat("T", (Math.sin(time)+1)/2);
          })
        mesh.material = mShader;
      }
    return tb
  }
function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
  function massTo(GF,mass){
    return new Promise((resolve, reject)=>{
      GF.pins.forEach((item,j)=>{
       item.physicsImpostor.mass = mass;
       if(j == GF.pins.length-1){
         resolve(true);
       }
      })
    })
  }

  function animateHeroHit(time){
    local.runAnim.stop();
    local.walkAnim.stop();
    local.inGame = true;
    return new  Promise(function(resolve, reject) {
      local.ballAnim.start(true, 1.0, local.ballAnim.from, local.ballAnim.to, false)
      local.idleAnim.stop();
      setTimeout(()=>{
        local.ballAnim.stop();
        local.idleAnim.start(true, 1.0, local.idleAnim.from, local.idleAnim.to, false);
        local.inGame = false;
      },1633)
      setTimeout(()=>{
        resolve(true)
      },time)
    });
  }

  GF.hit = async function(power, detH, vec, detW,GF) {
  //  await massTo(GF,.75);
    window.awaitT = 600;
    await animateHeroHit(awaitT);
    playSoundFB("kick");
    let curPow = 1.5 + 4*(power / detH);
    let curVPow = -5*(vec / detW/2)*1.5;
    //console.log(curPow,"curVPow");
    GF.ball = scene.getMeshByName('Fball');
    //let curVel = new BABYLON.Vector3(0.8119354801705532+curVPow, 0.038461719423805975,  0.5824787310959755).scale(curPow)
    let curVel = new BABYLON.Vector3(curVPow, curPow/4.5, -curPow).scale(2.7*curPow)
    GF.ball.physicsImpostor.setLinearVelocity( new BABYLON.Vector3(curVel.x,curVel.y,curVel.z));
    ///console.log(e.pickInfo.ray.direction);
    GF.ball.physicsImpostor.setAngularVelocity(new BABYLON.Quaternion(.01, .5, .02, 0));
    GF.bits--;
    GF.bitsCounter.innerHTML=GF.bits;
    setTimeout(()=>{
      let goTO = scene.onBeforeRenderObservable.add(()=>{
        if(local.Arrowshader.Rdist > 0){
          local.Arrowshader.Rdist -=.04;
          local.Arrowshader.setFloat("dist",local.Arrowshader.Rdist);
        }else{
          scene.onBeforeRenderObservable.remove(goTO);
        }
      })
    },350)
    GF.leftThCont.left = "1000%";
    setTimeout(()=>{
        if(GF.bits==0 || GF.num == 9){
          document.querySelector(".menu-football-js").classList.add("end-game")
          document.querySelector(".g-bits-fb").classList.add("final")
          document.querySelector(".g-point-fb").classList.add("final")
          playSoundFB("win")
        }else{
          if(local.canReset){
            GF.leftThCont.left = GF.Jsizes.left;
            scene.getMeshByName('Fball').dispose();
            createBowlingBall(scene,ballPosition,ballDiameter,BitPosition,GF);
          }
        }

    },1500);
  }
}
function toBackClassFB(element,elClass){
  element.classList.add(elClass);
  setTimeout(()=>{
      element.classList.remove(elClass);
  },600);
}
function createGate(){
  window.arrGate.forEach((item, i) => {
    let gateBox =  BABYLON.MeshBuilder.CreateBox(item.name, {width: item.w, height: item.h,depth: .4}, scene);
    gateBox.position.set(item.x,item.y,item.z);
    gateBox.rotation.set(item.rx,item.ry,item.rz);
    gateBox.visibility = 0;
    gateBox.physicsImpostor = new BABYLON.PhysicsImpostor(gateBox, BABYLON.PhysicsImpostor.BoxImpostor, {
      mass: 0,
      friction: 100,
      restitution: .4,
      disableBidirectionalTransformation: true,
    }, scene);
    if(i == window.arrGate.length-1){
      window.arrGate=[];
    }
  });

}
function opacitySin(GF,item,j){
  GF.pins.splice(j,1);
  let factor = Math.PI/2;
  let i=1;
  let observal = scene.onBeforeRenderObservable.add(function() {
    factor+= 3*Math.PI/120*i;
    item.visibility = Math.sin(factor);
    if(factor > Math.PI){
      i = 4;
    }
    if(factor > 3*Math.PI){
       scene.onBeforeRenderObservable.remove(observal);
       item.dispose();
    }
  })
}

function leaveGame(local){
  local.iSayStay=false;
  local.canReset=false;
  window.GF.interface.classList.remove("active");
  window.local.hero.rotation.y = local.camTarget.rotation.y;
  local.camTarget.rotation=window.local.hero.rotation;
  local.camTarget.position=window.local.hero.position;
  local.spriteGicon.renderingGroupId = 1;
  local.canMove = true;
  setTimeout(()=>{
  if(document.documentElement.clientWidth > 1024){
    document.querySelector(".point-detected").classList.remove("ingame");
  }else{
    document.querySelector(".nav-icon3").classList.remove("ingame-menu");
  }
    document.querySelector(".map").classList.remove("ingame");
    document.querySelector(".right-bar").classList.remove("ingame");
    document.querySelector(".camera-factor").classList.remove("ingame-cam");
    document.querySelector(".menu-football-js").classList.remove("end-game")
    document.querySelector(".g-bits-fb").classList.remove("final")
    document.querySelector(".g-point-fb").classList.remove("final")
  }, 250)
  local.runAnim.play(true);
  local.walkAnim.play(true);
  local.idleAnim.play(true);
  GF.leftThCont.left = "1000%";
  if(local.adt!=null){
    local.leftThCont.left = local.Jsizes.static.left;
  }
  scene.onBeforeRenderObservable.remove(GF.intersect);
  //setTimeout(()=>{
    scene.getMeshByName("boxTarget").dispose();
    scene.getMeshByName('Fball').dispose();
//  },2500)
}
function createPG(scene) {
  let PG = BABYLON.MeshBuilder.CreateBox("PF", {width: 30, height:.2,depth: 30}, scene);
  PG.material =  new BABYLON.StandardMaterial("mat",scene)
  PG.material.emissiveColor.set(1,1,1);
//  lane.position.y = laneHeight / 2; // New position due to mesh centering
  PG.position.set(115.55,0.615,-15.15);
  PG.visibility = 1;
  PG.isPickable = false;
  PG.physicsImpostor = new BABYLON.PhysicsImpostor(PG, BABYLON.PhysicsImpostor.BoxImpostor, {
    mass: 0,
    friction: 1,
    restitution: 3
  }, scene);
  return PG;
}
function GFmat(color,scene){
  let material = new BABYLON.StandardMaterial("pin",scene);
  material.emissiveTexture = new BABYLON.Texture("images/ball_texture.jpg",scene);
  return material;
}

function createBowlingBall(scene,ballPosition,ballDiameter,BitPosition) {
  // The original sphere, from which the ball will be made
  var sphere = BABYLON.MeshBuilder.CreateSphere("Fball", { diameterX: .34, diameterY: .34, diameterZ: .34 }, scene);
  sphere.material = GFmat(new BABYLON.Color3(217/255,207/255,235/255),scene)
  var ball = sphere;
  ball.position = BitPosition;
  ball.rotation.z = Math.PI / 2;
//  ball.rotation.x = Math.PI / 1.75;

  ball.physicsImpostor = new BABYLON.PhysicsImpostor(ball, BABYLON.PhysicsImpostor.SphereImpostor, {
    mass: .3,
    restitution: .15,
    friction: 1,
    disableBidirectionalTransformation: true
  }, scene);

  return ball;
}
    function addGFJoystic(scene, GF) {
      GF.Jsizes =  GF.joystickSizes;
      let xAddPos = 0;
      let yAddPos = 0;
      let adt = local.Gadt;
      GF.isCreate = true;
      GF.leftThCont = makeThumbArea("leftThumb", 2, "rgba(175,99,230,0)", null);
      GF.leftThCont.height = GF.Jsizes.height;
      GF.leftThCont.width = GF.Jsizes.width;
      GF.leftThCont.isPointerBlocker = true;
      GF.leftThCont.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
      GF.leftThCont.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
      GF.leftThCont.left = GF.Jsizes.left;
      GF.leftThCont.top = GF.Jsizes.top;
      GF.leftInThCont = makeThumbArea("leftInnterThumb", 4, "rgb(175,99,230)", null);
      GF.leftInThCont.height = "105px";
      GF.leftInThCont.width = "105px";
      GF.leftInThCont.isPointerBlocker = true;
      GF.leftInThCont.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      GF.leftInThCont.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      GF.leftPuck = makeThumbArea("GF.leftPuck", 0, "rgb(175,99,230)", "rgb(175,99,230)");
      GF.leftPuck.height = "45px";
      GF.leftPuck.width = "45px";
      GF.leftPuck.isPointerBlocker = true;
      GF.leftPuck.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      GF.leftPuck.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      GF.leftThCont.onPointerDownObservable.add(function(coordinates) {
        GF.leftPuck.floatLeft = adt._canvas.width - coordinates.x + (GF.leftThCont._currentMeasure.width * GF.Jsizes.offsetL) * -1; //GF.sideJoyOff;
        GF.leftPuck.left = GF.leftPuck.floatLeft;
        GF.leftPuck.floatTop = adt._canvas.height - coordinates.y + (GF.leftThCont._currentMeasure.height * GF.Jsizes.offsetT); //bottomJoystickOffset;
        GF.leftPuck.top = GF.leftPuck.floatTop * -1;
        GF.leftPuck.isDown = true;
      });
      GF.leftThCont.onPointerUpObservable.add(function(coordinates) {
        //console.log(yAddPos, GF.leftThCont._currentMeasure.height);
        GF.hit(yAddPos, GF.leftThCont._currentMeasure.height, xAddPos, GF.leftThCont._currentMeasure.width,GF);
        xAddPos = 0;
        yAddPos = 0;
        GF.leftPuck.isDown = false
        GF.leftPuck.alpha = 1;
        GF.leftPuck.left = 0;
        GF.leftPuck.top = 0;
      });

      GF.leftThCont.onPointerMoveObservable.add(function(coordinates) {
        if (GF.leftPuck.isDown) {
          xAddPos = (adt._canvas.width - coordinates.x - (GF.leftThCont._currentMeasure.width * GF.Jsizes.offsetL)) * -1;
          yAddPos = adt._canvas.height - coordinates.y - (GF.leftThCont._currentMeasure.height * GF.Jsizes.offsetT);
          local.Arrowshader.setFloat("dist", 2*yAddPos/GF.leftThCont._currentMeasure.height);
          local.Arrowshader.Rdist = 2*yAddPos/GF.leftThCont._currentMeasure.height;
          //console.log(yAddPos/GF.leftThCont._currentMeasure.height,"y")
          //console.log(xAddPos/GF.leftThCont._currentMeasure.width,"x")
          //local.Arrowshader.setFloat("angle",-2*xAddPos/GF.leftThCont._currentMeasure.width);
          scene.getMeshByName("ArrowParent").rotation.y = -1.53+(xAddPos/GF.leftThCont._currentMeasure.width)*2;
          GF.leftPuck.left = xAddPos;
          GF.leftPuck.top = yAddPos * -1;
          //console.log(GF.leftPuck.top,GF.leftPuck.left);
        }
      });

      adt.addControl(GF.leftThCont);
      GF.leftThCont.addControl(GF.leftInThCont);
      GF.leftThCont.addControl(GF.leftPuck);
      window.addEventListener("resize", function() {
        GF.leftThCont.height = GF.Jsizes.height;
        GF.leftThCont.width = GF.Jsizes.width;
        if(GF.leftThCont.left != "1000%"){
          GF.leftThCont.left = GF.Jsizes.left;
        }
        GF.leftThCont.top = GF.Jsizes.top;
      });

      function makeThumbArea(name, thickness, color, background, curves) {
        let rect = new BABYLON.GUI.Ellipse();
        rect.name = name;
        rect.thickness = thickness;
        rect.color = color;
        rect.background = background;
        rect.paddingLeft = "0px";
        rect.paddingRight = "0px";
        rect.paddingTop = "0px";
        rect.paddingBottom = "0px";

        return rect;
      }
    }

    function initFootball(local,scene,spriteGicon){
      let sprite = new BABYLON.Sprite("Game_Football", spriteGicon);
      sprite.position= new BABYLON.Vector3(115.55,.615,-6.15);
      sprite.isPickable = true;
      sprite.height = 1;
      sprite.width = 1;
      sprite.playAnimation(0, 0, true, 40);
      local.Games.Game_Football = startGFame;
      local.icons.Game_Football = sprite;
    }
    function playSoundFB(name){
      let soundFB = document.querySelector('.sound-'+ name)
      soundFB.currentTime = 0
      soundFB.play()
    }

    export{initFootball};
