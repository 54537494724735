<template>
     <div class="b-block b-stream js-b-stream">
          <div class="b-stream--close js-b-stream--close"
               @click="closeBlock()">
               <img class="stream-close--btn js-stream-close--btn" src="/images/b-close.png" alt="">
          </div>
          <div class="b-stream--back js-b-stream--back">
               <!-- b-stream--left -->
               <div class="b-stream--left js-b-stream--left">
                    <div class="stream-left_name js-stream-left_name">
                         <span>{{$t('interfaceStream.h1-1')}}</span>
                    </div>
                    <div class="stream-left--video js-stream-left--video">
                         <iframe width="100%" height="100%" :src="live.url"
                         frameborder="0"
                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                         allowfullscreen>
                         </iframe>
                    </div>
               </div>
               <!-- b-stream--left -->

               <!-- b-stream--right -->
               <div class="b-stream--right js-b-stream--right">
                    <div class="archive-top--bar js-archive-top--bar"></div>
                    <div class="stream-right_name js-stream-right_name">
                         <span>{{$t('interfaceStream.h1-2')}}</span>
                    </div>
                    <div class="stream-right_archive js-stream-right_archive Scrollbar-stream"
                         data-mcs-theme="inset-3-dark">
                         <div class="archive-top--bar js-archive-top--bar"></div>
                         <div v-for="e in events" :key="e.url"
                              class="archive-item archive-item-1 js-archive-item js-archive-item-1">
                              <div class="item item-1 js-item-1">
                                   <iframe width="100%" height="100%" :src="e.url"
                                   frameborder="0"
                                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                   allowfullscreen>
                                   </iframe>
                              </div>
                              <div class="item-date item-date-1 js-item-date-1">
                                   <span>{{ e.time }}</span>
                              </div>
                              <div class="item-name item-name-1 js-item-name-1">
                                   <span>{{ e.name }}</span>
                              </div>
                         </div>
                    </div>
                    <div class="archive-bottom--bar js-archive-bottom--bar"></div>
                    <!-- b-stream--right -->
               </div>
          </div>
     </div>
</template>

<script>
import store from "@/store";

export default {
     name: "ExpoStreamsEvents",
     methods: {
          closeBlock(){
               if(document.querySelector(".star-anim.anim-to") != null){
                    document.querySelector(".star-anim.anim-to").classList.add("anim-out");
                    document.querySelector(".star-anim_blue.anim-to").classList.add("anim-out");
               }
               if(document.querySelector(".right-item.active") != null){
                    document.querySelector(".right-item.active").classList.remove("active");
               }
               document.querySelector(".b-block.active").classList.remove("active");
               document.querySelector(".star-anim").classList.remove("anim-to");
               document.querySelector(".star-anim_blue").classList.remove("anim-to");
          }
     },
     computed: {
          // events: () => store.state.expoEvents,
          // live: () => store.state.expoLiveEvent,
          events: () => [
               {
                    name: 'Имя Стрима 1',
                    time: '10 апреля/Основная программа',
                    url: 'https://www.youtube.com/embed/YkfU30yj52A',
               },
               {
                    name: 'Имя Стрима 2',
                    time: '10 апреля/Основная программа',
                    url: 'https://www.youtube.com/embed/9IZA0L0KutE',
               },
               {
                    name: 'Имя Стрима 3',
                    time: '10 апреля/Основная программа',
                    url: 'https://www.youtube.com/embed/VGQBGmVvE7s',
               },
               {
                    name: 'Имя Стрима 4',
                    time: '10 апреля/Основная программа',
                    url: 'https://www.youtube.com/embed/Qrf_k5YECZU',
               },
               {
                    name: 'Имя Стрима 5',
                    time: '10 апреля/Основная программа',
                    url: 'https://www.youtube.com/embed/o2wsA5l00PE',
               },
               {
                    name: 'Имя Стрима 6',
                    time: '10 апреля/Основная программа',
                    url: 'https://www.youtube.com/embed/SqRs_ez-cDg',
               },
          ],
          live: () => {
               return {
                    name: 'Имя Стрима 7',
                    time: '10 апреля/Основная программа',
                    url: 'https://www.youtube.com/embed/sn7SbeDSRt0',
               }
          },
     },
}
</script>
