import {pinPositions} from "@/3d/pinsPos";
import {addArrow} from "@/3d/addArrow";

let startGGame = function (){
  window.soundGG ={}
  soundGG.win = document.querySelector(".sound-win")
  soundGG.soundClick = document.querySelector('.sound-click')
  let stopSound = document.querySelector(".sound-move")
  stopSound.pause()
  window.GG = {};
  GG.pins=[]
  GG.joystickSizes = {
        height: "45%",
        width: "45%",
        left: "-27.5%",
        top: "0%",
        offsetL:1.12,
        offsetT: .52,
  }
  if(local.adt!=null){
    local.leftThCont.left=100000;
  }
  local.canReset=true;
  GG.pinHeight = 0.4;
  GG.pinDiameter = 0.08;
  const ballPosition = new BABYLON.Vector3(0, .4, 0.1);
  const ballDiameter = 0.4;
  const BitPosition = new BABYLON.Vector3(-15.0, -4.8, 126.5);

  let cameraTPos = new BABYLON.Vector3( -11.5,-7.2, 129.2);
  if(local.adt != null){
     cameraTPos = new BABYLON.Vector3( -9,-7.2, 131.2);
  }
  local.camTarget.position = cameraTPos;
  let imbaUGOL = Math.floor(local.camTarget.rotation.y/(2*Math.PI));
  local.camTarget.rotation = new BABYLON.Vector3( 0.175, 3.92 + (imbaUGOL*2*Math.PI),0);
  local.canMove = false;
  local.hero.position.set(-19.373024461478778,-5.580511454656745,122.3477837234485);
  document.querySelector(".g-exit-js").addEventListener("click",()=>{
    leaveGame(local);
    soundGG.soundClick.currentTime = 0;
    soundGG.soundClick.play()
  });
  //сделать fov .65
  // Set the camera to be the main active camera
  GG.fixPos = new BABYLON.Vector3( -8.1, -5.58, 132.1);
  GG.num = 0;
  GG.bits = 25;
  GG.balls = 0;
  GG.gorodki = document.querySelector(".gorodki-js");
  document.querySelector(".right-bar").classList.add("ingame");
  document.querySelector(".camera-factor").classList.add("ingame-cam");
  document.querySelector(".map").classList.add("ingame");
  if(document.documentElement.clientWidth > 1024){
    document.querySelector(".point-detected").classList.add("ingame");
  }else{
    document.querySelector(".nav-icon3").classList.add("ingame-menu");
  }
  setTimeout(()=>{
    GG.gorodki.classList.add("active")
  }, 250);
  GG.ballsCounter = document.querySelector(".gorodki-js .g-point-js");
  GG.bitsCounter = document.querySelector(".gorodki-js .g-bits-js");
  GG.bitsCounter.innerHTML = GG.bits;
  if(scene.getMeshByName("PG") ==null){
    const lane = createPG(); //create physics ground
  }
  GG.opacitySin = function(GG,item,j){
    GG.pins.splice(j,1);
    let factor = Math.PI/2;
    let i=1;
    let observal = scene.onBeforeRenderObservable.add(function() {
      factor+= 3*Math.PI/120*i;
      item.visibility = Math.sin(factor);
      if(factor > Math.PI){
        i = 4;
      }
      if(factor > 3*Math.PI){
         scene.onBeforeRenderObservable.remove(observal);
         item.dispose();
      }
    })
  }
  GG.createPins = function () {
    // Create the main pin
     GG.pins=[];
    pinPositions[GG.num].forEach((item, i) => {
      let pin = BABYLON.MeshBuilder.CreateBox("pin", {width: GG.pinDiameter, height:GG.pinHeight,depth:GG.pinDiameter}, scene);
      GG.pins.push(pin);
      pin.material = GGmat(new BABYLON.Color3(115/255, 145/255, 244/255),scene);
      pin.position = new BABYLON.Vector3(item.pos[0],item.pos[1],item.pos[2]);
      pin.rotation = new BABYLON.Vector3(item.rot[0],item.rot[1],item.rot[2]);
      pin.physicsImpostor = new BABYLON.PhysicsImpostor(pin, BABYLON.PhysicsImpostor.BoxImpostor, {
        mass: 0,
        friction: 100,
        restitution: 0.2,
        disableBidirectionalTransformation: true
      }, scene);
      return pin;
    });
  return true
  }
  const pins = GG.createPins();//GG.pinDiameter,GG.pinHeight,
  if(scene.getMeshByName("ArrowParent") == null){
    addArrow(local,scene,new BABYLON.Vector3(-12.626868590719258,-5.5913216255173435, 128.2));
  }else{
    scene.getMeshByName("ArrowParent").position.set(-12.626868590719258,-5.5913216255173435, 128.2)
  }
  let ball = createBowlingBall(scene,ballPosition,ballDiameter,BitPosition,GG);
  if(GG.isCreate!=null){
    GG.leftThCont.left = GG.Jsizes.left;
  }else{
    addGGJoystic(GG);
  }
  function massTo(GG,mass){
    return new Promise((resolve, reject)=>{
      GG.pins.forEach((item,j)=>{
       item.physicsImpostor.mass = mass;
       if(j == GG.pins.length-1){
         resolve(true);
       }
      })
    })
  }
  function deleteBit(cur){
    let i = 0;
    while(i<cur){
      if(scene.getMeshByName('ball')!=null){
        scene.getMeshByName('ball').dispose();
      }
      i++;
    }
  }
  GG.hit = async function(power, detH, vec, detW,GG) {
    await massTo(GG,.75);
    playSoundGG("cast")
    let curPow =4+ 20 * (power / detH) * 2;
    let curVPow = (vec / detW/2)*2;
    deleteBit(3);
  //  console.log(curVPow,"curVPow",vec,detW);
    let ball = createBowlingBall(scene,ballPosition,ballDiameter,BitPosition,GG);
    //  ball.physicsImpostor.applyImpulse(new BABYLON.Vector3(0, 50, 100), new BABYLON.Vector3(ball.getAbsolutePosition().x,ball.getAbsolutePosition().y,ball.getAbsolutePosition().z+.5));
    let curVel = new BABYLON.Vector3(0.8119354801705532+curVPow, 0.038461719423805975,  0.5824787310959755).scale(curPow)
    ball.physicsImpostor.setLinearVelocity( new BABYLON.Vector3(curVel.x,curVel.y,curVel.z));
    ///console.log(e.pickInfo.ray.direction);
    ball.physicsImpostor.setAngularVelocity(new BABYLON.Quaternion(.1, 17, .2, 0));
    setTimeout(()=>{
      let goTO = scene.onBeforeRenderObservable.add(()=>{
        if(local.Arrowshader.Rdist>0){
          local.Arrowshader.Rdist -=.04;
          local.Arrowshader.setFloat("dist",local.Arrowshader.Rdist);
        }else{
          scene.onBeforeRenderObservable.remove(goTO);
        }
      })
    },350)
    GG.leftThCont.left = "1000%";
    GG.bits--;
    GG.bitsCounter.innerHTML = GG.bits;
    setTimeout(()=>{
    let i=GG.pins.length-1;
      do{
        //console.log(item.position)
           GG.pins[i].physicsImpostor.mass=0;
        if(Math.abs(GG.pins[i].position.subtract(GG.fixPos).length()) > 1.5){
            GG.balls++;
            GG.ballsCounter.innerHTML = GG.balls;
            document.querySelector(".gorodki-text--winScore").innerHTML = GG.balls;
            document.querySelector(".menu-score-gg").innerHTML = GG.balls;
            GG.opacitySin(GG,GG.pins[i],i);
        }
        if(i==0 && GG.pins.length == 0){
          setTimeout(()=>{
          //  console.log(GG.pins.length);
            GG.num++;
            GG.createPins();
          },400)
        }
        i--;
      }while(i >= 0)
        if(GG.bits==0 || GG.num == pinPositions.length-1){
          document.querySelector(".menu-end").classList.add("end-game");
          document.querySelector(".g-point-gg").classList.add("final");
          document.querySelector(".g-bits-gg").classList.add("final");
          soundGG.win.currentTime = 0;
          soundGG.win.play()
        }else{
          if(local.canReset){
            GG.leftThCont.left = GG.Jsizes.left;
          }
        }

    },2200);
  }
}
function leaveGame(local){
  local.iSayStay=false;
  local.canReset=false;
  window.GG.gorodki.classList.remove("active");
  if(document.querySelector(".g-point-gg") != null){
    document.querySelector(".g-point-gg").classList.remove("final");
    document.querySelector(".g-bits-gg").classList.remove("final");
  }
  if(document.querySelector(".menu-end.end-game")!=null){
    document.querySelector(".menu-end.end-game").classList.remove("end-game");
  }
  local.camTarget.rotation=window.local.hero.rotation;
  local.camTarget.position=window.local.hero.position;
  local.spriteGicon.renderingGroupId  = 1;
  local.canMove=true;
  setTimeout(()=>{
    if(document.documentElement.clientWidth > 1024){
      document.querySelector(".point-detected").classList.remove("ingame");
    }else{
      document.querySelector(".nav-icon3").classList.remove("ingame-menu");
    }
    document.querySelector(".map").classList.remove("ingame");
    document.querySelector(".right-bar").classList.remove("ingame");
    document.querySelector(".camera-factor").classList.remove("ingame-cam");
  }, 250)
  setTimeout(()=>{
    GG.pins.forEach((item, i) => {
      setTimeout(()=>{GG.opacitySin(GG,item,i)},20*i)
    });
  },3500)
  GG.leftThCont.left = "1000%";
  if(local.adt!=null){
    local.leftThCont.left = local.Jsizes.static.left;
  }
}
function createPG() {
  let PG = BABYLON.MeshBuilder.CreateBox("PG", {width: 30, height:.2,depth: 30}, window.scene);
  PG.material =  new BABYLON.StandardMaterial("mat",scene)
  PG.material.emissiveColor.set(1,1,1);
//  lane.position.y = laneHeight / 2; // New position due to mesh centering
  PG.position.set(-15,-5.6825,127);
  PG.visibility = 0;
  PG.isPickable = false;
  PG.physicsImpostor = new BABYLON.PhysicsImpostor(PG, BABYLON.PhysicsImpostor.BoxImpostor, {
    mass: 0,
    friction: 1,
    restitution: 1
  }, window.scene);
  let wall = BABYLON.MeshBuilder.CreateBox("PGGWall", {width: 20, height:.2,depth: 10}, window.scene);
  wall.position.set(-5.42,-5.58, 134.4);
  wall.rotation.set( 1.57,0.85,0);
  wall.physicsImpostor = new BABYLON.PhysicsImpostor(wall, BABYLON.PhysicsImpostor.BoxImpostor, {
    mass: 0,
    friction: 1000,
    restitution: .1
  }, window.scene);
  wall.visibility=0;
  wall.isPickable=false;
  return PG;
}
function GGmat(color,scene){
  let material = new BABYLON.StandardMaterial("pin",scene);
  material.emissiveColor = color;
  material.diffuseColor = new BABYLON.Color3(1, 0, 0);
  material.specularPower = 64;

  material.emissiveFresnelParameters = new BABYLON.FresnelParameters();
  material.emissiveFresnelParameters.bias = 0.2;
  material.emissiveFresnelParameters.leftColor = BABYLON.Color3.White();
  material.emissiveFresnelParameters.rightColor = color;

  material.opacityFresnelParameters = new BABYLON.FresnelParameters();
  material.opacityFresnelParameters.power = 4;
  material.opacityFresnelParameters.leftColor = BABYLON.Color3.White();
  material.opacityFresnelParameters.rightColor = BABYLON.Color3.Black();
  return material;
}


    function createBowlingBall(scene,ballPosition,ballDiameter,BitPosition) {
      // The original sphere, from which the ball will be made
      var sphere = BABYLON.MeshBuilder.CreateCylinder("ball", {
        height: 1.8,
        diameter: .1
      }, scene);
      sphere.material = GGmat(new BABYLON.Color3(217/255,207/255,235/255),scene)
      var ball = sphere;
      ball.position = BitPosition;
      ball.rotation.z = Math.PI / 2;
    //  ball.rotation.x = Math.PI / 1.75;

      ball.physicsImpostor = new BABYLON.PhysicsImpostor(ball, BABYLON.PhysicsImpostor.BoxImpostor, {
        mass: 80,
        restitution: .2,
        friction: 1,
        disableBidirectionalTransformation: true
      }, scene);

      return ball;
    }
    function addGGJoystic(GG) {
      GG.Jsizes =  GG.joystickSizes;
      let xAddPos = 0;
      let yAddPos = 0;
      console.log(BABYLON.GUI,"scene")
      console.log(BABYLON.GUI.AdvancedDynamicTexture,"scene")
      console.log(scene,"scene")
      let adt = local.Gadt;
      GG.isCreate =true;
      GG.leftThCont = makeThumbArea("leftThumb", 2, "rgba(175,99,230,0)", null);
      GG.leftThCont.height = GG.Jsizes.height;
      GG.leftThCont.width = GG.Jsizes.width;
      GG.leftThCont.isPointerBlocker = true;
      GG.leftThCont.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
      GG.leftThCont.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
      GG.leftThCont.left = GG.Jsizes.left;
      GG.leftThCont.top = GG.Jsizes.top;
      GG.leftInThCont = makeThumbArea("leftInnterThumb", 4, "rgb(175,99,230)", null);
      GG.leftInThCont.height = "55px";
      GG.leftInThCont.width = "55px";
      GG.leftInThCont.isPointerBlocker = true;
      GG.leftInThCont.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      GG.leftInThCont.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      GG.leftPuck = makeThumbArea("GG.leftPuck", 0, "rgb(175,99,230)", "rgb(175,99,230)");
      GG.leftPuck.height = "45px";
      GG.leftPuck.width = "45px";
      GG.leftPuck.isPointerBlocker = true;
      GG.leftPuck.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
      GG.leftPuck.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
      GG.leftThCont.onPointerDownObservable.add(function(coordinates) {
        GG.leftPuck.floatLeft = adt._canvas.width - coordinates.x + (GG.leftThCont._currentMeasure.width * GG.Jsizes.offsetL) * -1; //GG.sideJoyOff;
        GG.leftPuck.left = GG.leftPuck.floatLeft;
        GG.leftPuck.floatTop = adt._canvas.height - coordinates.y + (GG.leftThCont._currentMeasure.height * GG.Jsizes.offsetT); //bottomJoystickOffset;
        GG.leftPuck.top = GG.leftPuck.floatTop * -1;
        GG.leftPuck.isDown = true;
      });
      GG.leftThCont.onPointerUpObservable.add(function(coordinates) {
        GG.hit(yAddPos, GG.leftThCont._currentMeasure.height, xAddPos, GG.leftThCont._currentMeasure.width,GG);
        xAddPos = 0;
        yAddPos = 0;
        GG.leftPuck.isDown = false
        GG.leftPuck.alpha = 1;
        GG.leftPuck.left = 0;
        GG.leftPuck.top = 0;
      });

      GG.leftThCont.onPointerMoveObservable.add(function(coordinates) {
        if (GG.leftPuck.isDown) {
          xAddPos = (adt._canvas.width - coordinates.x - (GG.leftThCont._currentMeasure.width * GG.Jsizes.offsetL)) * -1;
          yAddPos = adt._canvas.height - coordinates.y - (GG.leftThCont._currentMeasure.height * GG.Jsizes.offsetT);
          local.Arrowshader.setFloat("dist", 2*yAddPos/GG.leftThCont._currentMeasure.height);
          local.Arrowshader.Rdist = 2*yAddPos/GG.leftThCont._currentMeasure.height;
          scene.getMeshByName("ArrowParent").rotation.y = 2.518 +(xAddPos/GG.leftThCont._currentMeasure.width)
          GG.leftPuck.left = xAddPos;
          GG.leftPuck.top = yAddPos * -1;
        }
      });

      adt.addControl(GG.leftThCont);
      GG.leftThCont.addControl(GG.leftInThCont);
      GG.leftThCont.addControl(GG.leftPuck);
      window.addEventListener("resize", function() {
        GG.leftThCont.height = GG.Jsizes.height;
        GG.leftThCont.width = GG.Jsizes.width;
        if(GG.leftThCont.left != "1000%"){
          GG.leftThCont.left = GG.Jsizes.left;
        }
        GG.leftThCont.top = GG.Jsizes.top;
      });

      function makeThumbArea(name, thickness, color, background, curves) {
        let rect = new BABYLON.GUI.Ellipse();
        rect.name = name;
        rect.thickness = thickness;
        rect.color = color;
        rect.background = background;
        rect.paddingLeft = "0px";
        rect.paddingRight = "0px";
        rect.paddingTop = "0px";
        rect.paddingBottom = "0px";

        return rect;
      }
    }

    function initGorodki(local,scene,spriteGicon){
      let sprite = new BABYLON.Sprite("Game_gorodki", spriteGicon);
      sprite.position= new BABYLON.Vector3(-13.839,-3.112,127.573);
      sprite.isPickable = true;
      sprite.height = 1;
      sprite.width = 1
      sprite.playAnimation(0, 0, true, 40);
      local.Games.Game_gorodki = startGGame;
      local.icons.Game_gorodki = sprite;
    }
    function playSoundGG(name){
      let soundFB = document.querySelector('.sound-'+ name)
      soundFB.currentTime = 0
      soundFB.play()
    }
    export{initGorodki};
