let pinPositions =[[
{pos:[-8.099,-5.381,131.816],rot:[0,0.873,0]},
{pos:[-8.301,-5.381,132.057],rot:[0,0.873,0]},
{pos:[-8.2,-5.141,131.937],rot:[0,0.873,-1.571]},
{pos:[-8.359,-5.381,131.598],rot:[0,0.873,0]},
{pos:[-8.561,-5.381,131.839],rot:[0,0.873,0]},
{pos:[-8.46,-5.141,131.719],rot:[0,0.873,-1.571]},
{pos:[-8.43,-5.059,131.947],rot:[0,-0.698,-1.571]},
{pos:[-8.231,-5.06,131.71],rot:[0,-0.698,-1.571]},
{pos:[-8.323,-4.976,131.833],rot:[0,0.873,-1.571]},
{pos:[-8.389,-4.977,131.778],rot:[0,0.873,-1.571]},
{pos:[-8.258,-4.977,131.888],rot:[0,0.873,-1.571]},
{pos:[-8.35,-4.722,131.927],rot:[0,0.873,0]},
],
[
{pos:[-8.333,-5.381,132.032],rot:[0,0.873,0]},
{pos:[-8.535,-5.381,132.273],rot:[0,0.873,0]},
{pos:[-8.434,-5.141,132.153],rot:[0,0.873,-1.571]},
{pos:[-8.329,-5.381,131.609],rot:[0,0.873,0]},
{pos:[-8.532,-5.381,131.85],rot:[0,0.873,0]},
{pos:[-8.431,-5.141,131.729],rot:[0,0.873,-1.571]},
{pos:[-7.886,-5.381,131.5],rot:[0,0.873,0]},
{pos:[-8.088,-5.381,131.741],rot:[0,0.873,0]},
{pos:[-7.987,-5.141,131.62],rot:[0,0.873,-1.571]},
{pos:[-8.187,-5.058,131.64],rot:[0,-0.087,-1.571]},
],
[
{pos:[-7.927,-5.381,131.616],rot:[0,0.874,0]},
{pos:[-8.03,-5.141,131.739],rot:[0,0.874,-1.571]},
{pos:[-8.133,-5.381,131.861],rot:[0,0.874,0]},
{pos:[-8.238,-5.381,131.987],rot:[0,0.874,0]},
{pos:[-8.34,-5.141,132.11],rot:[0,0.874,-1.571]},
{pos:[-8.443,-5.381,132.233],rot:[0,0.874,0]},
{pos:[-8.076,-4.901,131.793],rot:[0,0.874,0]},
{pos:[-8.178,-4.661,131.916],rot:[0,0.874,-1.571]},
{pos:[-8.281,-4.901,132.039],rot:[0,0.874,0]},
{pos:[-7.927,-4.901,131.616],rot:[0,0.874,0]},
{pos:[-8.443,-4.901,132.233],rot:[0,0.874,0]},
{pos:[-8.178,-4.421,131.916],rot:[0,0.874,0]},
],
[
{pos:[-8.255,-5.541,131.969],rot:[0,0.873,1.571]},
{pos:[-8.503,-5.541,131.761],rot:[0,0.873,1.571]},
{pos:[-8.279,-5.459,131.748],rot:[0,2.443,1.571]},
{pos:[-8.477,-5.459,131.984],rot:[0,2.443,1.571]},
{pos:[-8.255,-5.375,131.969],rot:[0,0.873,1.571]},
{pos:[-8.503,-5.375,131.761],rot:[0,0.873,1.571]},
{pos:[-8.279,-5.293,131.748],rot:[0,2.443,1.571]},
{pos:[-8.477,-5.293,131.984],rot:[0,2.443,1.571]},
{pos:[-8.255,-5.21,131.969],rot:[0,0.873,1.571]},
{pos:[-8.503,-5.21,131.761],rot:[0,0.873,1.571]},
{pos:[-8.279,-5.127,131.748],rot:[0,2.443,1.571]},
{pos:[-8.477,-5.127,131.984],rot:[0,2.443,1.571]},
{pos:[-8.255,-5.043,131.969],rot:[0,0.873,1.571]},
{pos:[-8.503,-5.043,131.761],rot:[0,0.873,1.571]},
{pos:[-8.279,-4.961,131.748],rot:[0,2.443,1.571]},
{pos:[-8.477,-4.961,131.984],rot:[0,2.443,1.571]},
{pos:[-8.255,-4.878,131.969],rot:[0,0.873,1.571]},
],
[
{pos:[-7.511,-5.381,131.621],rot:[0,0.873,0]},
{pos:[-7.714,-5.381,131.862],rot:[0,0.873,0]},
{pos:[-7.613,-5.141,131.741],rot:[0,0.873,-1.571]},
{pos:[-7.772,-5.381,131.403],rot:[0,0.873,0]},
{pos:[-7.974,-5.381,131.644],rot:[0,0.873,0]},
{pos:[-7.873,-5.141,131.523],rot:[0,0.873,-1.571]},
{pos:[-8.281,-5.381,131.635],rot:[0,0.873,0]},
{pos:[-8.483,-5.381,131.876],rot:[0,0.873,0]},
{pos:[-8.382,-5.141,131.756],rot:[0,0.873,-1.571]},
{pos:[-8.541,-5.381,131.417],rot:[0,0.873,0]},
{pos:[-8.743,-5.381,131.658],rot:[0,0.873,0]},
{pos:[-8.642,-5.141,131.538],rot:[0,0.873,-1.571]},
{pos:[-8.174,-5.381,132.393],rot:[0,0.873,0]},
{pos:[-8.376,-5.381,132.633],rot:[0,0.873,0]},
{pos:[-8.275,-5.141,132.513],rot:[0,0.873,-1.571]},
{pos:[-8.434,-5.381,132.174],rot:[0,0.873,0]},
{pos:[-8.636,-5.381,132.415],rot:[0,0.873,0]},
],
[
{pos:[-8.239,-5.381,131.305],rot:[0,-1.484,0]},
{pos:[-8.266,-5.381,130.991],rot:[0,-1.484,0]},
{pos:[-8.252,-5.141,131.148],rot:[0,-1.484,-1.571]},
{pos:[-7.941,-5.381,131.607],rot:[0,-1.484,0]},
{pos:[-7.969,-5.381,131.293],rot:[0,-1.484,0]},
{pos:[-7.955,-5.141,131.45],rot:[0,-1.484,-1.571]},
{pos:[-8.178,-5.381,131.997],rot:[0,-1.484,0]},
{pos:[-8.206,-5.381,131.684],rot:[0,-1.484,0]},
{pos:[-8.192,-5.141,131.84],rot:[0,-1.484,-1.571]},
{pos:[-8.064,-5.058,131.685],rot:[0,-2.443,-1.571]},
{pos:[-8.099,-5.058,131.28],rot:[0,2.618,-1.571]},
{pos:[-8.605,-5.381,132.088],rot:[0,3.054,0]},
{pos:[-8.291,-5.381,132.115],rot:[0,3.054,0]},
{pos:[-8.448,-5.141,132.102],rot:[0,3.054,-1.571]},
{pos:[-8.954,-5.381,132.328],rot:[0,3.054,0]},
{pos:[-8.64,-5.381,132.356],rot:[0,3.054,0]},
{pos:[-8.797,-5.141,132.342],rot:[0,3.054,-1.571]},
{pos:[-9.297,-5.381,132.027],rot:[0,3.054,0]},
{pos:[-8.984,-5.381,132.055],rot:[0,3.054,0]},
{pos:[-9.14,-5.141,132.041],rot:[0,3.054,-1.571]},
{pos:[-9.01,-5.058,132.194],rot:[0,2.094,-1.571]},
],
[
{pos:[-8.724,-5.301,132.433],rot:[0,-0.74,-3.142]},
{pos:[-8.492,-5.301,132.646],rot:[0,-0.74,-3.142]},
{pos:[-8.608,-5.541,132.54],rot:[0,-0.74,1.571]},
{pos:[-8.495,-5.301,132.183],rot:[0,-0.74,-3.142]},
{pos:[-8.263,-5.301,132.395],rot:[0,-0.74,-3.142]},
{pos:[-8.379,-5.541,132.289],rot:[0,-0.74,1.571]},
{pos:[-8.415,-5.301,132.071],rot:[0,0.831,-3.142]},
{pos:[-8.203,-5.301,131.839],rot:[0,0.831,-3.142]},
{pos:[-8.309,-5.541,131.955],rot:[0,0.831,1.571]},
{pos:[-8.666,-5.301,131.842],rot:[0,0.831,-3.142]},
{pos:[-8.454,-5.301,131.61],rot:[0,0.831,-3.142]},
{pos:[-8.56,-5.541,131.726],rot:[0,0.831,1.571]},
{pos:[-8.091,-5.301,131.736],rot:[0,-0.74,-3.142]},
{pos:[-7.859,-5.301,131.948],rot:[0,-0.74,-3.142]},
{pos:[-7.975,-5.541,131.842],rot:[0,-0.74,1.571]},
{pos:[-7.862,-5.301,131.485],rot:[0,-0.74,-3.142]},
{pos:[-7.63,-5.301,131.697],rot:[0,-0.74,-3.142]},
],
[
{pos:[-7.963,-5.381,131.95],rot:[0,0.873,0]},
{pos:[-8.165,-5.381,132.191],rot:[0,0.873,0]},
{pos:[-8.064,-5.141,132.071],rot:[0,0.873,-1.571]},
{pos:[-8.223,-5.381,131.732],rot:[0,0.873,0]},
{pos:[-8.425,-5.381,131.973],rot:[0,0.873,0]},
{pos:[-8.324,-5.141,131.852],rot:[0,0.873,-1.571]},
{pos:[-8.295,-5.059,132.081],rot:[0,-0.698,-1.571]},
{pos:[-8.095,-5.06,131.844],rot:[0,-0.698,-1.571]},
{pos:[-8.285,-5.541,132.472],rot:[0,0.087,1.571]},
{pos:[-7.693,-5.541,131.77],rot:[0,1.571,1.571]},
{pos:[-8.095,-5.541,131.433],rot:[0,-3.054,1.571]},
],
[
{pos:[-7.468,-5.381,131.262],rot:[0,1.658,0]},
{pos:[-7.452,-5.381,131.577],rot:[0,1.658,0]},
{pos:[-7.806,-5.381,131.292],rot:[0,1.658,0]},
{pos:[-7.771,-5.381,131.605],rot:[0,1.658,0]},
{pos:[-7.875,-5.381,131.959],rot:[0,0.873,0]},
{pos:[-8.077,-5.381,132.2],rot:[0,0.873,0]},
{pos:[-8.135,-5.381,131.741],rot:[0,0.873,0]},
{pos:[-8.337,-5.381,131.982],rot:[0,0.873,0]},
{pos:[-8.342,-5.381,132.304],rot:[0,1.658,0]},
{pos:[-8.315,-5.381,132.618],rot:[0,1.658,0]},
{pos:[-8.681,-5.381,132.334],rot:[0,1.658,0]},
{pos:[-8.653,-5.381,132.647],rot:[0,1.658,0]},
{pos:[-7.978,-5.139,132.082],rot:[0,0.873,1.571]},
{pos:[-7.611,-5.139,131.591],rot:[0,0.087,1.571]},
{pos:[-8.329,-5.139,132.458],rot:[0,-1.484,1.571]},
{pos:[-7.611,-4.899,131.591],rot:[0,1.658,0]},
{pos:[-8.329,-4.899,132.458],rot:[0,1.658,0]},
]
];
export {pinPositions};
