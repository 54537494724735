let startGSame = function (pos){
window.GS={}
GS.road = [[61.45, 0.78, 4.37],[61.45, 0.78, 4.37],[70.75, 0.78, 0.92], [79.21, 0.77, -4.26], [85.69, 0.89, -11.71], [89.76, 0.88, -20.75], [92.21, 0.88, -30.36], [93.41, 0.85, -40.22], [93.5, 0.77, -50.14], [90.18, 0.78, -59.17], [88.91, 0.78, -68.77], [83.2, 0.78, -76.26], [75.73, 0.78, -82.52], [66.08, 0.78, -84.53], [57.66, 0.78, -89.33], [53.3, 0.78, -98.2], [49.6, 0.78, -107.4], [44.2, 0.79, -115.71], [36.78, 0.78, -122.29], [28.33, 0.78, -127.48], [19.1, 0.78, -131.1], [9.32, 0.78, -132.72], [-0.59, 0.78, -132.37], [-10.38, 0.78, -130.74], [-20.02, 0.78, -128.35], [-29.49, 0.78, -125.35], [-38.74, 0.78, -121.74], [-47.68, 0.78, -117.42], [-56.2, 0.78, -112.33], [-64.19, 0.78, -106.43], [-71.57, 0.78, -99.79], [-77.2, 0.78, -91.66], [-77.2, 0.78, -91.66]];
if(pos.x == -77.2){
  GS.road = GS.road.reverse()
}
if(local.adt!=null){
  local.leftThCont.left=100000;
}
local.canMove = false;
local.hero.position.set(GS.road[0][0],GS.road[0][1],GS.road[0][2]);
let imbaUGOL = Math.floor(local.hero.rotation.y/(2*Math.PI));
local.hero.rotation.y= 4.9 + imbaUGOL*2*Math.PI;
local.inGame = true;

local.runAnim.stop();
local.walkAnim.stop();
local.idleAnim.stop();

local.heroGeometry.position.y +=.5;
GS.boost=0;
window.vexpo.inSamokat = true;

GS.Scooter= scene.getMeshByName("Scooter");
GS.Scooter.setParent(null);
GS.Scooter.rotationQuaternion = null;
GS.Scooter.position = local.hero.position;
GS.Scooter.scaling.set(.1,.1,.1);
GS.Scooter.rotation.copyFrom(local.hero.rotation);
GS.Scooter.rotation.y =local.hero.rotation.y+Math.PI;
GS.Scooter.visibility = 1;
let watch = document.querySelector(".js-samokat .js-time")
document.querySelector(".map").classList.add("ingame");
document.querySelector(".right-bar").classList.add("ingame");
document.querySelector(".camera-factor").classList.add("ingame-cam");

function opacitySin(item,to){
  let factor = Math.PI/2;
  let i=1;
  let observal = scene.onBeforeRenderObservable.add(function() {
    factor-= 2*Math.PI/120*i;
    item.visibility = Math.sin(factor);
    if(factor > Math.PI){
      i = 4;
    }
    if(factor < 3*Math.PI/2){
       scene.onBeforeRenderObservable.remove(observal);
       item.dispose();
    }
  })
}

let time = 3;
local.rideAnim = scene.animationGroups.find(a => a.name === "Scooter");
local.rideAnim.play();
let timeInt = setInterval(()=>{
  time--;
  document.querySelector('.s-timer--start').innerHTML = time
  if(time == 0){
    document.querySelector('.s-timer--start').classList.remove('start-s')
    document.querySelector('.s-end-js').classList.add('start-s')
    document.querySelector(".s-panel--js").classList.add("active");
    clearInterval(timeInt);
    start(GS.road);
  }
},1000);
function start(road){
  let timeInt = setInterval(()=>{
    time++;
    document.querySelector(".s-end-js .time").innerHTML = time;
  },1000);
  let curPoint = 1;
  let canEnd = true;
  GS.samokatGO = scene.onBeforeRenderObservable.add(()=>{
    if (local.fps > 15) {
      let curPos = local.hero.position;
      let endPos = new BABYLON.Vector3(GS.road[curPoint][0],GS.road[curPoint][1],GS.road[curPoint][2]);
      let dirPos = curPos.subtract(endPos).normalize();
      let forward = local.hero.forward;
      let rotDir = BABYLON.Vector3.Cross(forward, dirPos).y < 0 ? 1 : -1;
      if (curPos.subtract(endPos).length() < 1.5 && curPoint < GS.road.length-1) {
          curPoint++;
      }
      if(curPoint == GS.road.length-1){
        clearInterval(timeInt);
        GS.Scooter.visibility-=2/local.fps;
        if(canEnd){
          canEnd = false;
          setTimeout(()=>{
            endGame();
          },500)
        }
      }
      if(GS.boost>0){
        GS.boost-= .3/local.fps
      }
      let speed = local.WSpeed+GS.boost/local.fps;
      if(local.WSpeed+GS.boost/local.fps > .3){
        speed=.3
      }
      local.hero.position.addInPlace(local.hero.forward.scaleInPlace(speed));
      let deltaR = Math.abs(BABYLON.Vector3.Cross(forward, dirPos).y)
      if (deltaR > local.rotSpeed || deltaR==0) {
         local.hero.rotation.y += .02 * rotDir;
      }
      GS.Scooter.rotation.y = local.hero.rotation.y + Math.PI
    }
  })
function endGame(){
  scene.onBeforeRenderObservable.remove(GS.samokatGO);
  local.runAnim.play(true);
  local.runAnim.setWeightForAllAnimatables(0);
  local.walkAnim.play(true);
  local.walkAnim.setWeightForAllAnimatables(0);
  local.idleAnim.play(true);
  local.idleAnim.setWeightForAllAnimatables(1);
  local.rideAnim.stop();
  GS.Scooter.visibility=0;
  local.spriteGicon.renderingGroupId=1;
  if(local.adt!=null){
    local.leftThCont.left = local.Jsizes.static.left;
  }
  document.querySelector(".s-panel--js").classList.remove("active");
  document.querySelector('.s-end-js').classList.remove('start-s')
  document.querySelector('.s-aler--end').classList.add('end-som')
  document.querySelector(".s-end-js .time").innerHTML = time;
  if(time >= 60 ){
    document.querySelector(".s-time-end").innerHTML = Math.floor(time / 60) + ':' + time % 60
  }else{
    document.querySelector(".s-time-end").innerHTML = time
  }
  setTimeout(()=>{
    document.querySelector(".s-end-js").classList.remove("active");
    document.querySelector(".samokat").classList.remove("active");
    document.querySelector(".map").classList.remove("ingame");
    document.querySelector(".right-bar").classList.remove("ingame");
    document.querySelector('.s-aler--end').classList.remove('end-som')
    document.querySelector(".camera-factor").classList.remove("ingame-cam");
    setTimeout(()=>{window.vexpo.inSamokat = false;},600);
    local.inGame = false;
    local.canMove=true;
  },2000);
}

}
//GS.samokat = document.querySelector(".gorodki-js");
//GS.samokat.classList.add("active");
}
function initSamokat(local,scene,spriteGicon,pos){
  let sprite = new BABYLON.Sprite("Game_samokat", spriteGicon);
  sprite.position = pos;
  sprite.isPickable = true;
  sprite.height = 1;
  sprite.width = 1
  sprite.playAnimation(0, 0, true, 40);
  local.Games.Game_samokat = startGSame;
  local.icons.Game_samokat = sprite;
}


export {initSamokat};
