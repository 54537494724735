<template>
  <div class="game-int gorodki-js">
    <div class="g-point g-point-gg">
      <div class="g-point--back game-block">
        <span>{{$t('game.score')}}</span>
        <div class="g-point--counter g-counter--ingame">
          <span class="g-point-js">0</span>
        </div>
      </div>
    </div>
    <div class="g-bits g-bits-gg">
      <div class="g-bits--back game-block">
        <span>{{$t('game.bits')}}</span>
        <div class="g-bits--counter g-counter--ingame">
          <span class="g-bits-js">0</span>
        </div>
      </div>
    </div>
    <div class="g-exit g-exit-js">
      <div class="g-exit--btn">
        <span></span>
      </div>
      <span>{{$t('game.btnExit')}}</span>
    </div>
    <div class="end-game--GG">
      <div class="menu-end">
        <span class="menu-end--text">
          {{$t('game.text-win-game-1')}}
          <br>
          {{$t('game.text-win-game-2')}}
          <br class ="br-in--gorod">
          <span class="gorodki-text--winScore">100</span>
          {{$t('game.text-win-gorod')}}
        </span>
        <div class="menu-score--game">
            <img src="/images/RubbishGame/ok.png" alt="">
            <span class="menu-score--counter menu-score-gg">0</span>
        </div>
        <div @click="playAgainGorod()" class="play-again--game play-again--GG">
          <img src="/images/RubbishGame/reload.png" alt="">
          <span>{{$t('game.play-again')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  mounted() {
    window.GS = {}
    GS.soundHover = document.querySelector('.sound-hover')
    GS.soundClick = document.querySelector('.sound-click')
    this.hoverGG()
  },
  methods: {
    hoverGG(){
      document.querySelector(".g-exit-js").addEventListener('mouseenter', ()=>{
        GS.soundHover.currentTime = 0;
        GS.soundHover.play()
      });
      document.querySelector(".play-again--GG").addEventListener('mouseenter', ()=>{
        GS.soundHover.currentTime = 0;
        GS.soundHover.play()
      });
    },
    playAgainGorod(){
      GS.soundClick.currentTime = 0;
      GS.soundClick.play()
      window.GG.pins.forEach((item, i) => {
      item.dispose();
      console.log(i)
      if( i == window.GG.pins.length - 1 ){
          console.log("tut")
          window.GG.pins = [];
          window.GG.createPins();
          window.GG.leftThCont.left = window.GG.Jsizes.left;

      }
      });
      GG.bits = 20;
      GG.balls = 0;
      GG.num = 0;
      document.querySelector(".menu-end.end-game").classList.remove("end-game");
      document.querySelector(".g-point-gg").classList.remove("final");
      document.querySelector(".g-bits-gg").classList.remove("final");
      document.querySelector(".gorodki-js .g-point-js").innerHTML = 0;
      document.querySelector(".gorodki-js .g-bits-js").innerHTML = 20;
    }
  }
}
</script>
<style>
  .br-in--gorod{
    display: none;
  }
  .br-in--football{
    display: none;
  }
.menu-end{
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 35%;
  width: 50%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  border-radius: 40px;
  transform: scale(0);
  z-index: -10;
  transition: all 250ms ease;
}
.menu-end.end-game{
  transform: scale(1);
  z-index: 10;
  transition: all 250ms ease;
}
.menu-score--game{
  width: 100%;
  height: 76px;
  background: #A6D160;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 12px;
  max-width: 320px;
}
.menu-score--counter{
  font-family: "Gerbera-Bold";
  font-size: 34px;
  line-height: 40px;
  color: #ffffff;
}
.play-again--game{
  width: 100%;
  height: 76px;
  background: #AF63E6;
  border-radius: 44px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  max-width: 320px;
  margin-bottom: 40px;
}
.play-again--game span{
  font-style: normal;
  font-family: "Gerbera-Bold";
  font-size: 45px;
  line-height: 58px;
  color: #FFFFFF;
}
.menu-end--text{
  font-size: 32px;
  line-height: 41px;
  font-family: "Gerbera-Bold";
  color: #162B75;
  padding: 24px 0px 24px 0px;
  text-align: center;
}
.g-point,.g-bits,.g-exit{
  z-index: -1;
  transform: scale(0);
  transition: All 350ms ease;
  position: absolute;
}
.active .g-point,.active .g-bits,.active .g-exit{
  transform: scale(1);
  z-index: 100;
}
.g-bits.final{
  transform: scale(0);
  transition: All 350ms ease;
}
.g-point.final{
  transform: scale(0);
  transition: All 350ms ease;
}
.g-point{
  top: 20%;
  left: 5%;
}
.g-bits{
  top: calc(20% + 75px);
  left: 5%;
}
.game-block{
  width: 270px;
  height: 60px;
  border-radius: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.game-block span{
  font-family: "Gerbera-Bold";
  font-style: normal;
  font-size: 32px;
  color: #fff;
  padding-left: 27px;
}
.g-point--back{
  background-color: #A6D160;
}
.g-bits--back{
  background-color: #AF63E6;
}
.g-counter--ingame{
  width: 100px;
  background: #fff;
  position: absolute;
  right: -3px;
  height: 100%;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-counter--ingame span{
  font-family: "Gerbera-Bold";
  font-style: normal;
  font-size: 34px;
  color: #232176;
  padding-left: 0px;
}
.g-exit{
  top: 4%;
  right: 4%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.g-exit span{
  font-family: "Gerbera-Bold";
  font-style: normal;
  font-size: 18px;
  color: #fff;
}
.g-exit--btn{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.g-exit--btn::after, .g-exit--btn::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform .4s ease-in-out;
  content: "";
}
.g-exit--btn::before {
  background: #af63e6;
  transform: scale(1);
}
.g-exit--btn::after {
  background: #162B75;
  transform: scale(0);
}
.g-exit--btn:hover::before, .g-exit--btn:hover .g-exit--btn::before {
  transform: scale(0);
}
.g-exit--btn:hover::after, .g-exit--btn:hover .g-exit--btn::after {
  transform: scale(1);
}
.g-exit--btn span{
  position: absolute;
  transform: rotate(45deg);
  width: 35px;
  height: 3px;
  background-color: #ffffff;
  z-index: 10;
}
.g-exit--btn span::after {
  content: "";
  position: absolute;
  transform: rotate(90deg);
  width: 35px;
  height: 3px;
  background-color: #ffffff;
}
@media (max-width: 1024px) {
  .g-exit--btn {
    width: 60px;
    height: 60px;
  }
  .g-exit span {
    font-size: 16px;
  }
  .g-exit--btn span {
    width: 30px;
  }
  .g-exit--btn span::after {
    width: 30px;
  }
}
@media (max-width: 990px) {
  .menu-end {
    max-width: 425px;
    width: 100%;
  }
  .menu-end--text {
    font-size: 24px;
  }
  .menu-score--game {
    height: 60px;
    max-width: 230px;
  }
  .play-again--game {
    height: 60px;
    max-width: 230px;
  }
  .play-again--game img{
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .play-again--game span {
    font-size: 30px;
  }
  .game-block {
    width: 220px;
  }
  .game-block span {
    font-size: 28px;
    padding-left: 20px;
  }
  .g-counter--ingame {
    width: 90px;
  }
  .g-counter--ingame span {
    font-size: 26px;
    padding-left: 0px;
  }
}
@media (max-width: 570px) {
  .br-in--gorod{
    display: block;
  }
  .menu-end {
    top: 30%;
    max-width: 90%;
  }
  .g-exit--btn {
    width: 50px;
    height: 50px;
  }
  .g-exit--btn span {
    width: 25px;
  }
  .g-exit--btn span::after {
    width: 25px;
  }
  .g-point {
    top: 15%;
  }
  .g-bits {
    top: 15%;
    left: inherit;
    right: 5%;
  }
  .g-exit span {
    display: none;
  }
  .g-exit--btn span{
    display: block;
  }
}
@media (max-width: 500px){
  .game-block {
    width: 160px;
    height: 50px;
  }
  .game-block span {
    font-size: 18px;
  }
  .g-counter--ingame {
    width: 70px;
  }
  .g-counter--ingame span {
    padding-left: 0px;
    font-size: 20px;
  }
}
@media (max-width:450px) {
  .br-in--gorod{
    display: block;
  }
  .br-in--football{
    display: block;
  }
}
@media (max-width: 425px){
  .menu-end {
    top: 27%;
    max-width: 85%;
  }
  .menu-end--text {
    font-size: 20px;
  }
  .menu-score--game {
    height: 55px;
    max-width: 200px;
  }
  .play-again--game {
    height: 55px;
    max-width: 200px;
  }
  .play-again--game span {
    font-size: 24px;
  }
  .menu-score--counter {
    font-size: 28px;
  }
  .play-again--game img {
    width: 35px;
    height: 35px;
  }
  .menu-score--game img{
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}

@media (max-width: 360px) {
  .menu-end {
    max-width: 95%;
  }
  .g-bits {
    top: 15%;
    right: 3%;
  }
  .g-point {
    top: 15%;
    left: 3%;
  }
  .game-block {
    width: 140px;
  }
  .g-counter--ingame {
    width: 60px;
  }
  .game-block span {
    padding-left: 10px;
  }
  .g-point--counter .g-point-js, .g-bits--counter .g-bits-js{
    padding-left: 0px;
  }

}
@media (max-height: 675px) and (min-width: 500px){
  .menu-end {
    top: 20%;
    max-width: 360px;
  }
  .menu-end--text {
    font-size: 20px;
  }
  .menu-score--game {
    height: 50px;
    max-width: 180px;
  }
  .play-again--game {
    height: 50px;
    max-width: 180px;
  }
  .menu-score--counter {
    font-size: 26px;
  }
  .play-again--game img{
    width: 30px;
    height: 30px;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .menu-score--game img{
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  .play-again--game span {
    font-size: 26px;
  }

  .g-exit--btn {
    width: 45px;
    height: 45px;
  }
  .g-exit--btn span::after {
    width: 25px;
  }
  .g-exit {
    top: 4%;
    right: 5%;
  }
  .g-point {
    top: 25%;
    left: 5%;
  }
  .g-bits {
    top: calc(25% + 60px);
    left: 5%;
  }
  .game-block {
    width: 160px;
    height: 50px;
  }
  .game-block span {
    font-size: 18px;
  }
  .g-counter--ingame {
    width: 70px;
  }
  .g-counter--ingame span {
    padding-left: 0px;
    font-size: 20px;
  }
  .g-exit span {
    display: none;
  }
  .g-exit--btn span {
    width: 25px;
    display: block;
  }
}
.play-again--game{
  transform: scale(1);
  transition: all 150ms ease;
}
@media (hover: hover){
  .play-again--game:hover{
    transform: scale(1.1);
    transition: all 150ms ease;
  }
}
</style>
