<template>
    <div class="master-block">
        <div class="master-class master-class-1">
            <div @click="closeMaster()" class="master-class--exit">
                <span class="master-class--ex"></span>
            </div>
            <span class="master-head">{{$t('master.h1')}}</span>
            <span class="master-name">{{$t(rewards[msKlass].master)}}</span>
            <span class="master-disc">{{$t(rewards[msKlass].masterDisc)}}</span>
            <div class="master-link">
                <span>{{$t('master.master-btn')}}</span>
                <a class="master-link-btn" :href="rewards[msKlass][$i18n.locale]" target="_blank">
                    <img src="images/interface/arrow.svg" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ExpoMasterClass",
    mounted() {
        window.soundMaster = {};
        soundMaster.soundClick = document.querySelector('.sound-click')
    },
    methods: {
        closeMaster(){
            document.querySelector(".master-block").classList.add("close-anim");
            soundMaster.soundClick.currentTime = 0;
            soundMaster.soundClick.play()
            setTimeout(()=>{
                window.vexpo.msKlassON = false;
                document.querySelector(".master-block").classList.remove("anim-master-go");
                document.querySelector(".master-block").classList.remove("close-anim");
            },270)
        }
    },
    computed: {
        },
    data(){
        return {
            msKlass:window.msKlass,
            rewards:  {
              	MC_vladkim:{
                    ru:"https://mrexpo.ru/master-classes/vlad-kim",
                    en:"https://mrexpo.ru/en/master-classes/vlad-kim",
                    master:"master.master-1",
                    masterDisc:"master.master-disc-1"
                },
	            MC_urijpostrigaj:{
                    ru:"https://mrexpo.ru/master-classes/urij-postrigaj",
                    en:"https://mrexpo.ru/en/master-classes/urij-postrigaj",
                    master:"master.master-2",
                    masterDisc:"master.master-disc-2"
                },
	            MC_kristinavolodina:{
                    ru:"https://mrexpo.ru/master-classes/kristina-volodina",
                    en:"https://mrexpo.ru/en/master-classes/kristina-volodina",
                    master:"master.master-3",
                    masterDisc:"master.master-disc-3"
                },
	            MC_mihailkitaev:{
                    ru:"https://mrexpo.ru/master-classes/mihail-kitaev",
                    en:"https://mrexpo.ru/en/master-classes/mihail-kitaev",
                    master:"master.master-4",
                    masterDisc:"master.master-disc-4"
                },
	            MC_vladislavlitvinenko:{
                    ru:"https://mrexpo.ru/master-classes/vladislav-litvinenko",
                    en:"https://mrexpo.ru/en/master-classes/vladislav-litvinenko",
                    master:"master.master-5",
                    masterDisc:"master.master-disc-5"
                },
	            MC_anastasiazaharova:{
                    ru:"https://mrexpo.ru/master-classes/anastasia-zaharova",
                    en:"https://mrexpo.ru/en/master-classes/anastasia-zaharova",
                    master:"master.master-6",
                    masterDisc:"master.master-disc-6"
                },
	            MC_irinasluckaa:{
                    ru:"https://mrexpo.ru/master-classes/irina-sluckaa",
                    en:"https://mrexpo.ru/en/master-classes/irina-sluckaa",
                    master:"master.master-7",
                    masterDisc:"master.master-disc-7"
                },
	            MC_kristinaefimova:{
                    ru:"https://mrexpo.ru/master-classes/kristina-efimova",
                    en:"https://mrexpo.ru/en/master-classes/kristina-efimova",
                    master:"master.master-8",
                    masterDisc:"master.master-disc-8"
                },
	            MC_anaburlakova:{
                    ru:"https://mrexpo.ru/master-classes/ana-burlakova",
                    en:"https://mrexpo.ru/en/master-classes/ana-burlakova",
                    master:"master.master-9",
                    masterDisc:"master.master-disc-9"
                },
	            MC_alinanikulcenko:{
                    ru:"https://mrexpo.ru/master-classes/alina-nikulcenko",
                    en:"https://mrexpo.ru/en/master-classes/alina-nikulcenko",
                    master:"master.master-10",
                    masterDisc:"master.master-disc-10"
                },
	            MC_evdokiaharitonovic:{
                    ru:"https://mrexpo.ru/master-classes/evdokia-haritonovic",
                    en:"https://mrexpo.ru/en/master-classes/evdokia-haritonovic",
                    master:"master.master-11",
                    masterDisc:"master.master-disc-11"
                },
	            MC_elenanikolaeva:{
                    ru:"https://mrexpo.ru/master-classes/elena-nikolaeva",
                    en:"https://mrexpo.ru/en/master-classes/elena-nikolaeva",
                    master:"master.master-12",
                    masterDisc:"master.master-disc-12"
                },
	            MC_vaceslavzabelin:{
                    ru:"https://mrexpo.ru/master-classes/vaceslav-zabelin",
                    en:"https://mrexpo.ru/en/master-classes/vaceslav-zabelin",
                    master:"master.master-13",
                    masterDisc:"master.master-disc-13"
                },
	            MC_konstantinrodikov:{
                    ru:"https://mrexpo.ru/master-classes/konstantin-rodikov",
                    en:"https://mrexpo.ru/en/master-classes/konstantin-rodikov",
                    master:"master.master-14",
                    masterDisc:"master.master-disc-14"
                },
	            MC_katerinaiva:{
                    ru:"https://mrexpo.ru/master-classes/katerina-iva",
                    en:"https://mrexpo.ru/en/master-classes/katerina-iva",
                    master:"master.master-15",
                    masterDisc:"master.master-disc-15" 
                },
	            MC_masaallilueva:{
                    ru:"https://mrexpo.ru/master-classes/masa-allilueva",
                    en:"https://mrexpo.ru/en/master-classes/masa-allilueva",
                    master:"master.master-16",
                    masterDisc:"master.master-disc-16"
                },
	            MC_ekaterinakononova:{
                    ru:"https://mrexpo.ru/master-classes/ekaterina-kononova",
                    en:"https://mrexpo.ru/en/master-classes/ekaterina-kononova",
                    master:"master.master-17",
                    masterDisc:"master.master-disc-17"
                },
	            MC_katafirsova:{
                    ru:"https://mrexpo.ru/master-classes/kata-firsova",
                    en:"https://mrexpo.ru/en/master-classes/kata-firsova",
                    master:"master.master-18",
                    masterDisc:"master.master-disc-18"
                },
	            MC_lizanosk:{
                    ru:"https://mrexpo.ru/master-classes/liza-nosk",
                    en:"https://mrexpo.ru/en/master-classes/liza-nosk",
                    master:"master.master-19",
                    masterDisc:"master.master-disc-19"
                },
	            MC_uliannakorsunova:{
                    ru:"https://mrexpo.ru/master-classes/ulianna-korsunova",
                    en:"https://mrexpo.ru/en/master-classes/ulianna-korsunova",
                    master:"master.master-20",
                    masterDisc:"master.master-disc-20"
                },
	            MC_kristiannavinicenko:{
                    ru:"https://mrexpo.ru/master-classes/kristianna-vinicenko",
                    en:"https://mrexpo.ru/en/master-classes/kristianna-vinicenko",
                    master:"master.master-21",
                    masterDisc:"master.master-disc-21"
                },
	            MC_analetova:{
                    ru:"https://mrexpo.ru/master-classes/ana-letova",
                    en:"https://mrexpo.ru/en/master-classes/ana-letova",
                    master:"master.master-22",
                    masterDisc:"master.master-disc-22"
                },
	            MC_natalafontan:{
                    ru:"https://mrexpo.ru/master-classes/natala-fontan",
                    en:"https://mrexpo.ru/en/master-classes/natala-fontan",
                    master:"master.master-23",
                    masterDisc:"master.master-disc-23"
                },
            }
        }
    }
}
</script>

<style scoped>
.master-class--exit{
    width: 25px;
    height: 25px;
    position: absolute;
    top: 35px;
    right: 20px;
    cursor: pointer;
    transition: all 150ms ease;
}
.master-class--ex{
    top: 11px;
    position: absolute;
    transform: rotate(45deg);
    width: 25px;
    height: 2px;
    background-color: #C4C4C4;
}
.master-class--ex::after{
    content: "";
    position: absolute;
    transform: rotate(90deg);
    width: 25px;
    height: 2px;
    background-color: #C4C4C4;
}
.master-block {
    width: 600px;
    height: 350px;
    border-radius: 30px;
    position: absolute;
    top: 35%;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    cursor: initial;
    z-index: 10;
    transform: scale(0);
}
.master-block.anim-master-go{
    animation: anim-master-go 250ms ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.master-block.anim-master-go.close-anim{
    animation: anim-master-exit 250ms ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.master-class{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    border-radius: 30px;
    padding: 24px 48px;
}
.master-class--exit{
    position: absolute;
} 
.master-head{
    font-size: 28px;
    color: #162B75;
    text-transform: uppercase;
}
.master-name{
    font-family: "Gerbera-Bold";
    font-size: 32px;
    color: #162B75;
    padding: 12px 0px;
}
.master-disc{
    font-size: 18px;
    color: #7C7C7C;
    padding-bottom: 18px;
}
.master-link{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.master-link span{
    font-size: 20px;
    color: #162B75;
    text-transform: uppercase;
}
.master-link-btn img{
    width: 50%;
    height: 50%;
    object-fit: contain;
}
.master-link-btn{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #162B75;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 150ms ease;
}
.master-link-btn img{
    transform: rotate(180deg);
}

@keyframes anim-master-go{
    from { transform: scale(0); }
    to { transform: scale(1); }
}
@keyframes anim-master-exit{
    from { transform: scale(1); }
    to { transform: scale(0); }
}
@media (hover: hover){
    .master-link-btn:hover{
        transform: scale(1.2);
        transition: all 150ms ease;
    }
    .master-class--exit:hover{
        transform: scale(1.3);
        transition: all 150ms ease;
    }
}
@media (max-width: 991px) {
    .master-block{
        width: 480px;
    }
}
@media (max-width: 768px) {
    .master-block{
        width: 350px;
    }
    .master-head{
        font-size: 22px;
        color: #162B75;
    }
    .master-name{
        font-size: 28px;
    }
    .master-disc{
        font-size: 16px;
    }
    .master-link span{
        font-size: 22px;
    }
    .master-class--exit{
        top: 28px;
    }
    .master-block{
        top: 30%;
    }
}
@media (max-width: 360px){
    .master-block{
        width: 290px;
    }
}
</style>
