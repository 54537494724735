<template>
  <div class="game-int football-js">
    <div class="goal-alert">
      <span>{{$t('game.alert')}}</span>
    </div>
    <div class="g-point g-point-fb">
      <div class="g-point--back game-block">
        <span>{{$t('game.gols')}}</span>
        <div class="g-point--counter g-counter--ingame">
          <span class="g-point-js">0</span>
        </div>
      </div>
    </div>
    <div class="g-bits g-bits-fb">
      <div class="g-bits--back game-block">
        <span>{{$t('game.ball')}}</span>
        <div class="g-bits--counter g-counter--ingame">
          <span class="g-bits-js">0</span>
        </div>
      </div>
    </div>
    <div class="g-exit g-exit-js g-exit--GF">
      <div class="g-exit--btn">
        <span></span>
      </div>
      <span>{{$t('game.btnExit')}}</span>
    </div>
    <div class="end-game--FB">
      <div class="menu-end menu-football-js">
        <span class="menu-end--text">
          {{$t('game.text-win-game-1')}}
          <br>
          {{$t('game.text-win-game-2')}}
          <br class="br-in--football">
          <span class="football-text--winScore">20</span>
          {{$t('game.text-win-football')}}
        </span>
        <div class="menu-score--game">
            <img src="/images/RubbishGame/ok.png" alt="">
            <span class="menu-score--counter menu-score-fb">0</span>
        </div>
        <div @click="playAgainFootball()" class="play-again--game play-again--GF">
          <img src="/images/RubbishGame/reload.png" alt="">
          <span>{{$t('game.play-again')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.GB = {}
    GB.soundHover = document.querySelector('.sound-hover')
    GB.soundClick = document.querySelector('.sound-click')
    this.hoverFB()
  },
  methods: {
    hoverFB(){
      document.querySelector(".g-exit--GF").addEventListener('mouseenter', ()=>{
        GB.soundHover.currentTime = 0;
        GB.soundHover.play()
      });
      document.querySelector(".play-again--GF").addEventListener('mouseenter', ()=>{
        GB.soundHover.currentTime = 0;
        GB.soundHover.play()
      });
    },
    playAgainFootball(){
      GB.soundClick.currentTime = 0;
      GB.soundClick.play()
      window.GF.bits = 20;
      window.GF.balls = 0;
      window.GF.num = 0;
      document.querySelector(".menu-football-js").classList.remove("end-game")
      document.querySelector(".g-point-fb").classList.remove("final");
      document.querySelector(".g-bits-fb").classList.remove("final");
      document.querySelector(".football-js .g-point-js").innerHTML = 0;
      document.querySelector(".football-js .g-bits-js").innerHTML = 20;
    }
  }
}
</script>
<style>
.goal-alert{
  position: fixed;
  left: 0%;
  right: 0%;
  margin: 0 auto;
  top: 30%;
  width: fit-content;
  transform: scale(0);
  transition: all 150ms ease;
}
.goal-alert.alert-text{
  transform: scale(1);
  transition: all 150ms ease;
}
.goal-alert span{
  font-size: 70px;
  color: #fff;
  font-family: "Gerbera-Bold";
}
</style>
