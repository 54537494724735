<template>
    <div class="newShop b-block js-b-like--shop">
        <!-- js-b-like--shop -->
        <!-- Glass-plane -->
        <div class="newShop-glass"></div>
        <!-- /Glass plane -->
        <!-- Verification -->
        <div class="newShop-verification newShop-verification-js">
            <p class="text-verification">
                {{$t('newShop.verification')}}
                <span class="verification-name"></span>
                ?
            </p>
            <div @click="niceItem(false);clickSound()" class="verification-btn verification-no">
                <img src="/images/interface/Shop/yes.png" alt="">
            </div>
            <div @click="niceItem(true);clickSound()" class="verification-btn verification-yes">
                <img src="/images/interface/Shop/no.png" alt="">
            </div>
        </div>
        <div class="newShop-verification mail-js visible-btn active" v-if="isMail">
            <p class="text-verification">
                {{$t('newShop.mail')}}
            </p>
               <input v-model="emailAdress" type="text" placeholder="email">
            <div @click="buyItem(null,null,null);clickSound()" class="verification-btn verification-no">
                <img src="/images/interface/Shop/no.png" alt="">
            </div>
        </div>
        <!-- /Verification -->
        <!-- Nice -->
        <div class="newShop-nice newShop-nice--js">
            <p>
                {{$t('newShop.nice')}}
                <br>
                <span class="newShop-name--nice"></span>
                {{$t('newShop.niceEmail')}}
            </p>
        </div>
        <!-- /Nice -->
        <!-- Bad -->
        <div class="newShop-nice newShop-bad--js">
            <p>
                {{$t('newShop.bad')}}
                <br>
                <span class="newShop-name--nice"></span>

            </p>
        </div>
        <!-- /Bad -->
        <!-- no-money -->
        <div class="newShop-nice no-money--js">
            <p>
                {{$t(moneyOrreg)}}
                <br>
                <span class="newShop-name--nice"></span>

            </p>
        </div>
        <!-- /no-money -->
        <!-- Blur -->
        <div class="newShop-blur--top"></div>
        <div class="newShop-blur--bottom"></div>
        <!-- /Blur -->
        <!-- Close -->
        <div @click="closeBlock();clickSound()" class="newShop-close">
            <span class="art-close"></span>
        </div>
        <!-- /Close -->
        <!-- Head -->
        <div class="newShop-head row">
            <div class="col col-md-3 col-lg-4 newShop-head--counter">
                <div class="newShop-counter--back">
                    <div class="back-starNew">
                        <img src="/images/interface/Shop/Shop-star.png" alt="">
                    </div>
                    <span class="js-head-counter_number">{{ userProfile.balance }}</span>
                </div>
            </div>
            <div class="col col-md-6 col-lg-6 newShop-head--name">
                <span>{{$t('newShop.newShop-h1')}}</span>
            </div>
        </div>
        <!-- /Head -->
        <div class="newShop-main">
            <div v-for="r in rewards" :key="r.code" class="newShop-main--item row">
                <div class="newShop-item--data col col-sm-10 col-md-6 col-lg-7">
                    <div class="newShop-item--name">
                        <span>{{ r.title }}</span>
                    </div>
                    <div class="newShop-item--info">
                        <span>{{ r.short_desc}}</span>
                    </div>
                    <div class="newShop-item--btn">
                        <div class="newShop-item--price">
                            <div class="shop-img--back">
                                <img src="/images/interface/Shop/Shop-star-in.png" alt="">
                                <img class="img-star--mobile" src="/images/interface/Shop/Shop-star-mobile.png" alt="">
                            </div>
                            <span> {{ r.stars }}</span>
                        </div>
                        <div class="newShop-item--buy">
                            <button @click="buyItem(r.code, r.title,r.stars);clickSound()" class="newShop-hover">
                                <span>{{$t('newShop.buy')}}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="newShop-item--img col col-sm-10 col-md-6 col-lg-5">
                    <img :src="r.photo" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";

export default {
    name: "ExpoShopNew",
        mounted() {
            this.rewards=[];
            window.localShop = {};
            window.soundShop = {};
            fetch('https://mrexpo.ru/api/prizes/', {
                headers: {
                    Authorization: 'Bearer 5mk0pONvoHjuG2nNmWs25zUe4pUgxepUgosmoiC5J20GWltkeyKoZKYJIRAhUmCo'
            }}).then(i => i.json()).then( (i) =>{
                //let keys = Object.keys(i)
                //keys.forEach(item => {
                //    this.rewards.push(i[item][0]);
                //});
                this.rewards=i;
            });
            localShop.blurH = document.querySelector(".newShop-head").offsetHeight;
            document.querySelector(".newShop-blur--top").style.top = localShop.blurH + "px";
            window.addEventListener("resize", function() {
                localShop.blurH = document.querySelector(".newShop-head").offsetHeight;
                document.querySelector(".newShop-blur--top").style.top = localShop.blurH + "px";
            });
            localShop.vh = window.innerHeight * 0.01;
            document.querySelector(".newShop").style.setProperty('--vh', `${localShop.vh}px`);
            window.addEventListener('resize', () => {
                let vh = window.innerHeight * 0.01;
                document.querySelector(".newShop").style.setProperty('--vh', `${vh}px`);
            });
        },
    methods: {
        buyItem(index, name,stars){
          if(store.state.user.isAnonymous==true){
            this.moneyOrreg = "newShop.importantReg"
            this.openClose(document.querySelector(".no-money--js"));
            return;
          }
            if(stars-1 < store.state.userProfile.balance){
              this.curId = index !=null ? index : this.curId;
              this.cuCost = stars !=null ? stars : this.cuCost;
              this.curName = name !=null ? name : this.curName;
              this.isMail=false;
              if(!store.state.userProfile.email && this.emailAdress.length < 5){
                this.isMail=true;
                return;
              }else{
                if(!store.state.userProfile.email){
                  store.dispatch("updateUserProfile", {email: this.emailAdress});
                }
              }
              document.querySelector(".newShop-glass").classList.add('active');
              document.querySelector(".newShop-verification-js").classList.add('visible-btn');
              document.querySelector(".newShop-verification-js").classList.add('active');
              document.querySelector(".verification-name").innerHTML = this.curName;
              document.querySelector(".newShop-name--nice").innerHTML = this.curName;
            }else{
                this.openClose(document.querySelector(".no-money--js"));
            }
        },
        senBuyFetch(email){
          document.querySelector(".newShop-verification-js").classList.remove('visible-btn');
          document.querySelector(".newShop-verification-js").classList.remove('active');
          let myHeaders = new Headers();
          let successOrder = false;
          myHeaders.append("Authorization", "Bearer 5mk0pONvoHjuG2nNmWs25zUe4pUgxepUgosmoiC5J20GWltkeyKoZKYJIRAhUmCo");
          myHeaders.append("Content-Type", "application/json");
          let raw = JSON.stringify({
          "code": this.curId,
          "email": email
          });
          let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
          };

          fetch("https://mrexpo.ru/api/add-order/", requestOptions)
          .then(response => response.text())
          .then((result) =>{
            let response= JSON.parse(result);
            if(response.success==true){
              successOrder=true;
              console.log(response,response.success);
              let newB  = parseInt(store.state.userProfile.balance) - this.cuCost
              store.dispatch("updateUserProfile", {balance: newB});
              document.querySelector(".js-counter_number").innerHTML = newB;
              document.querySelector(".js-head-counter_number").innerHTML = newB;
          }})
          .then(()=>{
            document.querySelector(".newShop-glass").classList.remove('active');
            document.querySelector(".newShop-verification-js").classList.remove('visible-btn');
            document.querySelector(".newShop-verification-js").classList.remove('active');
            if(successOrder){
              this.openClose(document.querySelector(".newShop-nice--js"));
            }else{
              this.openClose(document.querySelector(".newShop-bad--js"));
            }
          })
        },
        openClose(item){
            item.classList.add("active");
            item.classList.add('visible-btn');
            setTimeout(()=>{
                item.classList.remove("active")
                item.classList.remove("visible-btn")
            },1500)
        },
        niceItem(isBuy){
            if(isBuy){
              this.senBuyFetch(store.state.userProfile.email);
            }else{
              document.querySelector(".newShop-verification-js").classList.remove('active');
              document.querySelector(".newShop-glass").classList.remove('active');
              setTimeout(()=>{
                document.querySelector(".newShop-verification-js").classList.remove('visible-btn');
              },350)
            }
        },
        closeBlock(){
            if(document.querySelector(".star-anim.anim-to") != null){
                document.querySelector(".star-anim.anim-to").classList.add("anim-out");
                document.querySelector(".star-anim_blue.anim-to").classList.add("anim-out");
            }
            if(document.querySelector(".right-item.active") != null){
                document.querySelector(".right-item.active").classList.remove("active");
            }
            if(document.querySelector(".newShop-verification-js.active") != null){
                document.querySelector(".newShop-verification-js").classList.remove('active');
                document.querySelector(".newShop-glass").classList.remove('active');
            }
            document.querySelector(".b-block.active").classList.remove("active");
            document.querySelector(".star-anim").classList.remove("anim-to");
            document.querySelector(".star-anim_blue").classList.remove("anim-to");
        },
        clickSound(){
            let soundClick = document.querySelector('.sound-click')
            soundClick.currentTime = 0
            soundClick.play()
        }
    },
    computed: {
        userProfile: () => {
            return {
                balance: 0
            }
        },
    },
    data(){
      return {
        moneyOrreg: "newShop.noMoney",
        emailAdress: "",
        isMail:false,
        curId:null,
        cuCost:null,
        rewards: null
      }
    }
}
</script>

<style scoped>
    .newShop-item--img img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-width: 258px;
        max-height: 258px;
    }
    .img-star--mobile{
        display: none;
    }
    .newShop-glass{
        width: 100%;
        height: 80%;
        position: absolute;
        bottom: 0px;
        z-index: -1;
    }
    .newShop-glass.active{
        z-index: 1;
    }
    span{
        box-sizing: border-box;
    }
    .newShop{
        align-items: baseline;
        flex-direction: column;
        width: 808px;
        box-sizing: border-box;
        padding-right: 30px;
        bottom: -300%;
    }
    .newShop-close{
        position: absolute;
        top: 30px;
        width: 40px;
        height: 40px;
        right: 40px;
        z-index: 3;
        transition: all 150ms ease;
        cursor: pointer;
    }
    .art-close{
        position: absolute;
        width: 40px;
        height: 3px;
        transform: rotate(45deg);
        background-color: #C4C4C4;
        top: 18px;
    }
    .art-close::after{
        content: "";
        position: absolute;
        width: 40px;
        height: 3px;
        transform: rotate(90deg);
        background-color: #C4C4C4;
    }
    .newShop-head{
        width: 100%;
        padding: 24px 5% 24px 5%;
        position: relative;
    }
    .newShop-counter--back{
        display: flex;
        width: 180.64px;
        height: 60px;
        background: #FFFFFF;
        border: 2px solid #DCDCDC;
        box-sizing: border-box;
        border-radius: 44px;
        align-items: center;
        justify-content: space-between;
    }
    .back-starNew{
        width: 60px;
        height: 60px;
        background: #AF63E6;
        border-radius: 50%;
        transform: translateX(-2px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .newShop-counter--back span{
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 24px;
        line-height: 31px;
        color: #232176;
        padding-right: 40px;
    }
    .newShop-main--item{
        margin-bottom: 50px;
    }
    .newShop-head--name{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .newShop-head--name span{
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 32px;
        line-height: 41px;
        display: flex;
        align-items: flex-end;
        color: #162B75;
    }
    .newShop-main{
        width: 100%;
        padding: 24px 5% 24px 5%;
        position: relative;
        overflow-y: scroll;
    }
    .newShop-main::-webkit-scrollbar {
        width: 6px;
        height: 3px;
        background-color: rgba(0, 0, 0, 0.15);
    }
    .newShop-main::-webkit-scrollbar-thumb {
        background-color: #AF63E5;
        border-radius: 4px;
    }
    .newShop-item--name span{
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 24px;
        line-height: 31px;
        color: #162B75;
    }
    .newShop-item--info{
        padding: 30px 0px;
    }
    .newShop-item--info span{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        color: #7C7C7C;
    }
    .newShop-item--price{
        width: 136px;
        display: flex;
        align-items: center;
    }
    .newShop-item--price img{
        width: 29px;
        height: 27px;
    }
    .newShop-item--price span{
        margin-left: 15%;
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 24px;
        line-height: 31px;
        display: flex;
        align-items: flex-end;
        color: #162B75;
    }
    .newShop-item--btn{
        display: flex;
    }
    .newShop-item--buy button{
        width: 136px;
        height: 40px;
        background: #AF63E6;
        border-radius: 44px;
        border: 0px;
        transition: all 150ms linear;
    }
    .newShop-item--buy button span{
        width: 68px;
        height: 28px;
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 22px;
        line-height: 28px;
        color: #FFFFFF;
    }
    .newShop-item--img{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DCDCDC;
        border-radius: 12px;
    }
    .newShop-blur--top{
        position: absolute;
        height: 24px;
        width: 100%;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
        z-index: 1;
    }
    .newShop-blur--bottom{
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0px;
        border-radius: 0px 0px 30px 30px;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
        z-index: 1;
    }
    .newShop-verification{
        position: absolute;
        top: 30%;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        width: 370px;
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 4px 64px rgb(0 0 0 / 25%);
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        transform: scale(0);
        transition: all 250ms ease;
    }
    .newShop-verification.active{
        transform: scale(1);
        transition: all 250ms ease;
    }
    .newShop-verification.visible-btn{
        z-index: 12;
    }
    .verification-btn{
        position: absolute;
        bottom: -20px;
        right: 100px;
        transition: all 150ms linear;
        border-radius: 50%;
        cursor: pointer;
    }
    .verification-no{
        right: 45px;
    }
    .text-verification{
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 18px;
        line-height: 23px;
        color: #232176;
        width: 60%;
        text-align: center;
    }
    .newShop-nice{
        position: absolute;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        top: 31%;
        width: 360px;
        height: 240px;
        background: #FFFFFF;
        box-shadow: 0px 0px 72px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: -1;
        transform: scale(0);
        transition: all 250ms ease;
    }
    .newShop-nice.active{
        transform: scale(1);
        transition: all 250ms ease;
    }
    .newShop-nice.visible-btn{
        z-index: 12;
    }
    .newShop-nice p{
        font-style: normal;
        font-family: "Gerbera-Bold";
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: #232176;
        margin: 0px;

    }

    @media (hover: hover) {
        .newShop-close:hover{
            transform: scale(1.3);
            transition: all 150ms ease;
        }
        .newShop-item--buy button:hover{
            background: #232176;
            box-shadow: 0px 2px 0 #191592, 2px 4px 6px #191dca;
            transition: all 150ms linear;
            transform: scale(1.1);
        }
        .verification-btn:hover{
            transform: scale(1.1);
            transition: all 150ms linear;
            box-shadow: 0px 4px 64px rgb(0 0 0 / 25%);
        }
    }
    @media(max-height: 880px) and (min-width: 550px){
        .newShop{
            min-height: 440px;
        }
    }
    @media (max-height: 675px) and (min-width: 500px){
        .newShop {
            width: 100vw;
        }
        .newShop-item--info{
            padding: 30px 0px;
            width: 50%;
        }
    }
    @media (max-height: 675px) and (min-width: 990px){
        .newShop-item--info {
            padding: 30px 0px;
            width: 80%;
        }
    }
    @media (max-width: 992px) {
        .newShop {
            width: 100vw;
            height: -webkit-fill-available;
            height: calc(var(--vh, 1vh) * 100);
            max-height: 100%;
            border-radius: 0px;
            z-index: 1000;
        }
        .newShop-main--item{
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }
        .newShop-item--info{
            width: 100%;
            text-align: center;
        }
        .newShop-item--img{
            margin-bottom: 30px;
        }
        .newShop-item--name{
            text-align: center;
        }
        .newShop-item--btn {
            display: flex;
            justify-content: space-between;
        }
        .newShop-head--name {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    @media (max-width: 767px) {
        .newShop-head {
            flex-direction: row-reverse;
        }
        .newShop-head--name {
            justify-content: flex-start;
        }
        .newShop-head--counter{
            display: flex;
            justify-content: center;
        }
        .newShop-close {
            top: 47px;
        }
        .newShop-head {
            padding: 36px 5% 24px 5%;
        }

    }
    @media (max-width: 575px){
        .newShop {
            padding-right: 0px;
        }
        .newShop-close{
            top: 36px;
            right: 20px;
        }
        .newShop-counter--back{
            width: 96.1px;
            height: 40px;
        }
        .back-starNew{
            width: 40px;
            height: 40px;
        }
        .back-starNew img{
            width: 20px;
            height: 19px;
        }
        .newShop-head--name span{
            font-size: 24px;
            line-height: 31px;
        }
        .newShop-counter--back span{
            font-size: 18px;
            line-height: 23px;
            padding-right: 24px;
        }
        .art-close{
            top: 20px;
            left: 5px;
            width: 30px;
        }
        .art-close[data-v-06805678]::after {
            width: 30px;
        }
        .newShop-item--name span{
            font-size: 18px;
            line-height: 23px;
        }
        .newShop-item--info span{
            font-size: 16px;
            line-height: 150%;
        }
        .newShop-item--buy button{
            width: 95.84px;
            height: 32px;
        }
        .newShop-item--buy button span {
            width: 49px;
            height: 21px;
            font-size: 16px;
            line-height: 21px;
        }
        .newShop-item--price span {
            font-size: 18px;
            line-height: 23px;
        }
        .newShop-item--price img{
            display: none;
        }
        .newShop-item--price .img-star--mobile{
            display: block;
            width: 16px;
            height: 14px;
        }
        .shop-img--back{
            width: 32px;
            height: 32px;
            background: #AF63E6;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .newShop-blur--bottom{
            border-radius: 0px 0px 0px 0px;
        }
        .newShop-verification{
            width: 328px;
            height: 146px;
            top: 45%;
        }
        .text-verification{
            font-size: 18px;
            line-height: 23px;
            width: 80%;
            margin: 0px;
        }
        .newShop-nice{
            top: 46%;
            width: 244px;
            height: 244px;
        }
        .newShop-nice p{
            font-size: 18px;
            line-height: 23px;

        }
    }
    @media (max-width: 425px){
        .newShop-item--name{
            text-align: left;
        }
        .newShop-item--info{
            text-align: left;
        }
        .newShop-item--info{
            padding: 24px 0px;
        }
        .newShop-item--img {
            margin-bottom: 12px;
        }
    }
    @media (max-width: 374px){
        .newShop-close{
            right: 5px;
        }
    }
    @media (max-width: 1024px){
        .newShop {
            position: fixed;
            bottom: -300%;
        }
    }
    .js-b-like--shop.active {
        bottom: 0%;
    }
    @media (max-width: 425px){
        br {
            display: block;
        }
    }
    .mail-js{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .main-js input{
      transition: all 150ms linear;
      outline: none;
      border-width: .5px;
      height: 32px;
      padding: 9px;
      border-color: #e8dbdb;
      border-radius: 6px;
      margin-top:6px;
      }
</style>
