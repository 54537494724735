let Walkpoints = [
	[[76.313, -5.604, 67.04],[74.025, -5.603, 66.848],[71.551, -5.607, 66.564],[68.846, -5.609, 66.5],[66.324, -5.609, 66.828],[63.986, -5.609, 67.491],[62.154, -5.61, 68.355],[60.57, -5.609, 69.53],[59.519, -5.609, 70.653],[58.533, -5.608, 72.052],[57.9, -5.605, 73.361],[56.681, -5.601, 77.47],[174, 60],[3]],
	[[56.865, -5.524, 74.023],[57.38, -5.612, 73.048],[58.048, -5.614, 71.731],[59.063, -5.617, 70.224],[60.142, -5.616, 69.102],[61.8, -5.616, 67.859],[63.743, -5.616, 66.966],[66.25, -5.616, 66.227],[68.832, -5.615, 65.9],[71.558, -5.616, 65.948],[74.708, -5.454, 66.214],[170, 168],[2]],
	[[51.771, -5.603, 107.601],[53.977, -5.601, 107.397],[55.694, -5.601, 107.518],[57.451, -5.601, 107.976],[59.083, -5.601, 108.965],[60.29, -5.601, 110.401],[61.067, -5.602, 111.97],[61.586, -5.602, 113.529],[61.969, -5.602, 115.057],[62.286, -5.602, 116.556],[62.58, -5.602, 118.024],[62.887, -5.602, 119.441],[63.264, -5.602, 120.798],[63.734, -5.602, 122.086],[64.316, -5.601, 123.256],[65.015, -5.601, 124.199],[65.814, -5.601, 124.782],[66.681, -5.601, 124.95],[67.612, -5.601, 124.705],[68.466, -5.601, 124.137],[69.054, -5.601, 123.317],[69.341, -5.601, 122.281],[69.343, -5.602, 121.084],[69.115, -5.602, 119.8],[68.722, -5.602, 118.485],[68.216, -5.602, 117.162],[67.611, -5.602, 115.783],[67.05, -5.602, 114.271],[66.65, -5.602, 112.67],[66.437, -5.602, 111.028],[66.422, -5.602, 109.366],[66.613, -5.602, 107.71],[67.015, -5.602, 106.086],[67.627, -5.602, 104.527],[68.441, -5.602, 103.061],[69.443, -5.602, 101.715],[70.621, -5.601, 100.512],[71.961, -5.601, 99.482],[73.439, -5.601, 98.656],[75.019, -5.601, 98.053],[76.664, -5.601, 97.682],[78.294, -5.601, 97.545],[80.223, -5.601, 97.646],[81.849, -5.601, 97.949],[83.425, -5.601, 98.431],[84.943, -5.601, 99.086],[86.383, -5.601, 99.925],[87.712, -5.601, 100.95],[88.893, -5.601, 102.156],[89.891, -5.602, 103.523],[90.681, -5.602, 105.022],[91.253, -5.603, 106.541],[201, 190],[8]],
	[[90.712, -5.586, 107.086],[90.117, -5.586, 105.254],[89.368, -5.586, 103.833],[88.423, -5.587, 102.539],[87.303, -5.587, 101.395],[86.039, -5.587, 100.42],[84.666, -5.587, 99.62],[83.213, -5.587, 98.994],[81.703, -5.587, 98.532],[80.146, -5.587, 98.241],[78.337, -5.587, 98.143],[76.76, -5.587, 98.276],[75.2, -5.587, 98.627],[73.702, -5.587, 99.199],[72.303, -5.587, 99.981],[71.033, -5.586, 100.957],[69.915, -5.586, 102.099],[68.962, -5.586, 103.379],[68.188, -5.586, 104.773],[67.605, -5.586, 106.256],[67.223, -5.586, 107.802],[67.04, -5.586, 109.381],[67.055, -5.586, 110.968],[67.259, -5.586, 112.538],[67.642, -5.586, 114.071],[68.184, -5.586, 115.534],[68.788, -5.586, 116.91],[69.308, -5.586, 118.269],[69.717, -5.586, 119.64],[69.961, -5.586, 121.013],[69.959, -5.587, 122.348],[69.62, -5.587, 123.57],[68.903, -5.587, 124.57],[67.869, -5.587, 125.258],[66.703, -5.587, 125.564],[65.564, -5.587, 125.343],[64.578, -5.587, 124.624],[63.79, -5.587, 123.562],[63.169, -5.586, 122.313],[62.678, -5.586, 120.969],[62.29, -5.586, 119.572],[61.978, -5.586, 118.132],[61.684, -5.586, 116.663],[61.37, -5.586, 115.178],[60.995, -5.586, 113.684],[60.498, -5.586, 112.188],[59.772, -5.587, 110.724],[58.678, -5.587, 109.423],[57.21, -5.587, 108.533],[55.595, -5.587, 108.112],[53.979, -5.587, 107.998],[52.169, -5.586, 108.171],[200, 23, 181],[8]],
	[[51.975, -4.2, 68.778],[53.303, -4.385, 69.051],[54.6, -4.838, 69.437],[55.614, -4.925, 70.037],[144, 59],[1]],
	[[55.76, -5.371, 71.045],[54.417, -4.765, 69.944],[53.065, -4.432, 69.53],[51.042, -4.066, 69.422],[149, 146],[1]],
	[[120.142, -1.952, 89.709],[121.378, -1.955, 90.864],[122.267, -1.955, 91.792],[123.009, -1.955, 92.783],[123.744, -1.955, 94.197],[124.122, -1.955, 95.604],[124.529, -1.955, 96.567],[11, 12],[1]],
	[[123.354, -1.953, 97.723],[123.499, -1.954, 95.818],[123.118, -1.954, 94.379],[122.494, -1.954, 93.101],[121.785, -1.954, 92.159],[120.956, -1.954, 91.296],[119.042, -1.955, 89.5],[102, 26],[1]],
	[[101.583, -2.429, 134.098],[102.83, -2.427, 130.949],[104.5, -2.427, 128.779],[106.672, -2.429, 127.109],[109.168, -2.431, 126.075],[111.885, -2.433, 125.72],[114.601, -2.433, 126.075],[117.098, -2.432, 127.109],[119.273, -2.433, 128.782],[120.171, -2.424, 129.863],[187, 155],[3]],
	[[120.367, -2.415, 131.263],[118.803, -2.416, 129.229],[116.77, -2.417, 127.666],[114.43, -2.417, 126.696],[111.887, -2.417, 126.364],[109.343, -2.417, 126.696],[107.003, -2.416, 127.666],[104.969, -2.416, 129.229],[103.403, -2.415, 131.267],[102.259, -2.431, 134.462],[49, 193],[3]],
	[[130.748, -2.019, 90.638],[129.162, -1.956, 92.419],[127.073, -1.955, 94.495],[125.765, -1.955, 95.976],[124.156, -1.955, 98.232],[12, 7],[1]],
	[[124.529, -1.955, 96.567],[125.332, -1.955, 95.55],[126.648, -1.955, 94.06],[128.721, -1.954, 91.999],[130.002, -2.0, 90.636],[25, 58, 166],[1]],
	[[124.156, -1.955, 98.232],[123.542, -2.065, 99.538],[46, 156],[1]],
	[[122.798, -2.061, 99.084],[123.354, -1.953, 97.723],[7, 11],[1]],
	[[57.101, 0.114, 41.253],[53.469, 0.113, 41.253],[51.469, 0.122, 41.365],[49.514, 0.127, 41.699],[47.604, 0.127, 42.253],[45.754, 0.126, 43.019],[44.014, 0.126, 43.979],[42.398, 0.127, 45.125],[40.889, 0.127, 46.475],[38.605, 0.129, 48.517],[37.26, 0.079, 49.386],[39.642, -0.359, 50.075],[42.717, -1.351, 51.369],[45.396, -2.302, 53.208],[47.848, -3.3, 55.041],[182, 159],[4]],
	[[47.686, -3.333, 55.705],[45.079, -2.301, 53.713],[42.397, -1.339, 51.885],[39.328, -0.326, 50.606],[36.841, 0.132, 49.894],[35.199, 0.145, 47.559],[36.85, 0.145, 46.3],[38.981, 0.146, 44.39],[40.645, 0.145, 42.907],[42.521, 0.145, 41.577],[44.537, 0.145, 40.465],[46.674, 0.145, 39.58],[48.885, 0.145, 38.939],[51.154, 0.15, 38.551],[53.437, -0.135, 38.42],[57.453, -0.136, 38.42],[27, 171],[4]],
	[[-36.413, 0.12, 56.365],[-34.118, 0.113, 60.142],[-31.354, 0.112, 62.354],[-28.582, -0.283, 63.747],[-25.65, -1.172, 64.699],[-23.094, -2.319, 65.245],[-21.116, -3.109, 65.608],[-19.285, -3.603, 65.92],[-17.66, -3.931, 66.177],[-16.156, -4.094, 66.46],[-14.86, -4.145, 66.758],[-13.736, -4.142, 67.106],[-12.684, -4.14, 67.546],[-11.708, -4.138, 68.078],[-10.762, -4.2, 68.737],[-9.848, -4.34, 69.554],[-9.049, -4.52, 70.473],[-8.363, -4.738, 71.494],[-7.571, -5.065, 73.145],[-7.017, -5.292, 74.996],[-6.701, -5.607, 77.048],[-6.623, -5.609, 79.329],[-6.343, -5.621, 80.754],[21, 19],[5]],
	[[-7.213, -5.607, 81.851],[-7.231, -5.61, 79.287],[-7.302, -5.61, 77.111],[-7.597, -5.295, 75.113],[-8.12, -5.069, 73.408],[-8.884, -4.741, 71.807],[-9.495, -4.522, 70.888],[-10.239, -4.342, 69.995],[-11.075, -4.201, 69.243],[-11.979, -4.134, 68.626],[-12.947, -4.135, 68.072],[-13.962, -4.136, 67.663],[-15.009, -4.138, 67.355],[-16.292, -4.086, 67.061],[-17.783, -3.922, 66.78],[-19.388, -3.592, 66.526],[-21.248, -3.099, 66.217],[-23.188, -2.31, 65.848],[-25.81, -1.164, 65.282],[-28.823, -0.279, 64.298],[-31.668, 0.113, 62.884],[-34.541, 0.112, 60.55],[-37.16, 0.123, 56.705],[161, 37],[5]],
	[[28.266, -5.609, 69.216],[27.891, -5.607, 68.179],[22, 177, 175],[1]],
	[[-6.272, -5.605, 82.052],[-7.029, -5.607, 84.551],[-7.84, -5.607, 87.233],[-8.329, -5.607, 89.157],[-8.811, -5.607, 91.43],[-9.169, -5.606, 93.979],[-9.284, -5.606, 96.728],[-9.037, -5.606, 99.604],[-8.026, -5.606, 103.082],[-8.697, -5.606, 103.769],[-11.18, -5.606, 102.091],[-12.889, -5.606, 101.44],[-14.487, -5.606, 101.141],[-16.035, -5.601, 101.078],[39, 135],[3]],
	[[-16.113, -5.602, 100.462],[-14.403, -5.606, 100.541],[-12.653, -5.606, 100.879],[-10.929, -5.606, 101.554],[-8.829, -5.606, 102.935],[-9.661, -5.606, 99.565],[-9.895, -5.606, 96.681],[-9.794, -5.606, 94.123],[-9.42, -5.607, 91.406],[-8.917, -5.607, 89.008],[-8.428, -5.607, 87.079],[-7.778, -5.608, 84.356],[-7.213, -5.607, 81.851],[17, 21],[3]],
	[[-6.343, -5.621, 80.754],[-5.375, -5.609, 78.58],[-3.572, -5.609, 75.833],[-1.044, -5.611, 72.739],[2.07, -5.613, 69.523],[4.485, -5.615, 66.5],[6.653, -5.615, 63.564],[7.986, -5.614, 61.396],[9.138, -5.614, 59.368],[10.329, -5.614, 57.527],[11.637, -5.616, 55.886],[13.566, -5.617, 54.312],[16.027, -5.615, 53.255],[18.816, -5.615, 52.947],[21.526, -5.617, 53.336],[24.025, -5.619, 54.152],[26.082, -5.619, 55.63],[27.699, -5.617, 57.308],[28.6, -5.611, 59.928],[28.789, -5.609, 62.554],[28.27, -5.607, 64.874],[28.222, -5.606, 66.733],[28.504, -5.607, 67.85],[177, 175, 18],[6]],
	[[27.891, -5.607, 68.179],[27.621, -5.607, 66.733],[27.675, -5.607, 64.797],[28.194, -5.607, 62.492],[28.027, -5.608, 60.077],[27.162, -5.609, 57.569],[25.745, -5.61, 56.104],[23.746, -5.61, 54.687],[21.38, -5.609, 53.879],[18.85, -5.609, 53.55],[16.219, -5.609, 53.779],[13.929, -5.609, 54.794],[12.058, -5.609, 56.295],[10.825, -5.609, 57.873],[9.661, -5.608, 59.665],[8.543, -5.608, 61.637],[7.188, -5.609, 63.865],[4.96, -5.608, 66.918],[2.46, -5.608, 69.994],[-0.594, -5.608, 73.108],[-3.051, -5.607, 76.172],[-4.799, -5.605, 78.867],[-6.272, -5.605, 82.052],[19, 17],[6]],
	[[51.138, -5.601, 107.772],[50.603, -5.606, 106.159],[50.11, -5.606, 104.196],[49.943, -5.606, 102.377],[50.059, -5.606, 99.913],[48.338, -5.606, 102.132],[46.539, -5.606, 104.062],[42.826, -5.604, 107.556],[38.79, -5.604, 111.985],[37.112, -5.606, 113.967],[34.302, -5.607, 118.119],[32.137, -5.609, 122.354],[30.606, -5.609, 126.697],[29.696, -5.607, 131.169],[29.395, -5.606, 135.797],[29.889, -5.606, 141.076],[31.346, -5.606, 145.832],[32.495, -5.606, 148.166],[178, 32],[6]],
	[[32.164, -5.606, 148.892],[30.821, -5.606, 145.89],[29.317, -5.606, 141.158],[28.788, -5.606, 135.79],[29.08, -5.606, 131.052],[30.079, -5.619, 126.474],[31.577, -5.619, 122.106],[33.805, -5.606, 117.772],[36.583, -5.607, 113.57],[38.214, -5.592, 111.566],[39.006, -5.584, 110.593],[42.416, -5.607, 106.974],[46.086, -5.606, 103.688],[47.873, -5.606, 101.763],[49.949, -5.606, 99.237],[50.621, -5.606, 99.603],[50.556, -5.606, 102.395],[50.717, -5.606, 104.125],[51.186, -5.606, 105.979],[51.771, -5.603, 107.601],[2, 200, 181],[7]],
	[[130.002, -2.0, 90.636],[127.967, -1.984, 90.625],[125.003, -1.954, 90.474],[122.46, -1.955, 90.115],[120.142, -1.952, 89.709],[6, 102],[1]],
	[[119.508, -1.952, 88.885],[122.513, -1.955, 89.437],[124.998, -1.957, 89.779],[127.963, -1.959, 89.925],[130.552, -2.052, 89.926],[58, 10, 166],[2]],
	[[57.453, -0.136, 38.42],[62.469, 0.15, 38.421],[66.686, 0.145, 38.421],[104.637, 0.145, 38.421],[106.556, 0.145, 38.497],[108.496, 0.145, 38.724],[110.408, 0.145, 39.099],[112.285, 0.145, 39.62],[114.114, 0.145, 40.282],[115.808, 0.19, 41.088],[33, 30],[6]],
	[[114.996, 0.168, 43.252],[113.128, 0.121, 42.671],[111.475, 0.12, 42.207],[109.751, 0.119, 41.854],[108.059, 0.119, 41.523],[106.336, 0.118, 41.321],[104.581, 0.119, 41.253],[100.368, 0.12, 41.253],[91.952, 0.126, 41.253],[66.702, 0.125, 41.253],[62.494, 0.12, 41.253],[57.828, 0.111, 41.253],[171, 14],[6]],
	[[136.525, 0.168, 59.473],[134.376, 0.212, 60.551],[132.573, 0.212, 61.176],[130.71, 0.212, 61.553],[128.797, 0.212, 61.679],[126.884, 0.212, 61.553],[125.02, 0.211, 61.176],[123.204, 0.211, 60.547],[122.373, 0.211, 60.27],[120.631, 0.211, 59.12],[120.051, 0.211, 58.703],[118.663, 0.211, 57.481],[117.442, 0.211, 56.095],[116.417, 0.21, 54.573],[115.592, 0.208, 52.926],[114.967, 0.207, 51.123],[114.591, 0.206, 49.26],[114.465, 0.206, 47.348],[114.619, 0.209, 45.151],[114.996, 0.168, 43.252],[28, 33],[4]],
	[[117.184, 0.23, 42.929],[116.483, 0.229, 45.389],[116.346, 0.231, 47.323],[116.456, 0.231, 48.986],[116.783, 0.231, 50.606],[117.326, 0.231, 52.172],[118.042, 0.231, 53.604],[118.934, 0.231, 54.927],[119.995, 0.231, 56.132],[121.2, 0.231, 57.193],[122.523, 0.231, 58.085],[123.955, 0.231, 58.801],[125.521, 0.231, 59.344],[127.141, 0.231, 59.671],[128.804, 0.231, 59.781],[130.467, 0.231, 59.671],[132.087, 0.231, 59.344],[133.66, 0.231, 58.799],[136.519, 0.187, 57.116],[34, 162],[3]],
	[[54.223, -5.605, 161.295],[52.115, -5.606, 161.048],[48.668, -5.607, 160.174],[45.75, -5.583, 158.952],[41.25, -5.608, 156.868],[36.756, -5.606, 154.106],[33.293, -5.606, 150.372],[32.164, -5.606, 148.892],[24, 178],[3]],
	[[32.938, -5.606, 148.936],[33.748, -5.606, 150.048],[37.21, -5.606, 153.6],[41.636, -5.605, 156.214],[48.712, -5.605, 159.143],[51.752, -5.606, 158.785],[55.565, -5.606, 158.145],[173, 142],[3]],
	[[115.808, 0.19, 41.088],[117.084, 0.234, 39.029],[118.431, 0.233, 37.412],[120.037, 0.233, 35.955],[121.559, 0.233, 34.928],[123.209, 0.233, 34.102],[125.016, 0.233, 33.476],[126.882, 0.233, 33.099],[128.798, 0.233, 32.973],[130.715, 0.233, 33.099],[132.581, 0.233, 33.476],[134.388, 0.233, 34.102],[136.038, 0.233, 34.928],[137.56, 0.234, 35.954],[138.93, 0.214, 37.211],[140.152, 0.217, 38.598],[141.177, 0.216, 40.12],[142.001, 0.214, 41.767],[142.626, 0.214, 43.57],[143.003, 0.214, 45.434],[143.129, 0.214, 47.347],[143.003, 0.214, 49.26],[142.627, 0.217, 51.124],[142.003, 0.221, 52.927],[141.178, 0.219, 54.574],[140.149, 0.214, 56.097],[138.724, 0.168, 57.815],[162, 29],[5]],
	[[136.519, 0.187, 57.116],[138.67, 0.231, 54.932],[139.565, 0.232, 53.604],[140.282, 0.232, 52.172],[140.825, 0.232, 50.606],[141.152, 0.231, 48.986],[141.261, 0.231, 47.323],[141.152, 0.231, 45.66],[140.825, 0.231, 44.04],[140.282, 0.231, 42.474],[139.565, 0.231, 41.042],[138.673, 0.232, 39.72],[137.613, 0.232, 38.515],[136.407, 0.233, 37.454],[135.085, 0.233, 36.562],[133.653, 0.233, 35.845],[132.087, 0.233, 35.302],[130.467, 0.233, 34.975],[128.804, 0.233, 34.866],[127.141, 0.233, 34.975],[125.521, 0.233, 35.302],[123.955, 0.233, 35.845],[122.523, 0.233, 36.562],[121.199, 0.233, 37.455],[119.788, 0.233, 38.738],[118.605, 0.232, 40.166],[117.184, 0.23, 42.929],[30, 28],[5]],
	[[-10.655, -5.602, 147.675],[-13.956, -5.605, 145.211],[-17.526, -5.605, 142.544],[-23.965, -5.605, 137.489],[-25.877, -5.605, 135.937],[-27.749, -5.605, 134.313],[-29.567, -5.605, 132.629],[-31.33, -5.605, 130.888],[-33.035, -5.605, 129.09],[-34.68, -5.605, 127.239],[-36.263, -5.594, 125.335],[-37.766, -5.591, 123.404],[-39.206, -5.586, 121.426],[-40.581, -5.585, 119.403],[-41.892, -5.605, 117.336],[-43.135, -5.605, 115.228],[-44.31, -5.606, 113.081],[-45.416, -5.606, 110.898],[-46.45, -5.606, 108.681],[-47.413, -5.607, 106.432],[-48.707, -5.609, 103.053],[104, 134],[6]],
	[[-45.426, -5.589, 103.667],[-44.807, -5.583, 105.346],[-43.879, -5.583, 107.514],[-42.883, -5.583, 109.65],[-41.818, -5.583, 111.752],[-40.686, -5.583, 113.819],[-39.489, -5.583, 115.849],[-38.227, -5.582, 117.84],[-36.902, -5.582, 119.789],[-35.515, -5.582, 121.694],[-34.069, -5.582, 123.553],[-32.544, -5.583, 125.386],[-30.959, -5.583, 127.169],[-29.317, -5.583, 128.9],[-27.62, -5.583, 130.578],[-25.868, -5.583, 132.199],[-24.065, -5.583, 133.763],[-22.207, -5.583, 135.271],[-15.812, -5.583, 140.292],[-12.331, -5.583, 142.894],[-9.16, -5.583, 145.235],[151, 152],[6]],
	[[-37.463, 0.143, 54.183],[-35.86, 0.145, 53.114],[-34.476, 0.145, 52.663],[-33.049, 0.145, 52.287],[-31.602, 0.145, 51.993],[-30.137, 0.145, 51.783],[-28.657, 0.145, 51.657],[-27.192, 0.146, 51.614],[-23.351, -0.054, 51.615],[-21.882, -0.046, 51.586],[139, 140],[2]],
	[[-21.157, -0.065, 54.504],[-22.834, -0.074, 54.256],[-27.162, 0.125, 54.209],[-28.526, 0.125, 54.324],[-29.831, 0.124, 54.599],[-31.123, 0.124, 54.784],[-32.412, 0.124, 55.046],[-33.682, 0.124, 55.38],[-35.221, 0.121, 55.853],[-36.413, 0.12, 56.365],[16, 161],[2]],
	[[-16.035, -5.601, 101.078],[-15.851, -5.602, 102.801],[-15.489, -5.602, 104.784],[-14.985, -5.602, 106.736],[-14.333, -5.602, 108.644],[-13.534, -5.602, 110.496],[-12.591, -5.602, 112.279],[-11.512, -5.602, 113.983],[-10.308, -5.602, 115.599],[-8.99, -5.602, 117.124],[-7.572, -5.602, 118.554],[-6.066, -5.602, 119.889],[-4.487, -5.602, 121.131],[-2.884, -5.602, 122.332],[-1.279, -5.602, 123.528],[0.337, -5.602, 124.71],[1.973, -5.602, 125.869],[3.637, -5.602, 126.988],[5.314, -5.602, 128.055],[6.788, -5.601, 129.288],[7.455, -5.6, 130.984],[6.941, -5.614, 132.782],[5.814, -5.604, 134.418],[4.902, -5.606, 136.219],[4.111, -5.614, 138.063],[3.135, -5.614, 139.794],[2.069, -5.606, 141.613],[153, 150],[5]],
	[[1.344, -5.587, 141.476],[2.579, -5.589, 139.458],[3.556, -5.589, 137.724],[4.518, -5.589, 135.98],[5.461, -5.589, 134.229],[6.344, -5.589, 132.541],[6.841, -5.588, 130.995],[6.28, -5.587, 129.638],[4.937, -5.586, 128.555],[3.287, -5.586, 127.508],[1.61, -5.586, 126.38],[-0.036, -5.586, 125.214],[-1.659, -5.586, 124.027],[-3.266, -5.586, 122.829],[-4.876, -5.586, 121.623],[-6.476, -5.586, 120.365],[-8.011, -5.586, 119.003],[-9.459, -5.586, 117.542],[-10.807, -5.586, 115.984],[-12.04, -5.586, 114.329],[-13.145, -5.586, 112.584],[-14.111, -5.586, 110.756],[-14.93, -5.586, 108.858],[-15.598, -5.586, 106.902],[-16.114, -5.586, 104.904],[-16.484, -5.586, 102.878],[-16.701, -5.586, 100.849],[135, 20],[5]],
	[[102.918, -2.415, 139.861],[104.962, -2.416, 143.058],[106.473, -2.416, 144.22],[154, 143],[1]],
	[[105.761, -2.428, 144.519],[104.497, -2.431, 143.546],[102.908, -2.431, 141.606],[172, 50],[1]],
	[[96.386, -5.607, 104.424],[97.443, -5.608, 103.07],[98.224, -5.607, 101.602],[98.624, -5.607, 100.321],[98.818, -5.608, 98.97],[98.781, -5.609, 97.68],[98.541, -5.608, 96.404],[98.087, -5.608, 95.212],[97.42, -5.607, 94.15],[96.694, -5.607, 93.391],[95.862, -5.607, 92.809],[94.933, -5.608, 92.409],[93.918, -5.607, 92.192],[92.829, -5.607, 92.16],[91.677, -5.607, 92.311],[90.366, -5.607, 92.65],[89.008, -5.607, 93.022],[87.571, -5.607, 93.146],[86.152, -5.607, 93.017],[84.832, -5.607, 92.663],[83.629, -5.607, 92.121],[82.491, -5.608, 91.389],[81.439, -5.608, 90.479],[80.371, -5.608, 89.24],[79.481, -5.608, 87.837],[78.799, -5.608, 86.316],[78.342, -5.608, 84.692],[78.146, -5.608, 83.049],[78.225, -5.608, 81.464],[78.581, -5.609, 79.949],[79.336, -5.609, 77.511],[79.873, -5.608, 75.308],[80.186, -5.608, 73.33],[80.277, -5.607, 71.552],[80.143, -5.607, 69.951],[79.752, -5.607, 68.425],[169, 101],[5]],
	[[80.425, -5.606, 68.663],[80.755, -5.607, 69.907],[80.893, -5.608, 71.632],[80.792, -5.611, 73.466],[80.477, -5.615, 75.433],[79.936, -5.616, 77.647],[79.151, -5.615, 80.17],[78.826, -5.613, 81.571],[78.756, -5.613, 83.051],[78.933, -5.613, 84.556],[79.344, -5.614, 86.047],[79.977, -5.614, 87.485],[80.822, -5.614, 88.832],[81.867, -5.613, 90.047],[82.907, -5.612, 90.942],[84.024, -5.61, 91.642],[85.205, -5.608, 92.143],[86.434, -5.608, 92.439],[87.698, -5.607, 92.529],[88.98, -5.607, 92.407],[90.25, -5.607, 92.077],[91.657, -5.608, 91.695],[92.976, -5.609, 91.546],[94.216, -5.611, 91.622],[95.321, -5.611, 91.903],[96.283, -5.61, 92.357],[97.147, -5.609, 92.984],[97.894, -5.609, 93.773],[98.602, -5.611, 94.886],[99.104, -5.612, 96.155],[99.385, -5.615, 97.54],[99.428, -5.617, 99.0],[99.224, -5.606, 100.455],[98.782, -5.609, 101.85],[98.066, -5.613, 103.435],[97.06, -5.612, 105.051],[194, 202],[5]],
	[[111.324, -2.119, 117.617],[112.214, -2.137, 118.818],[113.237, -2.136, 119.758],[115.258, -2.136, 120.651],[117.6, -2.136, 120.862],[119.563, -2.136, 120.484],[121.388, -2.136, 119.694],[123.044, -2.136, 118.589],[124.502, -2.136, 117.263],[125.731, -2.136, 115.807],[126.703, -2.136, 114.313],[127.384, -2.136, 112.87],[128.125, -2.136, 110.263],[128.315, -2.136, 107.885],[127.992, -2.136, 105.736],[127.202, -2.136, 103.817],[125.991, -2.137, 102.132],[124.41, -2.13, 100.679],[122.945, -2.123, 99.736],[156, 13],[4]],
	[[123.542, -2.065, 99.538],[124.699, -2.158, 100.373],[126.35, -2.15, 101.893],[127.603, -2.149, 103.636],[128.419, -2.149, 105.612],[128.754, -2.149, 107.831],[128.559, -2.149, 110.305],[127.782, -2.149, 113.04],[127.083, -2.148, 114.52],[126.074, -2.148, 116.068],[124.79, -2.148, 117.582],[123.267, -2.148, 118.961],[121.542, -2.148, 120.105],[119.652, -2.148, 120.918],[117.634, -2.148, 121.304],[115.12, -2.148, 121.076],[112.962, -2.148, 120.124],[111.893, -2.147, 119.169],[110.965, -2.154, 118.18],[195, 157],[4]],
	[[80.526, -5.605, 135.688],[80.423, -5.604, 137.256],[79.848, -5.605, 138.955],[78.863, -5.605, 140.456],[77.66, -5.605, 141.689],[76.32, -5.604, 142.721],[74.839, -5.603, 143.549],[73.248, -5.602, 144.148],[71.583, -5.602, 144.5],[69.882, -5.602, 144.598],[68.173, -5.602, 144.429],[66.496, -5.602, 143.966],[64.909, -5.602, 143.18],[63.527, -5.602, 142.07],[62.53, -5.601, 141.016],[179, 180],[3]],
	[[62.957, -5.586, 140.364],[63.962, -5.586, 141.61],[65.245, -5.586, 142.64],[66.718, -5.586, 143.37],[68.288, -5.586, 143.803],[69.897, -5.586, 143.963],[71.503, -5.586, 143.87],[73.076, -5.586, 143.537],[74.58, -5.587, 142.971],[75.981, -5.587, 142.187],[77.251, -5.587, 141.209],[78.381, -5.587, 140.05],[79.29, -5.587, 138.665],[79.824, -5.587, 137.09],[79.971, -5.587, 135.719],[199, 196],[3]],
	[[102.259, -2.431, 134.462],[102.104, -2.415, 136.159],[102.918, -2.415, 139.861],[41, 172],[1]],
	[[101.819, -2.427, 139.067],[101.439, -2.429, 136.149],[101.435, -2.433, 134.786],[193, 8],[1]],
	[[52.383, -4.058, 60.88],[50.664, -4.059, 60.134],[48.857, -4.066, 59.765],[46.558, -4.069, 60.464],[136, 158],[1]],
	[[46.78, -4.02, 59.382],[48.857, -4.089, 59.057],[51.638, -3.988, 59.695],[183, 184],[1]],
	[[-44.185, 0.145, 61.291],[-42.569, 0.141, 62.713],[-41.349, 0.141, 64.099],[-40.323, 0.142, 65.62],[-39.499, 0.142, 67.268],[-38.874, 0.142, 69.071],[-38.498, 0.142, 70.934],[-38.372, 0.143, 72.847],[-38.497, 0.145, 74.76],[-38.873, 0.147, 76.623],[-39.498, 0.148, 78.427],[-40.321, 0.151, 80.075],[-41.346, 0.153, 81.597],[-42.566, 0.154, 82.984],[-43.952, 0.154, 84.205],[-45.474, 0.154, 85.23],[-47.121, 0.147, 86.049],[-49.228, 0.14, 86.728],[-52.688, 0.14, 87.179],[-54.616, 0.143, 87.052],[-56.48, 0.147, 86.677],[-58.283, 0.147, 86.052],[-59.93, 0.145, 85.227],[-61.451, 0.144, 84.201],[-62.64, 0.145, 83.155],[103, 164],[5]],
	[[-62.219, 0.16, 82.19],[-60.872, 0.16, 83.431],[-59.449, 0.16, 84.39],[-57.911, 0.16, 85.16],[-56.228, 0.16, 85.743],[-54.478, 0.16, 86.096],[-50.988, 0.16, 85.979],[-47.502, 0.16, 85.165],[-45.954, 0.161, 84.39],[-44.533, 0.161, 83.432],[-43.239, 0.161, 82.292],[-42.099, 0.161, 80.998],[-41.141, 0.161, 79.577],[-40.371, 0.16, 78.039],[-39.788, 0.16, 76.356],[-39.437, 0.16, 74.616],[-39.319, 0.159, 72.829],[-39.437, 0.159, 71.043],[-39.788, 0.159, 69.303],[-40.371, 0.159, 67.62],[-41.141, 0.159, 66.082],[-42.099, 0.159, 64.661],[-43.245, 0.159, 63.359],[-46.012, 0.161, 61.361],[165, 160],[5]],
	[[-12.034, -0.001, 24.077],[-12.034, -0.0, 38.7],[-12.109, -0.037, 40.536],[141, 138],[2]],
	[[-13.269, -0.037, 40.696],[-13.268, -0.0, 38.704],[-13.268, -0.001, 23.998],[105, 108],[2]],
	[[87.837, 2.324, 50.543],[88.248, 2.36, 49.385],[88.873, 2.36, 48.068],[89.661, 2.36, 46.886],[90.913, 2.36, 45.621],[92.585, 2.36, 44.593],[94.48, 2.36, 44.031],[97.128, 2.359, 43.949],[99.617, 2.344, 44.393],[101.747, 2.274, 45.322],[103.48, 2.137, 46.711],[104.89, 1.919, 48.864],[105.779, 1.678, 51.314],[106.049, 1.388, 53.94],[105.968, 1.223, 56.094],[105.864, 1.018, 58.238],[105.91, 0.833, 60.181],[106.105, 0.668, 61.915],[106.452, 0.523, 63.44],[106.953, 0.398, 64.754],[107.611, 0.297, 65.855],[108.424, 0.101, 66.731],[109.15, 0.104, 67.247],[109.968, 0.104, 67.634],[110.877, 0.104, 67.892],[111.873, 0.104, 68.024],[112.954, 0.073, 68.03],[114.119, 0.073, 67.912],[115.371, 0.072, 67.669],[117.373, 0.072, 67.063],[119.51, 0.072, 66.507],[121.899, 0.103, 66.049],[124.459, -0.021, 65.781],[125.715, -0.021, 65.772],[127.037, -0.021, 65.87],[128.261, -0.129, 66.136],[129.318, -0.181, 66.49],[130.113, -0.255, 66.987],[130.737, -0.33, 67.609],[131.189, -0.451, 68.348],[131.497, -0.598, 69.395],[131.41, -0.803, 70.568],[130.822, -0.801, 71.643],[129.702, -0.801, 72.639],[128.446, -0.801, 73.362],[126.992, -0.801, 74.004],[125.43, -0.801, 74.593],[123.962, -0.801, 75.118],[122.667, -0.801, 75.599],[121.492, -0.801, 76.097],[120.489, -0.8, 76.635],[119.656, -0.799, 77.305],[119.239, -0.889, 78.235],[119.602, -0.921, 79.138],[120.128, -0.956, 79.741],[120.895, -0.94, 79.988],[121.692, -0.923, 80.013],[122.608, -0.919, 79.912],[123.53, -0.919, 79.741],[124.533, -0.918, 79.522],[125.631, -0.918, 79.256],[126.789, -0.919, 79.024],[128.016, -0.919, 78.84],[129.266, -0.924, 78.768],[130.578, -0.934, 78.893],[131.738, -0.985, 79.273],[132.77, -1.048, 79.941],[133.478, -1.124, 80.729],[133.893, -1.202, 81.551],[134.109, -1.309, 82.67],[134.047, -1.436, 84.016],[133.687, -1.571, 85.434],[133.043, -1.72, 86.995],[132.18, -1.884, 88.569],[131.329, -1.966, 89.942],[166, 10, 25],[10]],
	[[130.552, -2.052, 89.926],[131.635, -1.847, 88.35],[132.498, -1.695, 86.726],[133.103, -1.556, 85.265],[133.447, -1.429, 83.928],[133.508, -1.303, 82.606],[133.337, -1.225, 81.789],[132.984, -1.157, 81.078],[132.364, -1.092, 80.39],[131.449, -1.035, 79.796],[130.471, -1.01, 79.489],[129.27, -0.91, 79.375],[128.056, -0.915, 79.448],[126.868, -0.915, 79.629],[125.747, -0.915, 79.854],[124.671, -0.915, 80.115],[123.677, -0.915, 80.334],[122.698, -0.915, 80.517],[121.687, -0.911, 80.622],[120.699, -1.007, 80.57],[119.851, -1.006, 80.281],[119.103, -0.95, 79.478],[118.678, -0.883, 78.299],[119.161, -0.797, 77.018],[120.179, -0.799, 76.113],[121.198, -0.8, 75.564],[122.404, -0.8, 75.049],[123.737, -0.8, 74.552],[125.203, -0.8, 74.028],[126.721, -0.801, 73.457],[128.139, -0.801, 72.835],[129.368, -0.801, 72.133],[130.304, -0.801, 71.33],[130.822, -0.801, 70.45],[130.901, -0.602, 69.48],[130.642, -0.473, 68.608],[130.257, -0.37, 67.979],[129.725, -0.242, 67.454],[129.043, -0.174, 67.031],[128.101, -0.123, 66.716],[126.974, -0.016, 66.449],[125.682, -0.017, 66.359],[124.471, -0.019, 66.38],[122.005, 0.106, 66.644],[119.592, 0.075, 67.114],[117.55, 0.075, 67.636],[115.559, 0.077, 68.249],[114.172, 0.079, 68.52],[112.905, 0.08, 68.643],[111.735, 0.112, 68.626],[110.662, 0.112, 68.472],[109.687, 0.112, 68.181],[108.81, 0.112, 67.755],[108.033, 0.114, 67.197],[107.137, 0.258, 66.235],[106.41, 0.376, 65.026],[105.857, 0.514, 63.572],[105.497, 0.66, 62.036],[105.302, 0.835, 60.192],[105.256, 1.024, 58.215],[105.362, 1.232, 56.029],[105.431, 1.389, 53.953],[105.134, 1.668, 51.442],[104.365, 1.879, 49.122],[103.008, 2.095, 47.165],[101.381, 2.214, 45.869],[99.381, 2.339, 45.008],[97.05, 2.361, 44.601],[94.648, 2.36, 44.653],[92.84, 2.36, 45.165],[91.291, 2.36, 46.119],[90.204, 2.36, 47.258],[89.465, 2.36, 48.358],[88.867, 2.36, 49.612],[88.542, 2.324, 50.663],[133, 132],[10]],
	[[56.255, -5.154, 70.227],[56.592, -5.482, 72.236],[56.865, -5.524, 74.023],[1, 174],[1]],
	[[56.204, -5.652, 76.713],[55.76, -5.371, 71.045],[5, 144],[1]],
	[[-69.636, 0.0, -72.607],[-70.74, 0.0, -75.035],[-71.476, 0.0, -77.593],[-71.828, 0.0, -80.237],[-71.786, 0.0, -82.921],[-71.334, 0.0, -85.599],[-70.461, 0.0, -88.225],[-69.153, 0.0, -90.756],[122, 119],[2]],
	[[-39.198, 0.0, -95.28],[-38.918, 0.0, -95.453],[-38.657, 0.0, -95.65],[-38.418, 0.0, -95.871],[-38.202, 0.0, -96.112],[-38.01, 0.0, -96.373],[-37.843, 0.0, -96.651],[-37.703, 0.0, -96.945],[-37.591, 0.0, -97.252],[-37.509, 0.0, -97.571],[129, 95],[1]],
	[[4.184, 0.0, -93.404],[4.614, 0.0, -93.351],[5.035, 0.0, -93.352],[5.446, 0.0, -93.404],[5.845, 0.0, -93.505],[6.227, 0.0, -93.652],[6.588, 0.0, -93.844],[6.925, 0.0, -94.078],[7.231, 0.0, -94.352],[7.505, 0.0, -94.664],[7.741, 0.0, -95.012],[7.926, 0.0, -95.375],[11.25, 0.0, -100.827],[15.479, 0.0, -104.53],[20.32, 0.0, -106.636],[25.469, 0.0, -107.27],[30.618, 0.0, -106.554],[35.455, 0.0, -104.61],[39.668, 0.0, -101.565],[42.947, 0.0, -97.545],[128,],[5]],
	[[42.442, 0.0, -85.94],[42.453, 0.0, -89.224],[41.833, 0.0, -92.456],[40.583, 0.0, -95.528],[38.702, 0.0, -98.331],[36.189, 0.0, -100.756],[33.048, 0.0, -102.693],[29.57, 0.0, -103.931],[26.106, 0.0, -104.372],[22.742, 0.0, -104.084],[19.564, 0.0, -103.136],[16.656, 0.0, -101.596],[14.102, 0.0, -99.532],[11.985, 0.0, -97.012],[10.391, 0.0, -94.104],[9.403, 0.0, -90.876],[71, 125],[5]],
	[[43.902, 0.0, -79.51],[43.935, 0.0, -79.174],[44.001, 0.0, -78.843],[44.1, 0.0, -78.519],[44.231, 0.0, -78.207],[44.394, 0.0, -77.907],[44.588, 0.0, -77.622],[44.812, 0.0, -77.356],[110, 85],[1]],
	[[37.356, 0.0, -75.64],[38.633, 0.0, -76.857],[40.533, 0.0, -79.648],[41.802, 0.0, -82.712],[42.442, 0.0, -85.94],[64,],[2]],
	[[67.285, 0.0, -70.513],[68.551, 0.0, -68.75],[69.597, 0.0, -66.849],[70.395, 0.0, -64.87],[70.947, 0.0, -62.833],[71.256, 0.0, -60.762],[71.322, 0.0, -58.678],[71.147, 0.0, -56.603],[70.735, 0.0, -54.559],[70.085, 0.0, -52.568],[69.201, 0.0, -50.652],[117, 109],[2]],
	[[67.102, 0.0, -41.84],[67.766, 0.0, -41.224],[68.358, 0.0, -40.56],[68.881, 0.0, -39.85],[69.334, 0.0, -39.097],[69.713, 0.0, -38.308],[70.018, 0.0, -37.489],[70.246, 0.0, -36.643],[70.395, 0.0, -35.778],[70.464, 0.0, -34.898],[70.449, 0.0, -34.009],[70.349, 0.0, -33.117],[70.166, 0.0, -32.238],[69.902, 0.0, -31.389],[69.563, 0.0, -30.574],[69.152, 0.0, -29.798],[68.671, 0.0, -29.066],[68.126, 0.0, -28.382],[115, 113],[2]],
	[[16.432, -0.001, -34.942],[12.788, -0.001, -38.787],[8.611, -0.001, -42.045],[3.987, -0.001, -44.649],[0.324, -0.001, -46.04],[75, 78],[2]],
	[[10.982, -0.0, -48.858],[11.716, -0.0, -47.087],[12.791, -0.0, -45.438],[14.213, -0.0, -43.959],[15.884, -0.0, -42.769],[17.673, -0.0, -41.947],[19.533, -0.0, -41.481],[21.416, -0.0, -41.358],[94, 111],[2]],
	[[9.403, 0.0, -90.876],[9.106, 0.0, -87.397],[9.584, 0.0, -83.747],[13.319, 0.0, -68.185],[13.513, 0.0, -67.599],[13.796, 0.0, -67.062],[14.155, 0.0, -66.589],[14.579, 0.0, -66.185],[15.058, 0.0, -65.856],[97, 77],[3]],
	[[10.874, 0.0, -62.78],[10.976, 0.0, -65.022],[10.645, 0.0, -67.328],[6.176, 0.0, -85.95],[5.897, 0.0, -86.73],[5.474, 0.0, -87.4],[4.934, 0.0, -87.943],[4.306, 0.0, -88.355],[3.614, 0.0, -88.631],[125, 96],[3]],
	[[-13.127, 0.0, -67.21],[-13.42, 0.0, -65.044],[-13.322, 0.0, -62.894],[-12.853, 0.0, -60.81],[-12.036, 0.0, -58.842],[-10.889, 0.0, -57.04],[-9.435, -0.0, -55.453],[76, 79],[2]],
	[[-18.199, -0.0, -54.245],[-17.168, 0.0, -55.827],[-16.434, 0.0, -57.55],[-16.009, 0.0, -59.37],[-15.902, 0.0, -61.241],[-16.126, 0.0, -63.115],[-16.69, 0.0, -64.947],[-17.605, 0.0, -66.69],[81, 124],[2]],
	[[0.324, -0.001, -46.04],[-0.923, -0.001, -46.515],[-3.674, -0.001, -48.264],[-5.571, -0.001, -48.422],[-8.744, -0.001, -47.809],[-11.419, -0.001, -47.99],[-13.514, -0.001, -47.811],[79, 74],[2]],
	[[-9.435, -0.0, -55.453],[-7.693, -0.0, -54.133],[-5.686, -0.0, -53.129],[-3.435, -0.0, -52.492],[-1.105, -0.0, -52.283],[1.129, -0.0, -52.504],[3.227, -0.0, -53.116],[78, 70],[2]],
	[[14.871, 0.0, -60.695],[13.394, 0.0, -59.394],[12.213, 0.0, -57.884],[11.335, 0.0, -56.213],[10.767, 0.0, -54.428],[10.514, 0.0, -52.576],[10.584, 0.0, -50.704],[10.982, -0.0, -48.858],[70,],[2]],
	[[3.227, -0.0, -53.116],[5.152, 0.0, -54.079],[6.866, 0.0, -55.353],[8.333, 0.0, -56.899],[9.515, 0.0, -58.679],[10.374, 0.0, -60.652],[10.874, 0.0, -62.78],[72, 97],[2]],
	[[-13.514, -0.001, -47.811],[-16.82, -0.001, -48.049],[-21.874, -0.001, -46.298],[-23.697, -0.001, -45.556],[-26.759, -0.001, -44.317],[-30.133, -0.001, -42.32],[106, 126],[2]],
	[[-30.623, -0.0, -50.964],[-28.82, -0.0, -50.407],[-26.916, -0.0, -50.179],[-24.952, -0.0, -50.308],[-22.966, -0.0, -50.823],[-21.109, -0.0, -51.696],[-19.516, -0.0, -52.853],[-18.199, -0.0, -54.245],[74, 76],[2]],
	[[-17.605, 0.0, -66.69],[-18.876, 0.0, -68.289],[-29.799, 0.0, -79.67],[-30.433, 0.0, -80.202],[-31.137, 0.0, -80.566],[-31.877, 0.0, -80.764],[-32.627, 0.0, -80.802],[-33.363, 0.0, -80.69],[84,],[3]],
	[[-33.905, 0.0, -90.43],[-32.42, 0.0, -87.27],[-30.179, 0.0, -84.35],[-19.153, 0.0, -72.75],[-18.692, 0.0, -72.339],[-18.178, 0.0, -72.016],[-17.631, 0.0, -71.787],[-17.061, 0.0, -71.65],[-16.481, 0.0, -71.604],[-15.903, 0.0, -71.649],[124, 73],[3]],
	[[-42.282, 0.0, -62.101],[-41.738, 0.0, -62.346],[-41.241, 0.0, -62.673],[-40.802, 0.0, -63.077],[-40.43, 0.0, -63.549],[-40.137, 0.0, -64.083],[-39.934, 0.0, -64.672],[-39.831, 0.0, -65.299],[-38.699, 0.0, -80.124],[-38.792, 0.0, -83.431],[-39.542, 0.0, -86.484],[121, 131],[3]],
	[[-33.363, 0.0, -80.69],[-34.062, 0.0, -80.438],[-34.7, 0.0, -80.053],[-35.252, 0.0, -79.544],[-35.695, 0.0, -78.919],[-36.004, 0.0, -78.189],[-36.151, 0.0, -77.375],[-37.276, 0.0, -61.64],[-37.231, 0.0, -59.598],[127, 120],[3]],
	[[42.898, 0.0, -70.14],[41.869, 0.0, -69.179],[40.066, 0.0, -66.604],[38.784, 0.0, -63.554],[34.479, 0.0, -49.323],[34.347, 0.0, -48.701],[34.324, 0.0, -48.078],[34.404, 0.0, -47.474],[34.58, 0.0, -46.9],[34.844, 0.0, -46.365],[92, 93],[3]],
	[[29.601, 0.0, -45.411],[30.671, 0.0, -47.063],[31.442, 0.0, -48.955],[36.009, 0.0, -64.054],[36.162, 0.0, -64.867],[36.135, 0.0, -65.659],[35.944, 0.0, -66.401],[35.61, 0.0, -67.074],[35.152, 0.0, -67.661],[98,],[3]],
	[[64.032, 0.0, -40.537],[63.314, 0.0, -40.85],[62.575, 0.0, -41.074],[61.825, 0.0, -41.209],[61.069, 0.0, -41.258],[60.316, 0.0, -41.221],[59.573, 0.0, -41.099],[58.847, 0.0, -40.894],[58.146, 0.0, -40.606],[57.478, 0.0, -40.238],[56.85, 0.0, -39.79],[56.275, 0.0, -39.268],[90, 91],[1]],
	[[52.719, 0.0, -43.956],[56.02, 0.0, -44.166],[59.045, 0.0, -45.023],[61.735, 0.0, -46.443],[118, 114],[1]],
	[[42.529, 0.0, -29.245],[48.323, 0.0, -35.156],[48.836, 0.0, -35.806],[49.178, 0.0, -36.521],[49.353, 0.0, -37.266],[49.368, 0.0, -38.017],[49.234, 0.0, -38.75],[48.96, 0.0, -39.44],[91, 88],[2]],
	[[56.275, 0.0, -39.268],[50.186, 0.0, -33.057],[49.632, 0.0, -32.342],[49.283, 0.0, -31.56],[49.132, 0.0, -30.75],[49.167, 0.0, -29.945],[49.372, 0.0, -29.172],[116, 99],[2]],
	[[48.96, 0.0, -39.44],[48.556, 0.0, -40.066],[48.031, 0.0, -40.603],[47.393, 0.0, -41.026],[46.654, 0.0, -41.312],[45.835, 0.0, -41.435],[33.447, 0.0, -41.941],[31.695, 0.0, -41.85],[93, 86],[2]],
	[[34.844, 0.0, -46.365],[35.189, 0.0, -45.88],[35.607, 0.0, -45.455],[36.093, 0.0, -45.1],[36.637, 0.0, -44.826],[37.233, 0.0, -44.643],[37.863, 0.0, -44.563],[52.719, 0.0, -43.956],[88, 90],[2]],
	[[31.695, 0.0, -41.85],[30.051, 0.0, -41.447],[28.549, 0.0, -40.767],[27.215, 0.0, -39.843],[26.07, 0.0, -38.709],[25.14, 0.0, -37.398],[24.448, -0.0, -35.944],[111, 69],[1]],
	[[21.416, -0.0, -41.358],[23.278, 0.0, -41.563],[25.073, 0.0, -42.084],[26.756, 0.0, -42.908],[28.28, 0.0, -44.021],[29.601, 0.0, -45.411],[86, 92],[1]],
	[[-35.997, 0.0, -103.841],[-33.216, 0.0, -108.533],[-29.415, 0.0, -111.993],[-24.894, 0.0, -114.236],[-19.953, 0.0, -115.27],[-14.899, 0.0, -115.102],[-10.038, 0.0, -113.744],[-5.674, 0.0, -111.207],[-2.111, 0.0, -107.502],[0.354, 0.0, -102.637],[1.42, 0.0, -96.592],[1.461, 0.0, -96.187],[1.556, 0.0, -95.777],[1.698, 0.0, -95.388],[1.885, 0.0, -95.021],[2.114, 0.0, -94.681],[2.381, 0.0, -94.372],[2.684, 0.0, -94.096],[3.02, 0.0, -93.858],[3.39, 0.0, -93.658],[4.184, 0.0, -93.404],[63,],[6]],
	[[-1.583, 0.0, -92.932],[-1.337, 0.0, -96.299],[-1.772, 0.0, -99.587],[-2.834, 0.0, -102.701],[-4.47, 0.0, -105.549],[-6.624, 0.0, -108.036],[-9.245, 0.0, -110.069],[-12.277, 0.0, -111.554],[-15.666, 0.0, -112.395],[-19.355, 0.0, -112.499],[-22.985, 0.0, -111.829],[-26.204, 0.0, -110.476],[-28.972, 0.0, -108.542],[-31.248, 0.0, -106.13],[-32.995, 0.0, -103.341],[-34.172, 0.0, -100.276],[-34.743, 0.0, -97.035],[123, 62],[6]],
	[[15.058, 0.0, -65.856],[15.581, 0.0, -65.605],[16.139, 0.0, -65.438],[16.719, 0.0, -65.359],[17.313, 0.0, -65.375],[17.908, 0.0, -65.491],[18.486, 0.0, -65.707],[32.961, 0.0, -72.536],[36.106, 0.0, -74.448],[37.356, 0.0, -75.64],[66,],[3]],
	[[35.152, 0.0, -67.661],[34.589, 0.0, -68.146],[33.941, 0.0, -68.513],[33.227, 0.0, -68.745],[32.465, 0.0, -68.825],[31.678, 0.0, -68.736],[30.896, 0.0, -68.466],[16.629, 0.0, -61.734],[14.871, 0.0, -60.695],[77, 72],[3]],
	[[46.597, 0.0, -23.03],[34.84, 0.0, -21.37],[24.013, -0.001, -20.986],[107, 112],[3]],
	[[27.593, -0.0, -25.352],[28.979, -0.0, -24.508],[30.48, -0.0, -23.923],[32.055, -0.0, -23.605],[33.665, -0.0, -23.562],[35.27, 0.0, -23.803],[36.83, 0.0, -24.336],[38.304, 0.0, -25.169],[39.644, 0.0, -26.302],[42.529, 0.0, -29.245],[89, 116],[2]],
	[[80.462, -5.607, 68.02],[83.141, -5.607, 68.965],[85.684, -5.607, 69.769],[90.155, -5.607, 71.641],[93.242, -5.608, 73.18],[95.835, -5.618, 74.785],[98.914, -5.45, 76.898],[102.993, -5.096, 80.261],[106.427, -4.27, 83.062],[110.091, -3.364, 85.509],[113.354, -2.09, 87.374],[113.408, -2.073, 87.401],[113.47, -2.06, 87.425],[113.53, -2.056, 87.442],[116.763, -1.947, 88.178],[119.508, -1.952, 88.885],[26, 6],[5]],
	[[119.042, -1.955, 89.5],[116.583, -1.924, 88.844],[113.224, -2.037, 88.081],[113.193, -2.04, 88.073],[113.168, -2.045, 88.063],[113.14, -2.054, 88.049],[109.797, -3.361, 86.108],[106.023, -4.267, 83.567],[102.595, -5.103, 80.725],[98.624, -5.595, 77.442],[95.536, -5.604, 75.291],[92.999, -5.611, 73.658],[89.935, -5.61, 72.19],[85.524, -5.609, 70.376],[82.928, -5.608, 69.532],[80.425, -5.606, 68.663],[44, 169],[5]],
	[[-62.64, 0.145, 83.155],[-64.259, 0.15, 84.62],[-65.45, -0.12, 85.899],[-66.254, -0.528, 87.368],[-66.586, -1.075, 89.31],[-66.235, -1.6, 91.003],[-65.401, -2.126, 92.752],[-64.302, -2.562, 94.494],[-62.953, -2.951, 96.174],[-61.362, -3.329, 97.768],[-59.666, -3.748, 99.125],[-57.781, -4.158, 100.299],[-54.817, -4.711, 101.676],[-51.581, -5.112, 102.524],[-49.166, -5.611, 102.429],[-47.82, -5.644, 101.89],[134, 36],[4]],
	[[-48.707, -5.609, 103.053],[-51.75, -5.113, 103.106],[-55.014, -4.708, 102.24],[-58.046, -4.154, 100.846],[-59.961, -3.742, 99.644],[-61.745, -3.322, 98.218],[-63.366, -2.944, 96.594],[-64.8, -2.553, 94.847],[-65.886, -2.116, 93.106],[-66.775, -1.592, 91.226],[-67.114, -1.071, 89.376],[-66.816, -0.527, 87.155],[-65.941, -0.124, 85.548],[-64.723, 0.146, 84.227],[-63.121, 0.142, 82.664],[164, 54],[4]],
	[[-13.268, -0.001, 23.998],[-16.041, -0.001, 23.82],[-21.409, -0.001, 22.655],[-26.542, -0.001, 20.703],[-31.327, -0.001, 18.008],[-35.658, -0.001, 14.629],[-39.436, -0.001, 10.643],[-42.578, -0.001, 6.139],[-45.014, -0.001, 1.216],[-46.688, -0.001, -4.014],[-47.565, -0.001, -9.436],[-47.623, -0.001, -14.928],[-46.863, -0.001, -20.367],[-45.3, -0.001, -25.632],[-42.97, -0.001, -30.606],[-39.924, -0.001, -35.176],[-36.232, -0.001, -39.242],[-30.965, -0.001, -43.562],[126, 80],[9]],
	[[-30.133, -0.001, -42.32],[-35.276, -0.001, -38.23],[-38.829, -0.001, -34.317],[-41.759, -0.001, -29.92],[-44.001, -0.001, -25.135],[-45.504, -0.001, -20.069],[-46.236, -0.001, -14.836],[-46.18, -0.001, -9.553],[-45.337, -0.001, -4.336],[-43.725, -0.001, 0.696],[-41.382, -0.001, 5.432],[-38.359, -0.001, 9.766],[-34.724, -0.001, 13.601],[-30.558, -0.001, 16.851],[-25.954, -0.001, 19.444],[-21.015, -0.001, 21.322],[-15.851, -0.001, 22.443],[-12.664, -0.001, 22.648],[108, 55],[9]],
	[[24.013, -0.001, -20.986],[24.798, -0.001, -15.639],[24.673, -0.001, -10.286],[23.975, -0.001, -5.069],[22.409, -0.001, 0.205],[21.055, -0.001, 5.476],[18.02, -0.001, 10.031],[14.327, -0.001, 14.096],[10.07, -0.001, 17.566],[5.343, -0.001, 20.363],[0.253, -0.001, 22.424],[-5.088, -0.001, 23.703],[-10.561, -0.001, 24.172],[-12.034, -0.001, 24.077],[55, 105],[7]],
	[[-12.664, -0.001, 22.648],[-10.578, -0.001, 22.782],[-5.313, -0.001, 22.331],[-0.175, -0.001, 21.1],[4.723, -0.001, 19.117],[9.271, -0.001, 16.426],[13.367, -0.001, 13.088],[16.919, -0.001, 9.177],[19.859, -0.001, 4.765],[21.261, -0.001, -0.278],[22.759, -0.001, -5.323],[23.279, -0.001, -10.37],[23.409, -0.001, -15.508],[22.641, -0.001, -20.751],[112, 100],[7]],
	[[68.56, 0.0, -59.031],[68.282, 0.0, -62.06],[67.349, 0.0, -65.062],[65.718, 0.0, -67.952],[63.524, 0.0, -70.442],[60.994, 0.0, -72.309],[58.224, 0.0, -73.566],[55.31, 0.0, -74.23],[52.346, 0.0, -74.314],[49.426, 0.0, -73.837],[46.644, 0.0, -72.812],[44.093, 0.0, -71.254],[42.898, 0.0, -70.14],[85, 98],[4]],
	[[44.812, 0.0, -77.356],[45.062, 0.0, -77.114],[45.332, 0.0, -76.901],[45.62, 0.0, -76.718],[45.923, 0.0, -76.566],[46.239, 0.0, -76.445],[46.564, 0.0, -76.356],[46.897, 0.0, -76.299],[47.236, 0.0, -76.276],[47.577, 0.0, -76.287],[47.918, 0.0, -76.333],[48.245, 0.0, -76.411],[50.371, 0.0, -76.899],[52.493, 0.0, -77.125],[54.603, 0.0, -77.098],[56.68, 0.0, -76.828],[58.703, 0.0, -76.321],[60.651, 0.0, -75.586],[62.504, 0.0, -74.629],[64.241, 0.0, -73.46],[65.841, 0.0, -72.085],[67.285, 0.0, -70.513],[67,],[3]],
	[[24.448, -0.0, -35.944],[24.016, -0.0, -34.38],[23.87, -0.0, -32.738],[24.032, -0.0, -31.053],[24.527, -0.0, -29.359],[25.326, -0.0, -27.787],[26.362, -0.0, -26.448],[27.593, -0.0, -25.352],[100, 107],[2]],
	[[22.641, -0.001, -20.751],[22.419, -0.001, -23.953],[21.812, -0.001, -25.849],[19.462, -0.001, -30.597],[16.432, -0.001, -34.942],[69, 94],[2]],
	[[67.653, 0.0, -35.067],[67.557, 0.0, -35.824],[67.376, 0.0, -36.559],[67.114, 0.0, -37.266],[66.774, 0.0, -37.938],[66.36, 0.0, -38.569],[65.876, 0.0, -39.151],[65.324, 0.0, -39.677],[64.708, 0.0, -40.142],[64.032, 0.0, -40.537],[87, 118],[1]],
	[[65.878, 0.0, -43.869],[65.946, 0.0, -43.608],[66.032, 0.0, -43.354],[66.135, 0.0, -43.108],[66.256, 0.0, -42.869],[66.395, 0.0, -42.64],[66.549, 0.0, -42.421],[66.72, 0.0, -42.213],[66.906, 0.0, -42.017],[67.102, 0.0, -41.84],[68,],[1]],
	[[68.126, 0.0, -28.382],[67.519, 0.0, -27.751],[66.855, 0.0, -27.177],[66.136, 0.0, -26.665],[65.367, 0.0, -26.22],[64.557, 0.0, -25.847],[62.489, 0.0, -25.273],[46.597, 0.0, -23.03],[99, 89],[3]],
	[[49.372, 0.0, -29.172],[49.732, 0.0, -28.46],[50.232, 0.0, -27.836],[50.86, 0.0, -27.33],[51.601, 0.0, -26.97],[52.439, 0.0, -26.788],[53.343, 0.0, -26.81],[61.954, 0.0, -28.026],[62.716, 0.0, -28.179],[63.449, 0.0, -28.419],[64.142, 0.0, -28.738],[64.789, 0.0, -29.132],[65.385, 0.0, -29.594],[65.924, 0.0, -30.12],[66.401, 0.0, -30.704],[66.81, 0.0, -31.341],[67.147, 0.0, -32.026],[67.404, 0.0, -32.753],[67.578, 0.0, -33.517],[67.661, 0.0, -34.296],[67.653, 0.0, -35.067],[113, 68],[3]],
	[[69.201, 0.0, -50.652],[68.084, 0.0, -48.833],[66.73, 0.0, -47.125],[66.562, 0.0, -46.924],[66.405, 0.0, -46.704],[66.265, 0.0, -46.474],[66.142, 0.0, -46.236],[66.038, 0.0, -45.99],[65.951, 0.0, -45.737],[65.883, 0.0, -45.478],[65.833, 0.0, -45.214],[65.803, 0.0, -44.947],[65.792, 0.0, -44.677],[65.801, 0.0, -44.405],[65.83, 0.0, -44.135],[65.878, 0.0, -43.869],[114, 87],[1]],
	[[61.735, 0.0, -46.443],[64.044, 0.0, -48.34],[65.926, 0.0, -50.632],[67.336, 0.0, -53.233],[68.229, 0.0, -56.06],[68.56, 0.0, -59.031],[109,],[2]],
	[[-69.052, 0.0, -80.825],[-68.678, 0.0, -77.89],[-67.752, 0.0, -75.073],[-66.285, 0.0, -72.469],[-64.29, 0.0, -70.173],[-61.78, 0.0, -68.28],[-58.778, 0.0, -66.891],[-44.708, 0.0, -62.085],[-44.091, 0.0, -61.932],[-43.469, 0.0, -61.887],[-42.863, 0.0, -61.946],[-42.282, 0.0, -62.101],[83, 127],[4]],
	[[-42.946, -0.0, -55.426],[-45.553, 0.0, -57.19],[-48.243, -0.0, -58.811],[-51.019, 0.0, -60.28],[-53.904, 0.0, -61.58],[-56.876, 0.0, -62.722],[-59.912, 0.0, -63.673],[-64.232, 0.0, -66.559],[-66.371, 0.0, -68.325],[-68.175, 0.0, -70.355],[-69.636, 0.0, -72.607],[61,],[3]],
	[[-39.542, 0.0, -86.484],[-40.865, 0.0, -89.223],[-42.68, 0.0, -91.597],[-44.904, 0.0, -93.559],[-47.454, 0.0, -95.061],[-50.248, 0.0, -96.053],[-53.205, 0.0, -96.488],[-56.242, 0.0, -96.317],[-59.275, 0.0, -95.492],[-62.221, 0.0, -93.964],[-64.787, 0.0, -91.859],[-66.742, 0.0, -89.397],[-68.096, 0.0, -86.673],[-68.862, 0.0, -83.784],[-69.052, 0.0, -80.825],[119, 61],[4]],
	[[-69.153, 0.0, -90.756],[-67.468, 0.0, -93.053],[-65.504, 0.0, -95.003],[-63.306, 0.0, -96.598],[-60.921, 0.0, -97.83],[-58.396, 0.0, -98.689],[-55.777, 0.0, -99.168],[-53.111, 0.0, -99.259],[-50.445, 0.0, -98.951],[-47.825, 0.0, -98.238],[-45.297, 0.0, -97.11],[-42.897, 0.0, -95.552],[-42.634, 0.0, -95.37],[-42.346, 0.0, -95.209],[-42.046, 0.0, -95.079],[-41.737, 0.0, -94.978],[-41.42, 0.0, -94.908],[-41.099, 0.0, -94.868],[-40.775, 0.0, -94.858],[-40.451, 0.0, -94.88],[-40.128, 0.0, -94.933],[-39.809, 0.0, -95.017],[131,],[4]],
	[[-34.743, 0.0, -97.035],[-34.667, 0.0, -93.719],[-33.905, 0.0, -90.43],[82,],[1]],
	[[-15.903, 0.0, -71.649],[-15.337, 0.0, -71.783],[-14.795, 0.0, -72.006],[-14.289, 0.0, -72.317],[-13.832, 0.0, -72.715],[-13.439, 0.0, -73.192],[-4.33, 0.0, -86.351],[-2.565, 0.0, -89.58],[-1.583, 0.0, -92.932],[96,],[3]],
	[[3.614, 0.0, -88.631],[2.884, 0.0, -88.765],[2.139, 0.0, -88.755],[1.405, 0.0, -88.595],[0.708, 0.0, -88.279],[0.073, 0.0, -87.805],[-0.466, 0.0, -87.176],[-11.29, 0.0, -71.378],[-12.422, 0.0, -69.341],[-13.127, 0.0, -67.21],[73, 81],[3]],
	[[-30.965, -0.001, -43.562],[-33.526, -0.0, -46.779],[-35.75, 0.0, -49.225],[-38.041, 0.0, -51.427],[-40.445, -0.0, -53.499],[-42.946, -0.0, -55.426],[120, 83],[2]],
	[[-37.231, 0.0, -59.598],[-36.821, 0.0, -57.673],[-36.084, 0.0, -55.903],[-35.057, 0.0, -54.319],[-33.78, 0.0, -52.949],[-32.289, 0.0, -51.821],[-30.623, -0.0, -50.964],[80, 106],[2]],
	[[42.947, 0.0, -97.545],[44.985, 0.0, -92.675],[45.476, 0.0, -87.075],[44.104, 0.0, -80.84],[44.005, 0.0, -80.526],[43.936, 0.0, -80.189],[43.902, 0.0, -79.849],[43.902, 0.0, -79.51],[65, 64],[2]],
	[[-37.509, 0.0, -97.571],[-37.461, 0.0, -97.882],[-35.997, 0.0, -103.841],[95,],[1]],
	[[-39.497, 0.0, -95.134],[-39.198, 0.0, -95.28],[62, 123],[1]],
	[[-39.809, 0.0, -95.017],[-39.497, 0.0, -95.134],[130,],[1]],
	[[88.114, 2.324, 51.152],[86.37, 2.324, 50.908],[84.369, 2.324, 51.139],[82.534, 2.324, 51.797],[80.913, 2.324, 52.828],[79.561, 2.324, 54.18],[78.53, 2.324, 55.801],[77.872, 2.324, 57.636],[77.642, 2.324, 59.635],[77.872, 2.324, 61.634],[78.53, 2.324, 63.469],[79.561, 2.324, 65.089],[80.913, 2.324, 66.442],[82.534, 2.324, 67.473],[84.369, 2.324, 68.13],[86.368, 2.324, 68.361],[88.367, 2.324, 68.13],[90.202, 2.324, 67.473],[91.822, 2.324, 66.442],[93.175, 2.324, 65.089],[94.206, 2.324, 63.469],[94.863, 2.324, 61.634],[95.094, 2.324, 59.635],[94.863, 2.324, 57.636],[94.206, 2.324, 55.801],[93.175, 2.324, 54.18],[91.822, 2.324, 52.828],[90.198, 2.324, 51.794],[88.114, 2.324, 51.152],[57,],[6]],
	[[88.542, 2.324, 50.663],[90.441, 2.324, 51.317],[92.161, 2.324, 52.411],[93.597, 2.324, 53.848],[94.693, 2.324, 55.569],[95.391, 2.324, 57.519],[95.636, 2.324, 59.642],[95.391, 2.324, 61.765],[94.693, 2.324, 63.715],[93.597, 2.324, 65.436],[92.161, 2.324, 66.873],[90.44, 2.324, 67.968],[88.49, 2.324, 68.666],[86.367, 2.324, 68.911],[84.244, 2.324, 68.666],[82.294, 2.324, 67.968],[80.573, 2.324, 66.873],[79.136, 2.324, 65.436],[78.041, 2.324, 63.715],[77.342, 2.324, 61.765],[77.097, 2.324, 59.642],[77.342, 2.324, 57.519],[78.041, 2.324, 55.569],[79.136, 2.324, 53.848],[80.573, 2.324, 52.411],[82.294, 2.324, 51.316],[84.244, 2.324, 50.618],[86.367, 2.324, 50.372],[87.837, 2.324, 50.543],[57,],[6]],
	[[-47.82, -5.644, 101.89],[-46.295, -5.678, 101.279],[-45.601, -5.605, 100.132],[-45.345, -5.612, 98.118],[-44.902, -5.615, 96.192],[-44.249, -5.617, 94.33],[-43.377, -5.618, 92.562],[-42.283, -5.618, 90.925],[-40.972, -5.618, 89.458],[-39.464, -5.618, 88.195],[-37.789, -5.618, 87.162],[-35.984, -5.616, 86.375],[-34.09, -5.612, 85.832],[-32.143, -5.61, 85.527],[-30.169, -5.607, 85.464],[-28.19, -5.606, 85.579],[-26.219, -5.606, 85.824],[-24.26, -5.604, 86.171],[-22.317, -5.604, 86.598],[-20.41, -5.604, 87.123],[-18.622, -5.603, 87.892],[-17.176, -5.602, 89.126],[-16.407, -5.602, 90.864],[-16.173, -5.602, 92.801],[-16.156, -5.602, 94.785],[-16.196, -5.602, 96.784],[-16.185, -5.602, 98.79],[-16.113, -5.602, 100.462],[20, 39],[5]],
	[[-16.701, -5.586, 100.849],[-16.825, -5.586, 98.792],[-16.836, -5.586, 96.761],[-16.796, -5.586, 94.763],[-16.811, -5.586, 92.825],[-17.02, -5.587, 91.016],[-17.68, -5.587, 89.486],[-18.957, -5.587, 88.416],[-20.621, -5.587, 87.711],[-22.47, -5.587, 87.204],[-24.383, -5.587, 86.783],[-26.313, -5.587, 86.442],[-28.247, -5.588, 86.201],[-30.176, -5.588, 86.089],[-32.08, -5.588, 86.15],[-33.948, -5.589, 86.445],[-35.762, -5.59, 86.967],[-37.485, -5.59, 87.721],[-39.08, -5.59, 88.706],[-40.516, -5.59, 89.909],[-41.766, -5.59, 91.308],[-42.812, -5.59, 92.871],[-43.649, -5.59, 94.565],[-44.278, -5.589, 96.356],[-44.708, -5.589, 98.215],[-44.951, -5.591, 100.106],[-45.426, -5.589, 103.667],[36, 104],[5]],
	[[46.558, -4.069, 60.464],[45.385, -4.067, 61.374],[44.772, -4.067, 62.211],[44.334, -4.066, 63.469],[148, 176],[1]],
	[[43.619, -4.089, 63.039],[44.181, -4.085, 61.865],[44.872, -4.084, 60.871],[45.807, -4.121, 60.171],[158, 52],[1]],
	[[-12.485, -0.037, 41.611],[-14.266, -0.037, 41.844],[-16.442, -0.037, 42.745],[-18.332, -0.037, 44.199],[-19.786, -0.037, 46.089],[-20.687, -0.037, 48.265],[-20.996, -0.038, 50.63],[-20.688, -0.04, 52.994],[140, 38],[2]],
	[[-21.882, -0.046, 51.586],[-21.968, -0.037, 50.634],[-21.626, -0.037, 48.011],[-20.629, -0.037, 45.605],[-19.02, -0.037, 43.511],[-16.924, -0.037, 41.9],[-14.55, -0.037, 40.822],[-13.269, -0.037, 40.696],[56, 141],[2]],
	[[-20.688, -0.04, 52.994],[-19.786, -0.034, 55.17],[-18.332, -0.026, 57.06],[-16.442, -0.033, 58.514],[-14.258, -0.04, 59.419],[-11.333, -0.04, 59.546],[-7.373, -0.041, 58.523],[-5.471, -0.041, 57.06],[-4.011, -0.04, 55.162],[-2.957, -0.038, 51.74],[-3.112, -0.037, 48.273],[-4.017, -0.037, 46.089],[-5.471, -0.037, 44.199],[-7.361, -0.037, 42.745],[-9.541, -0.037, 41.842],[-12.485, -0.037, 41.611],[138, 56],[4]],
	[[-12.109, -0.037, 40.536],[-9.283, -0.037, 40.905],[-6.877, -0.037, 41.902],[-4.783, -0.037, 43.511],[-3.174, -0.037, 45.605],[-2.177, -0.037, 48.011],[-1.836, -0.037, 50.624],[-2.259, -0.044, 52.916],[-3.202, -0.058, 55.665],[-4.803, -0.057, 57.747],[-6.883, -0.057, 59.346],[-10.199, -0.056, 60.337],[-12.289, -0.056, 60.663],[-14.514, -0.056, 60.348],[-16.916, -0.064, 59.353],[-19.004, 0.128, 57.747],[-20.612, -0.065, 55.655],[-21.157, -0.065, 54.504],[38, 139],[5]],
	[[56.772, -5.646, 160.862],[60.764, -5.61, 161.392],[64.4, -5.609, 161.487],[67.95, -5.609, 161.397],[71.519, -5.42, 161.128],[75.105, -5.277, 160.68],[78.706, -5.104, 160.053],[82.32, -4.842, 159.247],[85.946, -4.55, 158.262],[89.585, -4.247, 157.098],[92.71, -4.009, 155.808],[95.564, -3.751, 154.232],[98.144, -3.471, 152.446],[100.45, -3.22, 150.526],[102.483, -2.965, 148.548],[104.247, -2.696, 146.583],[105.761, -2.428, 144.519],[42, 154],[6]],
	[[106.438, -2.426, 145.101],[104.674, -2.694, 147.008],[102.9, -2.962, 148.993],[100.842, -3.217, 150.983],[98.49, -3.468, 152.937],[95.853, -3.748, 154.749],[92.942, -4.005, 156.354],[89.774, -4.243, 157.677],[86.114, -4.546, 158.847],[82.459, -4.839, 159.839],[78.817, -5.102, 160.651],[75.188, -5.275, 161.283],[71.574, -5.418, 161.734],[67.978, -5.61, 162.005],[64.401, -5.607, 162.096],[60.743, -5.605, 162.0],[54.223, -5.605, 161.295],[31, 173],[6]],
	[[55.614, -4.925, 70.037],[55.128, -4.43, 68.091],[54.657, -4.13, 66.376],[54.369, -4.091, 65.24],[147, 185],[1]],
	[[54.724, -4.082, 64.402],[55.266, -4.13, 66.215],[55.751, -4.446, 67.993],[56.255, -5.154, 70.227],[59, 5],[1]],
	[[51.254, -4.071, 68.373],[52.49, -4.071, 67.262],[53.13, -4.065, 66.225],[53.95, -4.066, 64.418],[185, 145],[1]],
	[[54.369, -4.091, 65.24],[53.763, -4.078, 66.538],[53.041, -4.07, 67.745],[51.975, -4.2, 68.778],[4, 149],[1]],
	[[44.334, -4.066, 63.469],[44.193, -4.065, 64.997],[44.643, -4.065, 66.469],[45.565, -4.065, 67.748],[47.049, -4.065, 68.749],[48.877, -4.064, 69.119],[51.254, -4.071, 68.373],[146, 4],[1]],
	[[51.042, -4.066, 69.422],[48.865, -4.075, 69.862],[46.764, -4.077, 69.436],[45.045, -4.08, 68.276],[43.96, -4.081, 66.748],[43.505, -4.08, 65.157],[43.51, -4.082, 63.605],[176, 137],[2]],
	[[1.707, -5.585, 142.522],[-0.837, -5.581, 142.19],[-3.38, -5.581, 142.522],[-5.72, -5.581, 143.492],[-7.754, -5.581, 145.055],[-9.317, -5.583, 147.089],[152, 35],[2]],
	[[-9.16, -5.583, 145.235],[-8.444, -5.581, 144.371],[-6.205, -5.581, 142.649],[-3.634, -5.581, 141.584],[-0.835, -5.581, 141.218],[1.344, -5.587, 141.476],[40, 153],[2]],
	[[-9.317, -5.583, 147.089],[-10.287, -5.585, 149.429],[-10.619, -5.585, 151.972],[-10.287, -5.584, 154.516],[-9.317, -5.582, 156.856],[-7.754, -5.581, 158.89],[-5.72, -5.581, 160.453],[-3.38, -5.581, 161.423],[-0.837, -5.581, 161.755],[1.707, -5.582, 161.423],[4.047, -5.584, 160.453],[6.087, -5.585, 158.884],[8.149, -5.585, 155.697],[8.947, -5.585, 151.981],[8.613, -5.585, 149.429],[7.927, -5.585, 147.771],[6.87, -5.586, 146.081],[5.529, -5.587, 144.631],[4.047, -5.588, 143.492],[1.707, -5.585, 142.522],[150, 40],[5]],
	[[2.069, -5.606, 141.613],[4.515, -5.616, 142.686],[6.22, -5.616, 143.997],[7.416, -5.616, 145.072],[8.033, -5.602, 145.862],[8.734, -5.603, 147.287],[9.526, -5.603, 149.199],[9.89, -5.603, 151.988],[9.55, -5.603, 154.286],[8.473, -5.603, 157.334],[6.748, -5.603, 159.576],[4.517, -5.603, 161.292],[1.952, -5.581, 162.354],[-0.838, -5.581, 162.72],[-3.629, -5.581, 162.356],[-6.195, -5.581, 161.293],[-8.426, -5.581, 159.577],[-10.14, -5.581, 157.346],[-11.203, -5.603, 154.78],[-11.567, -5.602, 151.99],[-11.202, -5.603, 149.194],[-10.655, -5.602, 147.675],[35, 151],[5]],
	[[106.473, -2.416, 144.22],[109.337, -2.415, 145.596],[111.887, -2.415, 145.929],[114.43, -2.415, 145.596],[116.77, -2.416, 144.627],[118.804, -2.416, 143.063],[120.367, -2.416, 141.03],[121.337, -2.416, 138.69],[121.669, -2.416, 136.146],[121.337, -2.416, 133.603],[120.367, -2.415, 131.263],[9, 187],[3]],
	[[121.453, -2.422, 132.198],[121.974, -2.431, 133.448],[122.329, -2.428, 136.164],[121.974, -2.43, 138.88],[120.939, -2.431, 141.377],[119.27, -2.429, 143.549],[117.098, -2.427, 145.219],[114.601, -2.426, 146.254],[111.885, -2.425, 146.609],[109.169, -2.426, 146.254],[106.438, -2.426, 145.101],[143, 42],[3]],
	[[122.945, -2.123, 99.736],[120.39, -2.13, 98.829],[118.712, -2.136, 99.438],[117.416, -2.136, 100.921],[116.33, -2.136, 102.945],[115.289, -2.136, 105.226],[114.15, -2.136, 107.481],[112.761, -2.136, 109.446],[111.139, -2.136, 111.84],[110.492, -2.135, 114.213],[110.715, -2.136, 116.413],[111.324, -2.119, 117.617],[45, 195],[3]],
	[[110.766, -2.148, 117.547],[110.278, -2.144, 116.594],[110.028, -2.145, 114.279],[110.7, -2.147, 111.76],[112.47, -2.149, 109.137],[113.852, -2.149, 107.135],[115.015, -2.149, 104.758],[116.107, -2.149, 102.365],[117.278, -2.15, 100.29],[118.659, -2.15, 98.868],[120.392, -2.157, 98.371],[122.798, -2.061, 99.084],[13, 46],[3]],
	[[45.807, -4.121, 60.171],[46.706, -3.826, 58.146],[47.196, -3.57, 56.948],[47.686, -3.333, 55.705],[15, 182],[1]],
	[[48.27, -3.567, 56.135],[47.758, -3.721, 57.246],[47.224, -3.919, 58.414],[46.78, -4.02, 59.382],[52, 136],[1]],
	[[-47.011, 0.161, 59.637],[-44.611, 0.195, 58.175],[-43.399, 0.196, 57.327],[-42.064, 0.133, 56.724],[-40.53, 0.119, 56.666],[-38.36, 0.135, 55.301],[-37.463, 0.143, 54.183],[37, 16],[2]],
	[[-37.16, 0.123, 56.705],[-38.426, 0.104, 57.407],[-39.546, 0.081, 58.041],[-40.599, 0.044, 58.752],[-41.605, 0.175, 59.514],[-42.667, 0.175, 60.266],[-44.185, 0.145, 61.291],[53, 165],[1]],
	[[138.724, 0.168, 57.815],[140.103, 0.055, 60.548],[141.087, -0.119, 63.374],[141.689, -0.346, 66.304],[141.895, -0.501, 69.256],[141.981, -1.331, 84.235],[141.191, -1.645, 89.891],[186, 167],[4]],
	[[139.229, -1.625, 89.478],[139.106, -0.505, 69.305],[138.925, -0.367, 66.682],[138.398, -0.169, 64.115],[137.538, 0.055, 61.644],[136.525, 0.168, 59.473],[29, 34],[3]],
	[[-63.121, 0.142, 82.664],[-64.057, 0.142, 81.595],[-65.083, 0.142, 80.073],[-65.908, 0.142, 78.426],[-66.532, 0.142, 76.623],[-66.909, 0.143, 74.76],[-67.035, 0.114, 72.847],[-66.909, 0.143, 70.934],[-66.532, 0.142, 69.071],[-65.908, 0.142, 67.268],[-65.083, 0.142, 65.621],[-64.057, 0.141, 64.098],[-62.852, 0.164, 62.677],[-61.464, 0.163, 61.455],[-59.94, 0.163, 60.428],[-58.29, 0.163, 59.602],[-56.484, 0.163, 58.976],[-54.618, 0.163, 58.599],[-52.702, 0.163, 58.473],[-50.785, 0.163, 58.599],[-48.919, 0.163, 58.976],[-47.011, 0.161, 59.637],[160, 53],[4]],
	[[-46.012, 0.161, 61.361],[-49.165, 0.163, 59.918],[-50.915, 0.163, 59.565],[-52.701, 0.163, 59.447],[-54.488, 0.163, 59.565],[-56.228, 0.163, 59.916],[-57.911, 0.163, 60.499],[-59.449, 0.163, 61.269],[-60.87, 0.163, 62.227],[-62.164, 0.162, 63.367],[-63.304, 0.16, 64.661],[-64.262, 0.159, 66.082],[-65.032, 0.159, 67.62],[-65.615, 0.159, 69.303],[-65.966, 0.158, 71.043],[-66.084, 0.157, 72.829],[-65.966, 0.158, 74.616],[-65.615, 0.159, 76.356],[-65.032, 0.159, 78.039],[-64.262, 0.159, 79.577],[-63.303, 0.159, 80.999],[-62.219, 0.16, 82.19],[54, 103],[4]],
	[[131.329, -1.966, 89.942],[133.42, -1.879, 89.949],[135.393, -1.743, 89.877],[137.441, -1.627, 89.758],[139.229, -1.625, 89.478],[163, 186],[1]],
	[[139.997, -1.666, 90.221],[137.516, -1.664, 90.362],[135.478, -1.737, 90.521],[133.453, -1.879, 90.602],[130.748, -2.019, 90.638],[10, 58, 25],[1]],
	[[75.596, -5.615, 65.948],[80.462, -5.607, 68.02],[101, 44],[1]],
	[[79.752, -5.607, 68.425],[76.313, -5.604, 67.04],[0, 170],[1]],
	[[74.708, -5.454, 66.214],[71.244, -5.058, 64.148],[68.474, -4.409, 62.2],[66.008, -3.767, 60.029],[63.542, -2.994, 57.245],[61.424, -2.239, 54.337],[59.723, -1.487, 51.566],[58.686, -0.659, 48.784],[58.412, 0.108, 46.423],[58.404, 0.127, 46.371],[58.394, 0.131, 46.313],[58.382, 0.128, 46.259],[57.101, 0.114, 41.253],[14, 27],[4]],
	[[57.828, 0.111, 41.253],[59.092, 0.148, 46.419],[59.1, 0.14, 46.456],[59.105, 0.129, 46.489],[59.109, 0.122, 46.527],[59.246, -0.66, 48.542],[60.236, -1.487, 51.3],[61.883, -2.253, 54.035],[63.946, -2.987, 56.842],[66.397, -3.771, 59.606],[68.857, -4.413, 61.767],[71.573, -5.082, 63.6],[75.596, -5.615, 65.948],[168, 0],[4]],
	[[102.908, -2.431, 141.606],[98.242, -2.729, 144.031],[91.187, -3.574, 147.598],[85.407, -4.446, 150.521],[80.246, -5.16, 153.083],[76.662, -5.484, 154.733],[73.483, -5.61, 156.047],[70.212, -5.61, 157.258],[67.234, -5.609, 158.233],[64.225, -5.609, 159.108],[61.189, -5.61, 159.879],[56.772, -5.646, 160.862],[142, 31],[5]],
	[[55.565, -5.606, 158.145],[60.545, -5.606, 157.165],[63.492, -5.607, 156.416],[66.41, -5.607, 155.568],[69.3, -5.607, 154.621],[72.485, -5.607, 153.442],[75.578, -5.478, 152.161],[79.076, -5.152, 150.55],[84.17, -4.44, 148.02],[89.932, -3.57, 145.106],[96.978, -2.725, 141.544],[101.819, -2.427, 139.067],[50, 41],[5]],
	[[56.681, -5.601, 77.47],[52.183, -5.609, 75.729],[48.657, -5.609, 74.303],[45.062, -5.609, 73.563],[42.074, -5.608, 73.431],[38.926, -5.608, 73.905],[35.562, -5.609, 74.509],[31.917, -5.613, 74.154],[29.263, -5.612, 71.685],[28.266, -5.609, 69.216],[18, 22, 177],[3]],
	[[28.701, -5.606, 68.467],[29.704, -5.634, 71.298],[32.141, -5.633, 73.656],[35.502, -5.609, 73.766],[38.726, -5.611, 73.182],[42.047, -5.615, 72.684],[45.109, -5.618, 72.818],[48.826, -5.617, 73.573],[52.481, -5.612, 75.042],[56.204, -5.652, 76.713],[60, 1],[3]],
	[[43.51, -4.082, 63.605],[42.445, -4.156, 63.469],[39.171, -4.38, 63.708],[36.225, -4.922, 64.361],[33.366, -5.341, 65.49],[30.676, -5.609, 67.092],[28.701, -5.606, 68.467],[175, 22, 18],[2]],
	[[28.504, -5.607, 67.85],[30.337, -5.607, 66.615],[33.092, -5.339, 64.96],[36.047, -4.921, 63.794],[39.106, -4.379, 63.062],[42.519, -4.157, 62.863],[43.619, -4.089, 63.039],[137, 148],[2]],
	[[32.495, -5.606, 148.166],[34.633, -5.606, 148.945],[36.748, -5.606, 149.561],[38.801, -5.606, 150.073],[40.778, -5.606, 150.451],[42.658, -5.606, 150.668],[46.163, -5.606, 150.786],[49.319, -5.606, 150.551],[52.2, -5.606, 149.851],[54.868, -5.606, 148.578],[57.387, -5.606, 146.626],[59.823, -5.606, 143.898],[62.144, -5.603, 140.445],[180, 48],[4]],
	[[62.53, -5.601, 141.016],[60.285, -5.606, 144.286],[57.772, -5.606, 147.078],[55.167, -5.606, 149.083],[52.427, -5.606, 150.394],[49.437, -5.606, 151.134],[46.158, -5.606, 151.39],[42.618, -5.606, 151.275],[40.716, -5.606, 151.057],[38.718, -5.606, 150.677],[36.642, -5.606, 150.163],[34.515, -5.606, 149.545],[32.938, -5.606, 148.936],[32, 24],[4]],
	[[62.144, -5.603, 140.445],[61.524, -5.602, 139.218],[60.981, -5.602, 137.549],[60.745, -5.602, 135.89],[60.699, -5.597, 134.759],[60.397, -5.596, 132.597],[60.182, -5.602, 131.774],[59.667, -5.602, 130.628],[58.956, -5.602, 129.627],[58.052, -5.602, 128.79],[56.977, -5.602, 128.118],[55.767, -5.602, 127.602],[54.463, -5.602, 127.221],[53.097, -5.602, 126.953],[51.652, -5.602, 126.772],[50.078, -5.602, 126.569],[48.437, -5.602, 126.139],[46.843, -5.602, 125.421],[45.395, -5.602, 124.364],[44.22, -5.602, 122.989],[43.399, -5.602, 121.392],[42.942, -5.602, 119.688],[42.81, -5.602, 117.96],[42.961, -5.602, 116.25],[43.385, -5.602, 114.588],[44.075, -5.602, 113.012],[45.024, -5.601, 111.568],[46.206, -5.601, 110.31],[47.571, -5.601, 109.275],[49.063, -5.601, 108.47],[51.138, -5.601, 107.772],[23, 2, 200],[5]],
	[[51.076, -5.587, 108.409],[49.323, -5.587, 109.014],[47.908, -5.587, 109.777],[46.622, -5.587, 110.753],[45.511, -5.586, 111.934],[44.621, -5.586, 113.29],[43.972, -5.586, 114.772],[43.573, -5.586, 116.337],[43.43, -5.586, 117.946],[43.554, -5.586, 119.566],[43.98, -5.586, 121.151],[44.737, -5.586, 122.625],[45.818, -5.586, 123.892],[47.156, -5.586, 124.868],[48.645, -5.586, 125.538],[50.198, -5.586, 125.945],[51.732, -5.586, 126.143],[53.197, -5.586, 126.326],[54.611, -5.586, 126.604],[55.977, -5.586, 127.003],[57.265, -5.586, 127.553],[58.43, -5.586, 128.28],[59.424, -5.586, 129.201],[60.207, -5.586, 130.303],[60.765, -5.586, 131.546],[61.203, -5.586, 133.601],[61.361, -5.586, 135.816],[61.586, -5.586, 137.392],[62.092, -5.586, 138.948],[62.957, -5.586, 140.364],[48, 179],[5]],
	[[47.848, -3.3, 55.041],[49.432, -3.726, 56.225],[50.542, -3.846, 57.467],[52.523, -4.029, 59.884],[184, 51],[1]],
	[[51.638, -3.988, 59.695],[50.027, -3.807, 57.907],[48.913, -3.652, 56.65],[48.27, -3.567, 56.135],[159, 15],[1]],
	[[52.523, -4.029, 59.884],[54.029, -4.085, 62.526],[54.724, -4.082, 64.402],[145, 147],[1]],
	[[53.95, -4.066, 64.418],[53.376, -4.063, 62.774],[52.383, -4.058, 60.88],[51, 183],[1]],
	[[141.191, -1.645, 89.891],[141.184, -2.389, 103.336],[141.325, -2.387, 105.668],[141.425, -2.387, 108.107],[141.302, -2.387, 110.328],[140.677, -2.387, 112.589],[139.87, -2.387, 114.808],[138.887, -2.387, 116.961],[137.731, -2.387, 119.039],[136.414, -2.413, 121.018],[134.951, -2.413, 122.879],[133.349, -2.416, 124.614],[131.616, -2.419, 126.215],[129.76, -2.42, 127.675],[127.789, -2.418, 128.987],[125.749, -2.416, 130.123],[121.453, -2.422, 132.198],[155, 9],[5]],
	[[120.171, -2.424, 129.863],[124.43, -2.423, 128.066],[126.232, -2.502, 127.01],[128.137, -2.569, 125.582],[129.872, -2.544, 124.197],[131.596, -2.485, 122.605],[133.111, -2.458, 121.066],[134.358, -2.387, 119.498],[135.354, -2.39, 117.578],[136.397, -2.389, 115.703],[137.285, -2.389, 113.758],[138.187, -2.389, 111.76],[139.098, -2.389, 109.699],[139.686, -2.389, 107.493],[139.958, -2.388, 105.447],[140.048, -2.39, 103.27],[139.997, -1.666, 90.221],[167, 163],[5]],
	[[84.422, -5.61, 134.139],[83.069, -5.607, 133.058],[82.04, -5.607, 131.998],[81.285, -5.607, 130.967],[80.209, -5.562, 129.284],[198, 191],[1]],
	[[80.096, -5.605, 128.056],[81.809, -5.608, 130.658],[82.509, -5.608, 131.611],[83.537, -5.609, 132.658],[85.315, -5.609, 133.879],[192, 197],[1]],
	[[91.397, -5.602, 107.376],[91.582, -5.602, 108.263],[91.69, -5.602, 109.939],[91.572, -5.602, 111.613],[91.231, -5.602, 113.259],[90.663, -5.602, 114.849],[89.866, -5.603, 116.354],[88.834, -5.604, 117.713],[87.644, -5.604, 118.875],[86.399, -5.604, 119.873],[85.159, -5.604, 120.775],[83.969, -5.603, 121.638],[82.868, -5.603, 122.514],[81.903, -5.603, 123.443],[81.118, -5.603, 124.453],[80.549, -5.603, 125.555],[80.21, -5.602, 126.745],[80.096, -5.605, 128.056],[189, 198],[3]],
	[[79.541, -5.587, 128.717],[79.604, -5.587, 126.609],[79.974, -5.587, 125.309],[80.598, -5.587, 124.102],[81.445, -5.587, 123.012],[82.463, -5.587, 122.032],[83.597, -5.587, 121.13],[84.799, -5.587, 120.258],[86.026, -5.587, 119.366],[87.236, -5.587, 118.395],[88.372, -5.587, 117.286],[89.346, -5.587, 116.004],[90.099, -5.587, 114.582],[90.637, -5.586, 113.075],[90.962, -5.586, 111.511],[91.073, -5.586, 109.92],[90.971, -5.586, 108.326],[90.712, -5.586, 107.086],[3, 201],[3]],
	[[85.315, -5.609, 133.879],[86.498, -5.61, 134.425],[87.88, -5.611, 134.866],[89.17, -5.612, 135.104],[90.502, -5.615, 135.217],[91.832, -5.244, 135.259],[93.256, -4.826, 135.147],[94.619, -4.428, 134.952],[96.227, -4.001, 134.653],[98.122, -3.531, 134.319],[100.186, -2.903, 134.09],[101.583, -2.429, 134.098],[8, 49],[2]],
	[[101.435, -2.433, 134.786],[100.31, -2.906, 134.701],[98.271, -3.533, 134.921],[96.373, -4.004, 135.249],[94.771, -4.43, 135.546],[93.389, -4.825, 135.748],[91.924, -5.243, 135.87],[90.5, -5.614, 135.848],[89.149, -5.613, 135.729],[87.811, -5.61, 135.452],[86.301, -5.609, 134.994],[84.994, -5.608, 134.542],[197, 188],[2]],
	[[97.06, -5.612, 105.051],[96.177, -5.61, 107.836],[95.935, -5.61, 109.443],[95.907, -5.61, 111.007],[96.098, -5.61, 112.5],[96.515, -5.611, 113.893],[97.527, -5.455, 115.659],[98.987, -5.262, 117.05],[100.86, -4.91, 118.034],[103.892, -4.03, 118.63],[106.668, -3.1, 118.689],[109.566, -2.227, 118.191],[110.766, -2.148, 117.547],[157, 45],[3]],
	[[110.965, -2.154, 118.18],[109.697, -2.23, 118.798],[106.71, -3.112, 119.309],[103.816, -4.042, 119.224],[100.617, -4.917, 118.591],[98.609, -5.265, 117.529],[97.045, -5.454, 116.028],[95.949, -5.607, 114.109],[95.506, -5.607, 112.641],[95.297, -5.607, 111.043],[95.33, -5.607, 109.348],[95.597, -5.607, 107.633],[96.25, -5.61, 105.453],[202, 43],[3]],
	[[80.642, -5.6, 134.953],[81.387, -5.607, 134.68],[82.249, -5.607, 134.404],[83.227, -5.607, 134.219],[84.422, -5.61, 134.139],[188, 192],[1]],
	[[84.994, -5.608, 134.542],[83.328, -5.608, 134.783],[82.346, -5.608, 134.916],[81.344, -5.608, 135.299],[80.526, -5.605, 135.688],[47, 199],[1]],
	[[80.209, -5.562, 129.284],[80.414, -5.602, 131.209],[80.537, -5.602, 132.347],[80.647, -5.602, 133.916],[80.642, -5.6, 134.953],[196, 47],[1]],
	[[79.971, -5.587, 135.719],[80.032, -5.586, 133.923],[79.925, -5.586, 132.384],[79.804, -5.586, 131.268],[79.541, -5.587, 128.717],[191, 189],[1]],
	[[52.169, -5.586, 108.171],[51.076, -5.587, 108.409],[181, 23, 2],[1]],
	[[91.253, -5.603, 106.541],[92.977, -5.606, 106.361],[94.299, -5.606, 105.853],[95.31, -5.606, 105.317],[96.386, -5.607, 104.424],[43, 194],[1]],
	[[96.25, -5.61, 105.453],[94.538, -5.606, 106.419],[93.166, -5.606, 106.938],[91.397, -5.602, 107.376],[190, 3],[1]]
];
export{Walkpoints};
