let linkArr = [
	// {name:"MC_vladkim",url:"https://mrexpo.ru/master-classes/vlad-kim",pos:[38.473,2.637,7.576]},
	// {name:"MC_urijpostrigaj",url:"https://mrexpo.ru/master-classes/urij-postrigaj",pos:[-70.846,4.563,41.294]},
	// {name:"MC_kristinavolodina",url:"https://mrexpo.ru/master-classes/kristina-volodina",pos:[53.988,2.335,-97.517]},
	// {name:"MC_mihailkitaev",url:"https://mrexpo.ru/master-classes/mihail-kitaev",pos:[-111.641,1.393,-44.462]},
	// {name:"MC_vladislavlitvinenko",url:"https://mrexpo.ru/master-classes/vladislav-litvinenko",pos:[-118.091,1.599,39.052]},
	// {name:"MC_anastasiazaharova",url:"https://mrexpo.ru/master-classes/anastasia-zaharova",pos:[33.248,2.335,-124.431]},
	// {name:"MC_irinasluckaa",url:"https://mrexpo.ru/master-classes/irina-sluckaa",pos:[33.562,1.589,-75.557]},
	// {name:"MC_kristinaefimova",url:"https://mrexpo.ru/master-classes/kristina-efimova",pos:[42.638,1.589,-81.726]},
	// {name:"MC_anaburlakova",url:"https://mrexpo.ru/master-classes/ana-burlakova",pos:[3.634,1.589,-89.21]},
	// {name:"MC_alinanikulcenko",url:"https://mrexpo.ru/master-classes/alina-nikulcenko",pos:[17.124,1.589,-103.521]},
	// {name:"MC_evdokiaharitonovic",url:"https://mrexpo.ru/master-classes/evdokia-haritonovic",pos:[97.889,2.554,-28.073]},
	// {name:"MC_elenanikolaeva",url:"https://mrexpo.ru/master-classes/elena-nikolaeva",pos:[91.685,1.523,37.426]},
	// {name:"MC_vaceslavzabelin",url:"https://mrexpo.ru/master-classes/vaceslav-zabelin",pos:[68.391,1.523,41.421]},
	// {name:"MC_konstantinrodikov",url:"https://mrexpo.ru/master-classes/konstantin-rodikov",pos:[-132.086,12.811,41.987]},
	// {name:"MC_katerinaiva",url:"https://mrexpo.ru/master-classes/katerina-iva",pos:[-31.434,-4.134,120.995]},
	// {name:"MC_masaallilueva",url:"https://mrexpo.ru/master-classes/masa-allilueva",pos:[93.505,2.554,-45.499]},
	// {name:"MC_ekaterinakononova",url:"https://mrexpo.ru/master-classes/ekaterina-kononova",pos:[32.516,-4.089,158.003]},
	// {name:"MC_katafirsova",url:"https://mrexpo.ru/master-classes/kata-firsova",pos:[-136.85,12.902,-20.131]},
	// {name:"MC_lizanosk",url:"https://mrexpo.ru/master-classes/liza-nosk",pos:[-110.663,12.902,-41.466]},
	// {name:"MC_uliannakorsunova",url:"https://mrexpo.ru/master-classes/ulianna-korsunova",pos:[-52.671,4.472,48.939]},
	// {name:"MC_kristiannavinicenko",url:"https://mrexpo.ru/master-classes/kristianna-vinicenko",pos:[122.219,-1.022,127.454]},
	// {name:"MC_analetova",url:"https://mrexpo.ru/master-classes/ana-letova",pos:[69.587,-4.516,137.674]},
	// {name:"MC_natalafontan",url:"https://mrexpo.ru/master-classes/natala-fontan",pos:[132.208,1.523,52.507]},
]
export {linkArr};
