let addArrow = function(local,scene,cameraTPos){
  console.log("тутавнутри")
  let mesh = scene.getMeshByName("Arrow");
  let box = BABYLON.MeshBuilder.CreateBox("ArrowParent", {width: 1, height:1,depth: 1}, scene);
  box.position.copyFrom(cameraTPos);
  box.rotation.y = 1.65
  box.visibility=0;
  mesh.setParent(box);
  box.scaling.set(.2,.2,.2);
  mesh.position.set(0, 1, 0);
  addShader(mesh)
  function addShader(mesh) {
    BABYLON.Effect.ShadersStore.customVertexShader = `// Vertex shader
    precision highp float;
    // Attributes
    attribute vec3 position;
    attribute vec3 normal;
    attribute vec2 uv;
    attribute vec2 uv2;
    attribute vec4 color;

    // Uniforms
    uniform mat4 worldViewProjection;
    uniform float angle;
    uniform float dist;

    // Varying
    varying vec3 Cd;

    void main(void) {
    Cd = color.rgb;
    float a = clamp((uv.x -dist*1.1 + .1)/.1+.1,0.,1.);
    vec3 pos = position + normal*(1.-uv.y)*a;
    float s = sin(-angle/3.);
    float c = cos(-angle/3.);
    mat2 m = mat2(c, -s, s, c);
    vec2 p2 = pos.xz*m;
    gl_Position = worldViewProjection * vec4(p2.x, pos.y, p2.y, 1.0);
    }`;
    BABYLON.Effect.ShadersStore.customFragmentShader = `precision highp float;
    varying vec3 Cd;
    void main(void) {
        gl_FragColor = vec4(Cd, 1.);
    }
    `;
    local.Arrowshader = new BABYLON.ShaderMaterial("shaderGradient", scene, "custom", {
      attributes: ["uv2", "uv", "color", "position", "normal"],
      defines: ["#define INSTANCES"],
      uniforms: ["dist", "angle", "world", "worldViewProjection"]
    });
    //ljnhjhvhgxrt
    mesh.material = local.Arrowshader;
  }
}
export {addArrow};
